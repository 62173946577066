import styled from "styled-components"

export const CloseButton = styled.button`
  background: transparent;
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`
export const StyledImage = styled.img`
  width: 15px;
  height: 15px;
`

// Styled component for Web View Floating CTA
export const FloatingCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 5px;
  padding: 16px;
  width: 333px;
  border-radius: 16px;
  opacity: 1;
  background: var(
    --Gradient-Zen,
    linear-gradient(135deg, #fef9ec 0%, #eceffe 100%)
  );
  box-shadow: 0px 7px 8px -4px rgba(28, 30, 33, 0.02),
    0px 13px 19px 2px rgba(28, 30, 33, 0.04),
    0px 5px 24px 4px rgba(28, 30, 33, 0.1);

  /* Media query for screens smaller than 768px (mobile devices) */
  @media (max-width: 1023px) {
    display: none; /* Hide the FloatingCTA on mobile devices */
  }

  h5 {
    color: #1c1e21;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-right: 126px;
    color: var(--bn-w-black-900, var(--greyscale-black-900, #1c1e21));
  }

  p {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 12px;
  }
`

export const MobileCTA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 10px 20px;
  background: var(
    --Gradient-Zen,
    linear-gradient(135deg, #fef9ec 0%, #eceffe 100%)
  );
  box-shadow: 0px 7px 8px -4px rgba(28, 30, 33, 0.02),
    0px 13px 19px 2px rgba(28, 30, 33, 0.04),
    0px 5px 24px 4px rgba(28, 30, 33, 0.1);
  gap: 16px;

  h5 {
    color: #1c1e21;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
    color: var(--bn-w-black-900, var(--greyscale-black-900, #1c1e21));
  }

  ${CloseButton} {
    margin-left: 16px;
    margin-bottom: 6px;
  }

  /* Media query for screens larger than 1023px (non-mobile devices) */
  @media (min-width: 1023px) {
    display: none; /* Hide the MobileCTA on non-mobile devices */
  }
`
