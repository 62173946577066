import styled from "styled-components"
import { colors } from "../themes/colors"

export const HomePageWrapper = styled.main`
  background: ${colors.c_white[500]};
`

export const BackgroundGradient = styled.div`
  background: linear-gradient(135deg, #fef9ec 0%, #eceffe 100%);
`
