import axios from "axios"
import { Form, Formik } from "formik"
import { zipCodeToState } from "../../../../common/utils"
import { useWindowSize } from "../../../hooks"
import { usePageContext } from "../PageContext"
import { TextField } from "../TextField"
import {
  ActionContainer,
  Button,
  Container,
  FormContainer,
  H3,
  Span,
} from "../styled"
import { schema } from "./schema"

const CheckCouplesTherapyAvailabilityForm = () => {
  const { setCurrentPage } = usePageContext()
  const { isMobile } = useWindowSize()

  const checkAvailability = async (zipCode: string) => {
    const stateCode = zipCodeToState(Number(zipCode))
    if (!stateCode) {
      return
    }

    const { data } = await axios.get(
      `${process.env.GATSBY_API_SERVER_URL}/api/globals`
    )
    const availableLocations = new Set(
      data["regions-available-for-couples-therapists"]
    )

    if (!availableLocations.has(stateCode)) {
      setCurrentPage("join-waitlist", {
        zipCode,
        stateCode,
      })
      return
    }

    setCurrentPage("location-available", {
      zipCode,
      stateCode,
    })
  }

  const onSubmit = (values: { zipCode: string }) => {
    checkAvailability(values.zipCode)
  }

  return (
    <Formik
      initialValues={{
        zipCode: "",
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isValid, handleSubmit }) => (
        <Container
          style={{ justifyContent: isMobile ? "flex-start" : "center" }}
        >
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: isMobile ? 1 : 0,
              overflow: isMobile ? "hidden" : "visible",
            }}
          >
            <FormContainer>
              <H3>Let’s see if Couples Therapy is available in your state</H3>
              <Span>
                We’re adding more therapists as soon as we can.
                <br />
                <br />
                If we don’t have any in your area yet, you can sign up to get
                notified when we do.
              </Span>

              <TextField label="Zip code" name="zipCode" placeholder="12345" />
            </FormContainer>

            <ActionContainer>
              <div>
                <Button
                  to=""
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Check availability
                </Button>
              </div>
            </ActionContainer>
          </Form>
        </Container>
      )}
    </Formik>
  )
}

export default CheckCouplesTherapyAvailabilityForm
