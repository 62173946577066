import { Navigation } from "../ui/Navigation"
import { useEffect, useState } from "react"
import SEO from "../SEO"
import { HowItWorks, GetStarted } from "./sections"
import { Stats } from "../shared/Stats"
import { ExceptionalCare, TestimonialSection } from "../shared"
import Spinner from "../ui/Spinner"
import { CenterWrapper } from "./styled"

export const AdvocatePageBodyV2 = () => {
  const [widget, setWidget] = useState<Widget | null>(null)
  const [isLoadingWidget, setIsLoadingWidget] = useState(true)

  const widgetLoadedHandler = async () => {
    if (!widget) return
    setIsLoadingWidget(false)

    // Wait for stencil components to hydrate (can prevent flickering on initial load)
    await new Promise(resolve => setTimeout(resolve, 500))
    widget.open()
  }

  useEffect(() => {
    if (widget) {
      // Widget loaded event
      widget.addEventListener("sq:widget-loaded", widgetLoadedHandler)

      // Remove listener from DOM on dismount
      return () => {
        widget.removeEventListener("sq:widget-loaded", widgetLoadedHandler)
      }
    }

    // Return a noop function to ensure a consistent return type
    return () => {}
  }, [widget])

  return (
    <main>
      {isLoadingWidget && (
        <CenterWrapper>
          <Spinner className="" />
        </CenterWrapper>
      )}
      <div id="impact-embed"></div>

      <impact-embed
        ref={(el: Widget) => setWidget(el)}
        widget={"p/24570/w/websiteReferralWidget"}
        container={"#impact-embed"}
      />

      <HowItWorks />
      <TestimonialSection hideButton />
      <Stats />
      <ExceptionalCare />
      <GetStarted />
    </main>
  )
}

export const AdvocatePageV2 = () => {
  return (
    <>
      <SEO
        title="Give 70% off. Get $15."
        description="Know someone who might love Cerebral? Give them 70% off their first month. Get a $15 credit when they create an account."
        image="https://media.cerebral.com/Web/MetaDataImages/RAFMetaImage.png"
        url="refer/"
      />
      <Navigation />
      <AdvocatePageBodyV2 />
    </>
  )
}
