import {
  LinkButton,
  NextStepDescription,
  NextStepTitle,
  NextStepWrapper,
  MainText,
  FooterWrapper,
} from "./styled"
import CareTeam from "../MiniScreener/GenericWidget/images/careteam.webp"
import { UilArrowRight } from "@iconscout/react-unicons"
import MedFormAlt from "../MiniScreener/GenericWidget/images/med_form_alt.webp"

const NextStepCoupleTherapy = () => {
  return (
    <NextStepWrapper>
      <NextStepTitle>Thrive in your relationship.</NextStepTitle>
      <NextStepDescription>
        Work with your partner, or start with yourself, to strengthen your bond
        and improve communication
      </NextStepDescription>
      <FooterWrapper>
        <LinkButton href={`/plans/therapy`} variant="primary">
          <MainText>
            <img src={CareTeam} alt="" />
            Therapy
          </MainText>
          <UilArrowRight size={24} color="#A7BCFF" />
        </LinkButton>
        <LinkButton href={`/plans/couples-therapy`} variant="primary">
          <MainText>
            <img src={MedFormAlt} alt="" />
            Couples Therapy
          </MainText>
          <UilArrowRight size={24} color="#A7BCFF" />
        </LinkButton>
      </FooterWrapper>
    </NextStepWrapper>
  )
}

export default NextStepCoupleTherapy
