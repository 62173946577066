import { WrapperGradient, Card, PlantImage } from "./styled"
import Plant from "./assets/Plant.webp"

interface IWrapperProps {
  children: JSX.Element | JSX.Element[]
}

export const Wrapper = ({ children }: IWrapperProps) => {
  return (
    <WrapperGradient>
      <Card>{children}</Card>
      <PlantImage src={Plant} />
    </WrapperGradient>
  )
}
