interface State {
  min: number
  max: number
  abbreviation: string
}

export const zipCodeToState = (zip: number): string | undefined => {
  const states: State[] = [
    { min: 35000, max: 36999, abbreviation: "AL" },
    { min: 99500, max: 99999, abbreviation: "AK" },
    { min: 85000, max: 86999, abbreviation: "AZ" },
    { min: 71600, max: 72999, abbreviation: "AR" },
    { min: 90000, max: 96699, abbreviation: "CA" },
    { min: 80000, max: 81999, abbreviation: "CO" },
    { min: 6000, max: 6999, abbreviation: "CT" },
    { min: 19700, max: 19999, abbreviation: "DE" },
    { min: 32000, max: 34999, abbreviation: "FL" },
    { min: 30000, max: 31999, abbreviation: "GA" },
    { min: 96701, max: 96797, abbreviation: "HI" },
    { min: 96801, max: 96898, abbreviation: "HI" },
    { min: 96799, max: 96799, abbreviation: "AS" },
    { min: 83200, max: 83999, abbreviation: "ID" },
    { min: 60000, max: 62999, abbreviation: "IL" },
    { min: 46000, max: 47999, abbreviation: "IN" },
    { min: 50000, max: 52999, abbreviation: "IA" },
    { min: 66000, max: 67999, abbreviation: "KS" },
    { min: 40000, max: 42999, abbreviation: "KY" },
    { min: 70000, max: 71599, abbreviation: "LA" },
    { min: 3900, max: 4999, abbreviation: "ME" },
    { min: 20600, max: 21999, abbreviation: "MD" },
    { min: 1000, max: 2799, abbreviation: "MA" },
    { min: 48000, max: 49999, abbreviation: "MI" },
    { min: 55000, max: 56999, abbreviation: "MN" },
    { min: 38600, max: 39999, abbreviation: "MS" },
    { min: 63000, max: 65999, abbreviation: "MO" },
    { min: 59000, max: 59999, abbreviation: "MT" },
    { min: 27000, max: 28999, abbreviation: "NC" },
    { min: 58000, max: 58999, abbreviation: "ND" },
    { min: 68000, max: 69999, abbreviation: "NE" },
    { min: 88900, max: 89999, abbreviation: "NV" },
    { min: 3000, max: 3899, abbreviation: "NH" },
    { min: 7000, max: 8999, abbreviation: "NJ" },
    { min: 87000, max: 88499, abbreviation: "NM" },
    { min: 10000, max: 14999, abbreviation: "NY" },
    { min: 43000, max: 45999, abbreviation: "OH" },
    { min: 73000, max: 74999, abbreviation: "OK" },
    { min: 97000, max: 97999, abbreviation: "OR" },
    { min: 15000, max: 19699, abbreviation: "PA" },
    { min: 300, max: 999, abbreviation: "PR" },
    { min: 2800, max: 2999, abbreviation: "RI" },
    { min: 29000, max: 29999, abbreviation: "SC" },
    { min: 57000, max: 57999, abbreviation: "SD" },
    { min: 37000, max: 38599, abbreviation: "TN" },
    { min: 75000, max: 79999, abbreviation: "TX" },
    { min: 88500, max: 88599, abbreviation: "TX" },
    { min: 84000, max: 84999, abbreviation: "UT" },
    { min: 5000, max: 5999, abbreviation: "VT" },
    { min: 20101, max: 20598, abbreviation: "VA" },
    { min: 22003, max: 24658, abbreviation: "VA" },
    { min: 20000, max: 20599, abbreviation: "DC" },
    { min: 98000, max: 99499, abbreviation: "WA" },
    { min: 24700, max: 26999, abbreviation: "WV" },
    { min: 53000, max: 54999, abbreviation: "WI" },
    { min: 82000, max: 83199, abbreviation: "WY" },
  ]

  return states.find(({ min, max }) => zip >= min && zip <= max)?.abbreviation
}
