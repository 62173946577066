import { useContext } from "react"
import { H1, BodyRegular } from "../../../ui"
import { Container, InnerContainer } from "./styled"
import { createPageDescription, createPageTitle } from "./helpers"
import { LocationPageContext } from "../../LocationPageProvider"

export const Header = (): JSX.Element => {
  const { totalCarePersons, city, state, specialty, carePersonType } =
    useContext(LocationPageContext)

  const pageTitle = createPageTitle({ city, state, specialty, carePersonType })
  const pageDescription = createPageDescription({
    totalCarePersons,
    city,
    state,
    specialty,
    carePersonType,
  })
  return (
    <Container>
      <InnerContainer>
        <H1>{pageTitle}</H1>
        <BodyRegular>{pageDescription}</BodyRegular>
      </InnerContainer>
    </Container>
  )
}
