import { Navigation } from "../ui/Navigation"
import SEO from "../SEO"
import { WaysWeCanHelp, GetStarted } from "./sections"
import { TestimonialSection } from "../shared/Testimonials/TestimonialSection"
import { Stats } from "../shared/Stats"
import { ExceptionalCare } from "../shared"
import Spinner from "../ui/Spinner"
import { CenterWrapper } from "./styled"

export const FriendReferralPageV2Body: React.FC = () => {
  return (
    <main>
      <impact-embed widget="p/24570/w/friendWidget">
        <CenterWrapper>
          <Spinner className="" />
        </CenterWrapper>
      </impact-embed>

      <WaysWeCanHelp />
      <TestimonialSection hideButton />
      <Stats />
      <ExceptionalCare />
      <GetStarted />
    </main>
  )
}

export const FriendReferralPageV2 = () => {
  return (
    <>
      <SEO
        title="Get 70% off your first month of online mental health care"
        description="Curious if therapy or medication is right for you? Try one month of Cerebral for as little as $30. No commitments. No judgment. Just high quality care."
        image="https://media.cerebral.com/Web/MetaDataImages/RAFMetaImage.png"
        url="friend-referral/"
      />
      <Navigation />
      <FriendReferralPageV2Body />
    </>
  )
}
