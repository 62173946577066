import styled from "styled-components"
import { colors } from "../themes/colors"

export const LocationBody = styled.main`
  background: ${colors.c_white[500]};
`

export const PlantContainer = styled.div`
  position: relative;
  min-height: 268px;
  & > img {
    position: absolute;
    bottom: 0;
    right: 64px;
    @media (max-width: 600px) {
      right: 32px;
    }
  }
`

export const BottomSeoSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 80px 16px;
  gap: 100px;
  max-width: 991px;
  margin: 0 auto;
  @media (max-width: 834px) {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
`

export const BottomSEOCopySectionOuterWrapper = styled.div`
  background: ${colors.c_secondary[50]};
`
