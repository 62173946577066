import React from "react"
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../lib/linkResolver"

const PreviewPage = () => {
  return (
    <div>
      <h1>
        {process.env.GATSBY_ACTIVE_ENV === "production"
          ? "Prismic preview is unavailable in the production!"
          : "Loading preview…"}
      </h1>
    </div>
  )
}

export default process.env.GATSBY_ACTIVE_ENV === "production"
  ? PreviewPage
  : withPrismicPreviewResolver(PreviewPage, [
      {
        repositoryName: "cerebral",
        linkResolver,
      },
    ])
