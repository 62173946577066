import TrustPilot from "../../../images/trustpilot.png"
import {
  TrustPilotText,
  TrustPilotTextWrapper,
  TrustPilotImage,
} from "./styled"

export const TrustPilotPhrase = () => {
  return (
    <TrustPilotTextWrapper>
      <TrustPilotText>
        5-star reviews are gathered from Cerebral clients on
      </TrustPilotText>
      <a href="https://www.trustpilot.com/review/cerebral.com" target="_blank">
        <TrustPilotImage src={TrustPilot} alt="TrustPilot" />
      </a>
    </TrustPilotTextWrapper>
  )
}
