import React from "react"

import { contents } from "./constants"
import { AlternatingImageTexts } from "../../../shared"
import { H2 } from "../../../ui"
import { Container, ContentContainer } from "./styled"

export const OnlineMentalHealth = () => {
  return (
    <Container>
      <ContentContainer>
        <H2>
          Online mental health care that <br /> caters to you
        </H2>
        <AlternatingImageTexts contents={contents} theme="secondary" />
      </ContentContainer>
    </Container>
  )
}
