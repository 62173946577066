import styled from "styled-components"

export const Container = styled.section`
  background: #eff2ff;
  padding: 96px 64px 36px;
  @media (max-width: 800px) {
    padding: 64px 24px 24px;
  }
`

export const InnerContainer = styled.div`
  max-width: 991px;
  margin: 0 auto;
  h1 {
    margin-bottom: 16px;
  }
`
