import styled, { keyframes } from "styled-components"
import { colors } from "../../themes/colors"

export const FAQSectionWrapper = styled.div<{
  noPadding: boolean
  margin: string
}>`
  margin: ${({ margin }) => (margin !== "" ? margin : "96px auto")};
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 24px")};
  h2 {
    text-align: center;
    margin-bottom: 48px;
    color: ${colors.c_black[900]};
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
  }

  @media (max-width: 834px) {
    margin: ${({ margin }) => (margin !== "" ? margin : "64px auto")};
    h2 {
      font-size: 24px;
    }
  }
`

export const FAQInnerWrapper = styled.div`
  max-width: 992px;
  margin: 0 auto;
`

export const FAQBlock = styled.div`
  border-bottom: 1px solid ${colors.c_grey[200]};
`

export const FAQQuestion = styled.button`
  cursor: pointer;
  padding: 24px 0;
  background-color: transparent;
  border: none;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  h3 {
    color: ${colors.c_black[900]};
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`

export const FAQAnswer = styled.div<{ isOpen: boolean }>`
  padding-bottom: ${({ isOpen }) => (isOpen ? "20px" : "0px")};
  max-height: ${({ isOpen }) => (isOpen ? "1152px" : "0px")};
  overflow: hidden;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-right: 44px;

  @media (max-width: 700px) {
    padding-right: 0px;
  }

  p,
  li {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  p:not(:first-child) {
    margin: 8px 0 0;
  }

  ul {
    list-style: disc;
    padding: 8px 0px 0px 24px;
  }

  b,
  strong {
    font-weight: 600;
    line-height: 20px;
  }

  a {
    color: var(--Secondary-600, #385ade);
  }

  em {
    color: var(--Grey-900, #353a41);
    font-family: Poppins;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
  }

  .table-wrapper {
    margin-left: 32px;

    @media (max-width: 700px) {
      margin: 0px;
    }

    table.pricing {
      @media (max-width: 700px) {
        width: 100%;
      }
      tr {
        @media (max-width: 700px) {
          border-bottom: 1pt solid #4d515a;
        }

        td {
          padding: 4px 0px;
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          &:nth-child(1) {
            width: 486px;

            @media (max-width: 700px) {
              width: 50%;
            }
          }

          &:nth-child(2) {
            width: 150px;
            font-size: 14px;
            padding: 0px 20px;

            @media (max-width: 700px) {
              text-align: end;
              padding: 0px;
            }
          }

          .benefit-items {
            color: var(--Grey-800, #4d515a);
            font-family: Poppins;
            font-size: 14px;
            padding: 8px 0px 0px 32px;

            @media (max-width: 700px) {
              padding: 0px;
            }
            li {
              padding: 2px 0px;

              @media (max-width: 700px) {
                display: flex;
              }

              .title {
                width: 475px;
                float: left;

                @media (max-width: 700px) {
                  width: 70%;
                }
              }

              .value {
                display: block;

                @media (max-width: 700px) {
                  width: 30%;
                  text-align: end;
                }
              }
            }
          }
        }
      }
    }
  }
`
