import { Container, Header } from "./styled"

import { QuotesCarousel, TrustPilotPhrase } from "../../../shared"
import { quotes } from "./constants"

export const WhatOurClientsAreSaying = () => {
  return (
    <Container>
      <Header>What our clients are saying</Header>
      <QuotesCarousel quotes={quotes} />
      <span style={{ marginTop: "24px" }}></span>
      <TrustPilotPhrase />
    </Container>
  )
}
