import { graphql, useStaticQuery } from "gatsby"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import JoinCouplesTherapyWaitlist from "./components/JoinCouplesTherapyWaitlist"
import { ModalState, useModalContext } from "../../contexts/ModalContext"
import SEO from "../SEO"
import { EndCap, FAQSection } from "../shared"
import { HeroSection } from "../shared/HeroSection"
import { Navigation } from "../ui/Navigation"
import { useSignupUrl } from "../../hooks/useSignupUrl"

import {
  IsCouplesRightToMeCopy,
  approachesWeUseCopy,
  chooseServicePlans,
} from "./constants"
import HeaderImage from "./images/couples-therapy-header.webp"
import {
  ApproachesWeUse,
  ChooseService,
  GettingStarted,
  IsCouplesRightToMe,
  OfferingComparison,
  StrengthenYourRelationship,
} from "./sections"
import { TherapyPageWrapper } from "./styled"
import LatestPost from "../shared/Blog/LatestPost"
import { edgeToPostPreview } from "../../lib/utils"

const COUPLES_THERAPY_PAGE_QUERY = graphql`
  query CouplesTherapyPageQuery {
    allPrismicTestimonialSection(filter: { tags: { eq: "therapy_quote" } }) {
      edges {
        node {
          data {
            testimonial_section {
              name
              quote
            }
          }
        }
      }
    }
    allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { in: ["Couples Therapy"] } } }
        }
      }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            date(formatString: "MM/DD/YYYY")
            previewimage {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 1216)
            }
            categories {
              category
            }
            content {
              text
            }
            body {
              ... on PrismicPostDataBodyBlogText {
                primary {
                  text {
                    text
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`

export const CouplesTherapyPageBody = (): JSX.Element => {
  const { setModalContent, setModalState } = useModalContext()
  const response = useStaticQuery(COUPLES_THERAPY_PAGE_QUERY)

  const recentPosts = response.allPrismicPost.edges.map((edge: any) =>
    edgeToPostPreview({ edge })
  )

  return (
    <TherapyPageWrapper>
      <HeroSection
        title="Couples Therapy"
        description="Support for every relationship — strengthen your bond, navigate life changes, improve communication, and more"
        image={HeaderImage}
        buttonsDirection="column"
        buttons={[
          {
            text: "Get started",
            link: useSignupUrl(),
          },
          {
            text: "Check availability",
            link: "",
            onClick: () => {
              setModalContent(<JoinCouplesTherapyWaitlist />, {
                modalWidth: "wide",
              })
              setModalState(ModalState.OPEN)
            },
            theme: "tertiary",
          },
        ]}
      />
      <IsCouplesRightToMe copy={IsCouplesRightToMeCopy} />
      <StrengthenYourRelationship />
      <ApproachesWeUse copy={approachesWeUseCopy} />
      <OfferingComparison />
      <ChooseService plansInfo={chooseServicePlans} />
      <GettingStarted />
      <FAQSection labels={["couples_faq"]} />
      <LatestPost
        posts={recentPosts}
        title="Couples Therapy Resources"
        hideReadMore
      />
      <EndCap title="You deserve a great relationship" />
    </TherapyPageWrapper>
  )
}

export const CouplesTherapyPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="Conditions Cerebral Treats"
        titleSuffix="Expert Online Therapy + Mental Health Care on Your Schedule"
        description="Cerebral experts can treat anxiety, depression, insomnia, and more. Learn about the care you can get today."
        url="/couples-therapy"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <CouplesTherapyPageBody />
    </>
  )
}
