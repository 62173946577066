import EmailCollectionSection from "./EmailCollectionSection"
import LatestPost from "./LatestPost"
import MentalHeathSupport from "./MentalHeathSupport"
import { EndBlogWrapper } from "./styled"
import { Post } from "./types"

interface EmailCollectionSectionProps {
  posts?: Post[]
  title?: string
  subtitle?: string
}

const EndBlog = ({ posts, title, subtitle }: EmailCollectionSectionProps) => {
  return (
    <EndBlogWrapper>
      <EmailCollectionSection />
      {posts && posts.length > 0 && <LatestPost posts={posts} />}
      <MentalHeathSupport title={title} subtitle={subtitle} />
    </EndBlogWrapper>
  )
}

export default EndBlog
