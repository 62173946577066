import {
  ContinueScreenerButton,
  NextStepDescription,
  NextStepTitle,
  NextStepWrapper,
} from "./styled"
import { TServiceLine } from "./types"
import { capitalizeFirstLetter } from "../utils"

interface NextStepProps {
  serviceLine: TServiceLine
}

const NextStep = ({ serviceLine }: NextStepProps) => {
  return (
    <NextStepWrapper>
      <NextStepTitle>Get the support you need.</NextStepTitle>
      <NextStepDescription>
        Work with a therapist, prescriber, or both in a safe and judgment-free
        online space
      </NextStepDescription>
      <ContinueScreenerButton
        href={`${process.env.GATSBY_BASE_URL}/app?screenHint=signup&nextStep=signup&survey=${serviceLine}1`}
        variant="primary"
      >
        Continue {capitalizeFirstLetter(serviceLine)} Screening
      </ContinueScreenerButton>
      <ContinueScreenerButton
        href={`${process.env.GATSBY_BASE_URL}/app?screenHint=signup&nextStep=signup`}
        variant="outline"
      >
        Create Account
      </ContinueScreenerButton>
    </NextStepWrapper>
  )
}

export default NextStep
