import styled from "styled-components"
import { DropdownWrapper } from "../../../shared/CustomDropdown/styled"

export const Container = styled.div`
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContentContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  opacity: 0px;
  padding: 80px 24px;

  @media (max-width: 768px) {
    padding: 32px 24px;
    align-self: stretch;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1024px;

  @media (max-width: 768px) {
    align-items: center;
  }

  @media (max-width: 413px) and (min-width: 329px) {
    padding: 0px 0px;
  }

  @media (max-width: 328px) {
    padding: 0px 0px;
  }
`

export const Header = styled.h1`
  align-self: stretch;
  color: var(--Primary-500, #7d70d2);
  text-align: center;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 80px;
  margin-bottom: 16px;

  b {
    font-weight: 800;
    color: var(--Primary-600, #51459e);
  }

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    white-space: nowrap;
  }

  @media (max-width: 413px) and (min-width: 329px) {
    font-size: 28px;
  }

  @media (max-width: 328px) {
    font-size: 28px;
    white-space: normal;
  }
`

export const SubText = styled.div`
  width: 480px;
  color: var(--Grey-900, var(--greyscale-grey-900, #353a41));
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ConditionText = styled.p`
  color: var(--Grey-900, var(--greyscale-grey-900, #353a41));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const SearchButton = styled.button`
  display: flex;
  border-radius: 0px 8px 8px 0px;
  background: var(--Primary-600, #51459e);
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: var(--base-S1, 8px);
  flex-shrink: 0;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    color: white;
  }
`

export const DropdownContainer = styled.div`
  width: 480px;
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const FormWrapper = styled.form`
  margin-top: 32px;
  ${DropdownWrapper} {
    border-radius: 8px 0px 0px 8px;

    @media (max-width: 768px) {
      max-width: none;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  * {
    font-family: Poppins;
  }
`
