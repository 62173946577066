import React from "react"

import {
  Wrapper,
  TextDescription,
  TextContainer,
  ContentContainer,
} from "./styled"
import { content } from "./constants"
import { H2, H4 } from "../../../ui"

export const QualityDefinesUs = (): JSX.Element => {
  return (
    <Wrapper>
      <ContentContainer>
        <H2>Quality defines us</H2>
        <TextDescription>
          Cerebral is tech-enabled, clinician-led. We understand the
          provider-patient experience and use our insights to deliver
          evidence-based, online therapy that gets results.
        </TextDescription>
        {content.map(question => (
          <TextContainer key={question.id}>
            <H4>{question.title}</H4>
            <TextDescription>{question.description}</TextDescription>
          </TextContainer>
        ))}
      </ContentContainer>
    </Wrapper>
  )
}
