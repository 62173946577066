import styled from "styled-components"
import Link from "../../common/Link"
import { Logo, CloseButton } from "./PlansNavigation/styled"
import Button from "../../common/Button"

export const TopContainer = styled.div`
  background-color: #6786ff;
  text-align: center;
  box-sizing: border-box;
`

export const NavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(102.53deg, #d2eff6 3.32%, #d4dbf5 97.9%);
  padding: 3px 15px;

  @media (min-width: 600px) {
    padding: 3px 30px;
  }

  @media (min-width: 834px) {
    padding: 0 50px;
  }
`

export const Menu = styled.ul``
export const MenuItem = styled.li``
export const MenuLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #250044;
  &:active,
  &:hover {
    color: #6786ff;
  }
`
export const MenuLinkExternal = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #250044;
  cursor: pointer;
  &:active,
  &:hover {
    color: #6786ff;
  }
`

export const ContentLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #6786ff;
  cursor: pointer;
  &:active,
  &:hover {
    color: #d2eff6;
    text-decoration: underline;
  }
`

export const ListLinkContentContainer = styled.div`
  margin: auto;
  width: 170px;
  margin-top: 60px;
  text-align: center;
  @media (min-width: 834px) {
    width: 100%;
    margin-top: 90px;
  }
  & ${ContentLink} {
    font-size: 16px;
    display: block;
    line-height: 23px;
    @media (min-width: 834px) {
      display: inline-block;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
`

export const GetStartedContainer = styled.div`
  margin-top: 20px;
`

export const DesktopMenu = styled.div`
  display: none;
  & > ${Menu} {
    display: flex;
    align-items: center;
    & > li:last-child {
      margin-right: 0;
    }
  }

  & ${MenuItem} {
    margin: 20px;
  }

  @media (min-width: 834px) {
    display: block;
  }
`

export const MobileMenu = styled.div`
  position: fixed;
  z-index: 2;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 90px;

  & > ${Logo} {
    position: absolute;
    top: 10px;
    left: 15px;
  }

  & > ${CloseButton} {
    position: absolute;
    top: 2px;
    right: 3px;
  }

  & > ${Menu} {
    text-align: center;
  }

  & ${MenuItem} {
    margin: 40px;
  }

  @media (min-width: 600px) {
    ${Logo} {
      top: 10px;
      left: 30px;
    }

    ${CloseButton} {
      position: absolute;
      top: 3px;
      right: 16px;
    }
  }
`

export const ContentContainer = styled.div`
  padding: 10px 30px 60px 30px;
  text-align: center;
  @media (min-width: 834px) {
    padding-top: 60px;
    padding-bottom: 210px;
  }
`

export const FirstTextContainer = styled.div`
  text-align: center;
  font-size: 26px;
  color: #250044;
  font-weight: 600;
  line-height: 35px;
  width: 280px;
  margin: auto;
  @media (min-width: 834px) {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 40px;
    width: 410px;
    line-height: 42px;
  }
`

export const SecondTextContainer = styled.div`
  width: 280px;
  margin: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #250044;
  text-align: center;
  padding-top: 15px;
  @media (min-width: 834px) {
    font-weight: 500;
    font-size: 20px;
    width: 515px;
    line-height: 35px;
    margin-bottom: 10px;
  }
`

export const ButtonBackToHome = styled(Button)`
  padding: 23px 68px;
  margin-top: 30px;
  @media (min-width: 375px) {
    padding: 23px 88px;
  }
  @media (min-width: 834px) {
    margin-bottom: 10px;
  }
`

export const ImageContentContainer = styled.img`
  width: 100px;
  height: 100px;
  @media (min-width: 834px) {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
  }
`
