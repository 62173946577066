import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import SliceZone from "../components/prismic/SliceZone"
import DynamicNavigation from "../components/ui/DynamicNavigation"
import { graphql, PageProps } from "gatsby"
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../lib/linkResolver"
interface LandingPageProps {
  data: {
    prismicLanding: {
      data: {
        seotitle: {
          text: string
        }
        seokeywords: {
          text: string
        }
        seodescription: {
          text: string
        }
        body: { id: string; slice_type: string; primary: any }[]
      }
    }
  }
}

interface LandingPageProps {
  data: {
    prismicLanding: {
      data: {
        seotitle: {
          text: string
        }
        seokeywords: {
          text: string
        }
        seodescription: {
          text: string
        }
        body: { id: string; slice_type: string; primary: any }[]
      }
    }
  }
}

type CustomPageProps = PageProps<
  LandingPageProps["data"],
  object,
  unknown,
  object
> &
  WithPrismicPreviewProps<LandingPageProps["data"]>

const LandingPage: React.FC<CustomPageProps> = ({ data }) => {
  if (!data) return null
  const doc = data.prismicLanding

  const { seotitle, seokeywords, seodescription } = doc.data

  return (
    <Layout>
      <Seo
        title={seotitle.text}
        titleSuffix={seokeywords.text}
        description={seodescription.text}
      />
      <DynamicNavigation bannerLookupKey="banner-medication-month" />
      <SliceZone sliceZones={doc.data.body} />
    </Layout>
  )
}

export const query = graphql`
  query LandingPageB2CQuery($id: String) {
    prismicLanding(id: { eq: $id }) {
      _previewable
      data {
        seokeywords {
          text
        }
        seodescription {
          text
        }
        seotitle {
          text
        }
        body {
          ...HeroHeaderSlice
          ...TextRightSlice
          ...CallOutBoxSlice
          ...FeatureTextLeftSlice
          ...CtaButtonSlice
          ...FeatureTextRightSlice
          ...TextLeftSlice
          ...ThreeColumnsSlice
          ...FourColumnsSlice
          ...LargeQuoteSlice
          ...QuoteWithImageSlice
          ...VideoSlice
          ...ImageSlice
          ...ImageWithTextSlice
          ...FullImageWithTextOverlaySlice
          ...LandingTextSlice
          ...HeaderWithTrustpilotSlice
          ...StatsSlice
          ...ImageTextRightSlice
          ...ImageWithListTextRightSlice
          ...ImageWithListTextLeftSlice
          ...FAQSlice
          ...TestimonialsCardsSlice
          ...TextButtonSlice
        }
      }
    }
  }
`

export default process.env.GATSBY_ACTIVE_ENV === "production"
  ? LandingPage
  : withPrismicPreview(LandingPage, [
      {
        repositoryName: "cerebral",
        linkResolver,
      },
    ])
