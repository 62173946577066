import styled from "styled-components"
import { colors } from "../../themes/colors"

export const QuotesContainer = styled.div<{ isPrismic?: boolean }>`
  max-width: 1216px;

  @media (max-width: 834px) {
    max-width: 100%;
  }
`

export const QuotesContentContainer = styled.div`
  background-color: #fff;
  padding: 64px 80px 48px;
  width: 85%;
  margin-bottom: 32px;
  border-radius: 10px;
  border: 1px solid ${colors.c_primary[900]};
  display: flex;

  @media (max-width: 834px) {
    width: 100%;
    padding: 24px;
    margin-bottom: 24px;
    flex-direction: column;
  }
`

export const Quote = styled.pre`
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-wrap: wrap;
  margin-bottom: 32px;

  @media (max-width: 834px) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const Name = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.c_black[900]};
`

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 834px) {
    flex-direction: column;
    align-items: baseline;
  }
`

export const TextContainer = styled.div`
  background-color: #fff;

  @media (max-width: 834px) {
    width: 100%;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProfilePicture = styled.img`
  max-width: 160px;
  max-height: 160px;
  width: 100%;
  @media (max-width: 834px) {
    max-width: 80px;
    max-height: 80px;
  }
`

export const InnerContentContainer = styled.div`
  display: flex;
`
