import { useEffect, useState } from "react"
import { getVariantByName } from "../../../lib/utils"

const EXPERIMENT_NAME = "Blog Email Popup"
const VARIANT_NAME = "Show Email Popup"

export const useEmailCollectionPopup = (
  exprimentName = EXPERIMENT_NAME,
  variantName = VARIANT_NAME,
  key = "lastVisit"
) => {
  const [showModal, setShowModal] = useState(false)
  const [isExperimentActive, setIsExperimentActive] = useState(true)

  useEffect(() => {
    if (!isExperimentActive) return // if experiment is not active, do not track last visit time

    const lastVisit = localStorage.getItem(key)
    const isNewUser = !lastVisit
    const isOldUser =
      lastVisit && Date.now() - Number(lastVisit) > 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds

    if (isNewUser || isOldUser) {
      setShowModal(true)
    }

    localStorage.setItem(key, String(Date.now()))
  }, [isExperimentActive])

  useEffect(() => {
    const variant = getVariantByName(exprimentName)
    setIsExperimentActive(variant === variantName)
  }, [])

  return { showModal, setShowModal }
}
