import {
  CardWrapper,
  Category,
  Date,
  DateCategoriesWrapper,
  LatestPostContainer,
  LatestPostTitle,
  LatestPostWrapper,
  PostDescription,
  PostPreviewImage,
  PostPreviewInfoWrapper,
  PostTitle,
  PostsWrapper,
  ReadMore,
} from "./styled"
import { CtaButton } from "../../prismic/LandingPageStyles"
import { Post } from "./types"
import { formatDate } from "./utils"
import { navigate } from "@reach/router"

interface LatestPostProps {
  posts: Post[]
  title?: string
  hideReadMore?: boolean
  id?: string
  ctaLink?: string
  ctaText?: string
  filteredCategory?: string
}

const LatestPost = ({
  posts,
  title,
  hideReadMore,
  id,
  ctaLink,
  ctaText,
  filteredCategory,
}: LatestPostProps) => {
  const handleOnClick = (url: string) => {
    navigate(`${process.env.GATSBY_BASE_URL}${url}`)
  }

  const getCategories = (categories: string[]) => {
    const hasFilteredCategory =
      !!filteredCategory && categories.includes(filteredCategory)

    if (hasFilteredCategory) {
      const excludeFilteredCategory = categories.filter(
        (category: string) => category !== filteredCategory
      )
      return [filteredCategory, ...excludeFilteredCategory]
    }

    return categories
  }

  return (
    <LatestPostWrapper id={id}>
      <LatestPostContainer>
        <LatestPostTitle>{title || "You may also like"}</LatestPostTitle>
        <PostsWrapper>
          {posts.map(({ date, categories, title, subtitle, image, url }) => (
            <CardWrapper key={title} onClick={() => handleOnClick(url)}>
              <PostPreviewImage image={image} alt="" loading="lazy" />

              <PostPreviewInfoWrapper>
                <DateCategoriesWrapper>
                  <Date>{formatDate(date)}</Date>
                  {getCategories(categories).map(
                    category =>
                      !!category && (
                        <Category key={category}>{category}</Category>
                      )
                  )}
                </DateCategoriesWrapper>
                <PostTitle>{title}</PostTitle>
                <PostDescription>{subtitle}</PostDescription>
                {!hideReadMore && (
                  <ReadMore onClick={() => handleOnClick(url)}>
                    Read more
                  </ReadMore>
                )}
              </PostPreviewInfoWrapper>
            </CardWrapper>
          ))}
        </PostsWrapper>
        <CtaButton to={ctaLink || "/blog"} width="320px" boxShadow="none">
          {ctaText || "View more posts"}
        </CtaButton>
      </LatestPostContainer>
    </LatestPostWrapper>
  )
}

export default LatestPost
