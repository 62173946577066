export const organizationSchemaMarkup = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Cerebral",
  legalName: "Cerebral Inc.",
  url: "https://cerebral.com/",
  logo: "https://images.prismic.io/cerebral/35fdc3b1-9224-4b94-ad47-9b549c67edac_logo.png",
  description:
    "Cerebral is an online mental health company with the mission to make access to high-quality mental health care the new normal.",
  foundingDate: "2020",
  address: {
    "@type": "PostalAddress",
    streetAddress: "2093 Philadelphia Pike #9898",
    addressLocality: "Claymont",
    addressRegion: "DE",
    postalCode: "19703",
    addressCountry: "USA",
  },
  contactPoint: {
    "@type": "ContactPoint",
    contactType: "customer support",
    telephone: "[+415-403-2156]",
    email: "support@cerebral.com",
  },
  sameAs: [
    "https://twitter.com/cerebral",
    "https://www.facebook.com/Cerebral/",
    "https://www.instagram.com/cerebral/",
    "https://www.linkedin.com/company/getcerebral/",
  ],
}
