import styled from "styled-components"

export const Container = styled.section`
  background: #eff2ff;
  font-family: "Poppins", sans-serif;
`

export const InnerContainer = styled.div`
  max-width: 991px;
  margin: 0 auto;
  padding: 96px 64px 64px;

  //These match the styles for H3 in shared components
  h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 24px;

    @media (max-width: 499px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  //These match the styles for H4 in shared components
  h3 {
    font-size: 16px;
    line-height: 23px;
    margin-top: 12px;
    @media (max-width: 499px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  //These match the styles for BodyRegular in shared components
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
    @media (max-width: 400px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`
export const QuestionWrapper = styled.div`
  margin: 24px auto;

  ul {
    list-style: disc;
    padding-left: 40px;
  }

  li {
    font-weight: 300;
    line-height: 24px;
  }

  a {
    line-height: 30px;
  }
`
