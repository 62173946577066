import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const WaysWeCanHelpWrapper = styled.section`
  background-color: ${colors.c_white[500]};
  max-width: 900px;
  padding: 64px 16px 32px;
  margin: 0 auto;
  text-align: center;
  h3 {
    color: ${colors.c_primary[700]};
    text-align: center;
  }
`

export const Row = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 258px));
  gap: 40px;
  align-items: center;

  @media (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`

export const Item = styled.div`
  text-align: center;
  h4 {
    margin-bottom: 8px;
  }
`
