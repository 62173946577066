import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const Container = styled.section`
  padding: 64px 24px;
  background: ${colors.c_secondary[200]};
  @media (max-width: 600px) {
    padding: 40px 24px;
  }
`

export const ContentContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

export const Border = styled.div`
  border: 1px solid #a8baff;
`
