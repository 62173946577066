import { BodyRegular } from "../../../ui"
import { ButtonWrapper, Container, IconWrapper, TextWrapper } from "./styled"
import { UilShieldCheck } from "@iconscout/react-unicons"
import { Button } from "../../../ui"

const InsuranceText = (): JSX.Element => {
  return (
    <div>
      <h2>Are you covered by insurance?</h2>
      <BodyRegular>
        We work with a select group of insurance providers to help cover the
        cost of your services. Check to see if you can save with insurance.
      </BodyRegular>
    </div>
  )
}

export const InsuranceBanner = () => {
  return (
    <Container>
      <TextWrapper>
        <IconWrapper>
          <UilShieldCheck size={40} color="#51459E" />
        </IconWrapper>
        <InsuranceText />
      </TextWrapper>
      <ButtonWrapper>
        <Button
          text="Learn more about coverage"
          theme="secondary"
          fontSize={12}
          to="/insurance-partners"
          borderWidth={3}
          paddingHorizontal={24}
        />
      </ButtonWrapper>
    </Container>
  )
}
