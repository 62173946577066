import { BodyRegular, H3, H4 } from "../../../ui"
import { WaysWeCanHelpWrapper, Row, Item } from "./styled"

export const WaysWeCanHelp = () => {
  return (
    <WaysWeCanHelpWrapper>
      <H3>Ways We Can Help</H3>
      <Row>
        <Item>
          <H4>Therapy</H4>
          <BodyRegular>
            Therapy can help identify the root of your symptoms and develop the
            skills to manage them.
          </BodyRegular>
        </Item>
        <Item>
          <H4>Medication</H4>
          <BodyRegular>
            Medication can help reduce symptoms of mental health conditions.
          </BodyRegular>
        </Item>
        <Item>
          <H4>Therapy + Medication</H4>
          <BodyRegular>
            Both treatments may create better outcomes + reduce the risk of
            setbacks.
          </BodyRegular>
        </Item>
      </Row>
    </WaysWeCanHelpWrapper>
  )
}
