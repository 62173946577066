export type ColorWeight =
  | 50
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900

export type Color =
  | "c_primary"
  | "c_secondary"
  | "c_tertiary"
  | "c_violet"
  | "c_grey"
  | "c_green"
  | "c_yellow"
  | "c_orange"
  | "c_red"
  | "c_white"
  | "c_black"

export type ColorString = `${Color}.${ColorWeight}`

const colors = {
  c_white: {
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FFFFFF",
    400: "#FFFFFF",
    500: "#FFFFFF",
    600: "#FFFFFF",
    700: "#FFFFFF",
    800: "#FFFFFF",
    900: "#FFFFFF",
  },
  c_black: {
    50: "#1C1E21",
    100: "#1C1E21",
    200: "#1C1E21",
    300: "#1C1E21",
    400: "#1C1E21",
    500: "#1C1E21",
    600: "#1C1E21",
    700: "#1C1E21",
    800: "#1C1E21",
    900: "#1C1E21",
  },
  c_primary: {
    50: "#F6F4FF",
    100: "#ECEAFF",
    200: "#D9D5FF",
    300: "#C7BFFF",
    400: "#AFA3FF",
    500: "#7D70D2",
    600: "#51459E",
    700: "#422E80",
    800: "#341863",
    900: "#250044",
  },
  c_secondary: {
    50: "#F2F5FF",
    100: "#E5EBFF",
    200: "#CBD8FF",
    300: "#A7BCFF",
    400: "#7E9DFF",
    500: "#5D7DEF",
    600: "#385ADE",
    700: "#2C46AD",
    800: "#1F2C8D",
    900: "#12106B",
  },
  c_tertiary: {
    50: "#EEF8F9",
    100: "#DDF1F3",
    200: "#BBE3E6",
    300: "#99D6DA",
    400: "#77C8CD",
    500: "#55BAC1",
    600: "#3FA8B0",
    700: "#388C92",
    800: "#2F666A",
    900: "#244E51",
  },
  c_violet: {
    50: "#FAF5FF",
    100: "#F6EBFF",
    200: "#ECD7FE",
    300: "#E3C2FE",
    400: "#D09AFD",
    500: "#C45BFF",
    600: "#9D42D0",
    700: "#781CAB",
    800: "#5D1585",
    900: "#471065",
  },
  c_grey: {
    50: "#FAFAFC",
    100: "#F0F1F5",
    200: "#E1E2E8",
    300: "#C9CBD2",
    400: "#AAAEB8",
    500: "#9BA0AC",
    600: "#898E99",
    700: "#626874",
    800: "#4D515A",
    900: "#353A41",
  },
  c_green: {
    50: "#E8F7EE",
    100: "#B9E6C9",
    200: "#97DAAF",
    300: "#68C98B",
    400: "#4ABF75",
    500: "#1DAF52",
    600: "#1A9F4B",
    700: "#157C3A",
    800: "#10602D",
    900: "#0C4A22",
  },
  c_yellow: {
    50: "#FFFAED",
    100: "#FEF4DB",
    200: "#FDE9B7",
    300: "#FDDF92",
    400: "#FCD46E",
    500: "#FBC94A",
    600: "#E3B03D",
    700: "#B68126",
    800: "#956215",
    900: "#764906",
  },
  c_orange: {
    50: "#FDF2EB",
    100: "#FFDCC7",
    200: "#FDC7A5",
    300: "#F9AA79",
    400: "#F69A60",
    500: "#F3823B",
    600: "#D36E2E",
    700: "#A55624",
    800: "#884418",
    900: "#613315",
  },
  c_red: {
    50: "#FEF2F2",
    100: "#FDE5E5",
    200: "#FCCCCC",
    300: "#FAB2B2",
    400: "#F99999",
    500: "#F06464",
    600: "#D83024",
    700: "#B42828",
    800: "#951D1D",
    900: "#6C0D0D",
  },
} satisfies Record<Color, Record<ColorWeight, `#${string}`>>

const primaryButton = {
  background: colors.c_primary[600],
  text: colors.c_white[500],
}

const secondaryButton = {
  background: colors.c_white[500],
  text: colors.c_primary[600],
}

export { primaryButton, secondaryButton, colors }
