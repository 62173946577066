import { H3 } from "../../../ui"
import {
  PopularLocationsContainer,
  PopularLocationsList,
  DesktopColumn,
  MobileColumn,
  LocationLink,
  LocationLinkContainer,
} from "./styled"

export interface IPopularLocationsProps {
  locations: { name: string; linkEnd: string }[]
  carePersonType: "therapists" | "prescribers"
  title: string
}

export const PopularLocations = ({
  locations,
  carePersonType,
  title,
}: IPopularLocationsProps) => {
  const alphabeticallySortedLocations = locations.sort((a, b) =>
    a.name > b.name ? 1 : -1
  )
  const thirdOfList = Math.round(alphabeticallySortedLocations.length / 3 + 1)
  const secondThirdOfList = Math.round(thirdOfList + thirdOfList)
  const desktopColumn1 = alphabeticallySortedLocations.slice(
    0,
    Math.max(thirdOfList)
  )
  const desktopColumn2 = alphabeticallySortedLocations.slice(
    Math.max(thirdOfList),
    secondThirdOfList
  )
  const desktopColumn3 = alphabeticallySortedLocations.slice(secondThirdOfList)
  const mobileColumn1 = alphabeticallySortedLocations.slice(0, 25)
  const mobileColumn2 = alphabeticallySortedLocations.slice(25)
  return (
    <PopularLocationsContainer>
      <H3>{title}</H3>
      <PopularLocationsList>
        <DesktopColumn>
          {desktopColumn1.map(({ name, linkEnd }) => (
            <LocationLinkContainer>
              <LocationLink to={`/${carePersonType}/${linkEnd}`}>
                {name}
              </LocationLink>
            </LocationLinkContainer>
          ))}
        </DesktopColumn>
        <DesktopColumn>
          {desktopColumn2.map(({ name, linkEnd }) => (
            <LocationLinkContainer>
              <LocationLink to={`/${carePersonType}/${linkEnd}`}>
                {name}
              </LocationLink>
            </LocationLinkContainer>
          ))}
        </DesktopColumn>
        <DesktopColumn>
          {desktopColumn3.map(({ name, linkEnd }) => (
            <LocationLinkContainer>
              <LocationLink to={`/${carePersonType}/${linkEnd}`}>
                {name}
              </LocationLink>
            </LocationLinkContainer>
          ))}
        </DesktopColumn>
        <MobileColumn>
          {mobileColumn1.map(({ name, linkEnd }) => (
            <LocationLinkContainer>
              <LocationLink to={`/${carePersonType}/${linkEnd}`}>
                {name}
              </LocationLink>
            </LocationLinkContainer>
          ))}
        </MobileColumn>
        <MobileColumn>
          {mobileColumn2.map(({ name, linkEnd }) => (
            <LocationLinkContainer>
              <LocationLink to={`/${carePersonType}/${linkEnd}`}>
                {name}
              </LocationLink>
            </LocationLinkContainer>
          ))}
        </MobileColumn>
      </PopularLocationsList>
    </PopularLocationsContainer>
  )
}
