import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../../../themes/colors"

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
`

export const Category = styled.div`
  display: flex;
  background: ${colors.c_white[500]};
  border-radius: 18px;
  border: 1px solid #a8baff;
  width: 328px;
  height: 120px;
  justify-content: space-between;
  padding: 16px 24px;
`
export const CategoryTitle = styled.h2`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #222128;
`

export const CategoryText = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TopicsLink = styled(Link)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${colors.c_secondary[600]};
`
