import { Hero } from "../../../shared/Hero"
import { prescribersHeroContent } from "./constants"

export const PrescribersHero = (): JSX.Element => {
  return (
    <Hero
      title={prescribersHeroContent.title}
      blurb={prescribersHeroContent.blurb}
      carePersonType={prescribersHeroContent.carePersonType}
    />
  )
}
