import React from "react"
import { Hero } from "../../../shared/Hero"
import { therapistHeroContent } from "./constants"
export const TherapistHero = (): JSX.Element => {
  return (
    <Hero
      title={therapistHeroContent.title}
      blurb={therapistHeroContent.blurb}
      carePersonType={therapistHeroContent.carePersonType}
    />
  )
}
