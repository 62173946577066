import styled from "styled-components"
import { colors } from "../../../../themes/colors"

export const BackToTopButton = styled.button<{ visible: boolean }>`
  display: ${props => (props.visible ? "inline" : "none")};
  position: fixed;
  bottom: 24px;
  right: 16px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: ${colors.c_primary[900]};
  color: ${colors.c_white[500]};
  padding: 16px 24px;
  border-radius: 30px;

  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 16px;
`
