import CareTeam from "./images/careteam.webp"
import HandsHeart from "./images/hands_heart.webp"
import MedForm from "./images/med_form.webp"
import MedFormAlt from "./images/med_form_alt.webp"

export const genericWidgetCopy = [
  {
    planName: "Medication",
    planType: "medication",
    icon: MedForm,
    title: "Find the best way for you to improve your overall mental health",
    subTitle: "Click on our plans below to learn more",
    description:
      "Meet 1-on-1 with a licensed prescriber to get comprehensive medication guidance",
  },
  {
    planName: "Individual Therapy",
    planType: "individual",
    icon: CareTeam,
    title: "Find the best way for you to improve your overall mental health",
    subTitle: "Click on our plans below to learn more",
    description:
      "Meet 1-on-1 with a credentialed compassionate therapist via video chat or over the phone",
  },
  {
    planName: "Therapy + Medication",
    planType: "medication-therapy",
    icon: HandsHeart,
    title: "Find the best way for you to improve your overall mental health",
    subTitle: "Click on our plans below to learn more",
    description:
      "Build long-term coping skills in therapy and reduce symptoms with medication at the same time",
  },
]

export const couplesTherapyWidgetCopy = [
  {
    planName: "Medication",
    planType: "medication",
    icon: MedForm,
    title: "Find the best way for you to improve your overall mental health",
    subTitle: "Click on our plans below to learn more",
    description:
      "Meet 1-on-1 with a licensed prescriber to get comprehensive medication guidance",
  },
  {
    planName: "Individual Therapy",
    planType: "individual",
    icon: CareTeam,
    title: "Find the best way for you to improve your overall mental health",
    subTitle: "Click on our plans below to learn more",
    description:
      "Meet 1-on-1 with a credentialed compassionate therapist via video chat or over the phone",
  },
  {
    planName: "Couples Therapy",
    planType: "couples",
    icon: MedFormAlt,
    title: "Find the best way for you to improve your overall mental health",
    subTitle: "Click on our plans below to learn more",
    description:
      "Work with your partner and a therapist to strengthen your bond and improve communication",
  },
  {
    planName: "Therapy + Medication",
    planType: "medication-therapy",
    icon: HandsHeart,
    title: "Find the best way for you to improve your overall mental health",
    subTitle: "Click on our plans below to learn more",
    description:
      "Build long-term coping skills in therapy and reduce symptoms with medication at the same time",
  },
]
