/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

// NOTE: This component uses the title that is provided from prismic by default. You can override this by including the `titleOverride` prop and setting it to `true`.
//       This will use the title that is passed in as a prop instead of the title from prismic. This can be useful for A/B tests

import { useLocation } from "@reach/router"
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet-async"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  url = "",
  title = "Cerebral",
  titleSuffix = "",
  image = "https://media.cerebral.com/Web/MetaDataImages/DefaultImage.png",
  schemaMarkup = {},
  canonicalTag,
  hideFromSearchEngines = false,
  titleOverride = false,
}) {
  const { pathname } = useLocation()
  const { site, seo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        seo: allPrismicPageMetaData {
          edges {
            node {
              data {
                page_path
                title
              }
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  let titleTag
  if (title) {
    if (titleSuffix) {
      titleTag = `${title} | ${titleSuffix}`
    } else {
      titleTag = title
    }
  } else {
    titleTag = site.siteMetadata.title
  }

  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

  const prismicTitle = seo.edges.find(item => {
    return (
      item.node.data.page_path === pathname ||
      item.node.data.page_path === pathname + "/"
    )
  })?.node?.data?.title

  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:image`,
      content: image,
    },
    {
      property: `og:image:type`,
      content: `image/png`,
    },
    {
      property: `og:image:width`,
      content: `1200`,
    },
    {
      property: `og:image:height`,
      content: `630`,
    },
    {
      property: `og:title`,
      content: titleTag,
    },
    {
      property: `og:site_name`,
      content: "Cerebral",
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:url`,
      content: `${process.env.GATSBY_BASE_URL}${url}`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: prismicTitle || titleTag,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `robots`,
      content: `${
        activeEnv.includes("development") || hideFromSearchEngines
          ? "none"
          : "all"
      }`,
    },
    {
      name: `google-site-verification`,
      content: `${
        activeEnv.includes("development")
          ? "GRyatA1ynG3w1acbBAH8Lgz7rfrKQV_wKhlueYYveHw"
          : ""
      }`,
    },
    {
      name: `google-site-verification`,
      content: `${
        activeEnv.includes("development")
          ? "thFCSSFVHBy4ArN996tE1POeu5cuT4zIZCM0qcj3dvI"
          : ""
      }`,
    },
  ].concat(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleOverride ? titleTag : prismicTitle || titleTag}
      meta={metaTags}
    >
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
      {canonicalTag && <link rel="canonical" href={canonicalTag} />}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonicalTag: PropTypes.string,
}

export default SEO
