import styled from "styled-components"
import webView from "./assets/web-view.webp"
import mobileView from "./assets/mobile-view.webp"
import { colors } from "../../../themes/colors"

export const Wrapper = styled.section`
  background-image: url(${webView});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 112px 0;
  @media (max-width: 800px) {
    background-image: url(${mobileView});
    background-size: cover;
    min-height: 896px;
    padding: 104px 0px;
  }
  @media (max-width: 499px) {
    background-image: url(${mobileView});
    background-size: cover;
    padding: 104px 0px;
  }
`

export const ContentContainer = styled.div`
  width: 50%;
  align-items: left;
  background-size: contain;
  align-items: left;
  margin-left: 10%;
  max-width: 465px;
  & > h2 {
    color: ${colors.c_white[500]};
  }
  & > div {
    margin-bottom: 24px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 834px) {
    padding: 0 24px;
    align-items: center;
    width: unset;
    margin: 0px 8px;
  }
  @media (max-width: 499px) {
    margin: 0px;
    & > h2 {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }
`

export const TextContainer = styled.div`
  width: 492px;
  margin-right: 0;
  box-sizing: border-box;
  margin-top: 16px;
  & > h4 {
    color: ${colors.c_white[500]};
  }
  @media (max-width: 833px) {
    max-width: fit-content;
    justify-content: left;
    align-items: left;
    width: 100%;
    & > h4 {
      margin-top: 30px;
      margin-bottom: 0;
    }
  }
  @media (max-width: 499px) {
    max-width: fit-content;
    justify-content: left;
    align-items: left;
    width: 100%;
    margin: 0;
  }
`

export const TextDescription = styled.p`
  font-weight: 300;
  font-size: 16px;
  weight: 300;
  width: 100%;
  color: ${colors.c_white[500]};
  line-height: 24px;
  font-style: normal;
  @media (max-width: 833px) {
    max-width: 100%;
    font-size: 14px;
    line-height: 22px;
  }
`
