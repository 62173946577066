interface CategoryInfo {
  topicImage: string
  pageImage: string
  subTitle: string
}

type CategoryPageInfo = {
  [key: string]: CategoryInfo
}

import { Section, FaqResult } from "../../../types/ZendeskApi"

export const categoryPageInfo: CategoryPageInfo = {
  "About Cerebral": {
    topicImage:
      "https://cerebral.cdn.prismic.io/cerebral/5674b6da-dcbf-43a7-b3c1-31ade4c149c1_faq-about-cerebral-lp.svg",
    pageImage:
      "https://cerebral.cdn.prismic.io/cerebral/533fb1ed-9c08-416f-b1ac-c04918c44693_faq-about-cerebral.svg",
    subTitle:
      "More about our mission, our approach to mental healthcare, and who we serve.",
  },
  "Plans, Costs & Insurance": {
    topicImage:
      "https://cerebral.cdn.prismic.io/cerebral/4fa95188-ec2b-4dcf-9250-99cbedde2c18_faq-plans-cost-insurance-lp.svg",
    pageImage:
      "https://cerebral.cdn.prismic.io/cerebral/5602965e-4180-46e2-94e8-ec447b174b09_faq-plans-costs-insurance.svg",
    subTitle:
      "More on insurance coverage for Cerebral, plan costs, and what you can expect to pay.",
  },
  Medication: {
    topicImage:
      "https://cerebral.cdn.prismic.io/cerebral/07680e5d-7185-4205-bfb6-a7cf12a6cb77_faq-medication-lp.svg",
    pageImage:
      "https://cerebral.cdn.prismic.io/cerebral/2c8463a6-660d-46c7-ab8b-88770795ada3_faq-medication.svg",
    subTitle:
      "More about the conditions we treat with medication and how our medications plans work.",
  },
  Therapy: {
    topicImage:
      "https://cerebral.cdn.prismic.io/cerebral/329ea368-1892-49c7-8629-e245022aa5ac_faq-therapy-lp.svg",
    pageImage:
      "https://cerebral.cdn.prismic.io/cerebral/2b69b128-9940-4f60-9d29-addac2ade976_faq-therapy.svg",
    subTitle:
      "More about Cerebral therapists, our various approaches to therapy, and how our therapy plans work.",
  },
}

export const mockSection: Section = {
  id: 27093619229719,
  url: "https://cerebral.zendesk.com/api/v2/help_center/en-us/sections/27093619229719.json",
  html_url:
    "https://support.getcerebral.com/hc/en-us/sections/27093619229719-About-Cerebral",
  category_id: 27093485592471,
  position: 0,
  sorting: "manual",
  created_at: "2024-10-15T20:36:11Z",
  updated_at: "2024-10-28T22:46:34Z",
  name: "About Cerebral",
  description: "",
  locale: "en-us",
  source_locale: "en-us",
  outdated: false,
  parent_section_id: null,
  theme_template: "section_page",
}

export const mockFaqs: FaqResult[] = [
  {
    id: 27124280194199,
    url: "https://cerebral.zendesk.com/api/v2/help_center/en-us/articles/27124280194199.json",
    html_url:
      "https://support.getcerebral.com/hc/en-us/articles/27124280194199-How-do-I-choose-a-couples-therapist",
    author_id: 1500392921041,
    comments_disabled: true,
    draft: false,
    promoted: false,
    position: 5,
    vote_sum: 0,
    vote_count: 0,
    section_id: 27093609815063,
    created_at: "2024-10-16T21:28:50Z",
    updated_at: "2024-10-23T17:16:17Z",
    name: "How do I choose a couples therapist?",
    title: "How do I choose a couples therapist?",
    source_locale: "en-us",
    locale: "en-us",
    outdated: false,
    outdated_locales: [],
    edited_at: "2024-10-16T21:28:50Z",
    user_segment_id: null,
    permission_group_id: 3646893,
    content_tag_ids: [],
    label_names: [],
    body: "<p>You’ll choose a therapist when you sign up as a new client. We’ll show you all available therapists in your area, and you can read their bios and see their schedule. Then you can book your first session at any available time that works for you and your partner.</p>",
    user_segment_ids: [],
    snippet:
      "You’ll choose a therapist when you sign up as a new client. We’ll show you all available therapists in your area, and you",
    result_type: "article",
  },
]
