import { useEffect } from "react"
import SEO from "../../SEO"
import {
  Clinicians,
  HeroSection,
  QualityDefinesUs,
  TheCerebralWay,
} from "./sections"

import { Navigation } from "../../ui/Navigation"
import { CareTeamPageBody } from "./styled"
import segment from "../../../lib/segment"
import Cookies from "js-cookie"
import { TestimonialSection } from "../../shared/Testimonials/TestimonialSection"

export const CareTeamBody = (): JSX.Element => (
  <CareTeamPageBody>
    <HeroSection />
    <QualityDefinesUs />
    <Clinicians />
    <TestimonialSection />
    <TheCerebralWay />
  </CareTeamPageBody>
)

export const CareTeamPage = (): JSX.Element => {
  useEffect(() => {
    // To track Impact conversions
    segment.setImpactClickID()

    // Sets cookie for testing segment on DEV enviroments
    const params = new URLSearchParams(window.location.search)
    if (
      params.get("enable_segment_track") &&
      !Cookies.get("enable_segment_track")
    ) {
      Cookies.set("enable_segment_track", "true", { expires: 1 })
    }
  }, [])

  return (
    <>
      <SEO
        title="Cerebral Care Team"
        titleSuffix="Dedicated Mental Health Experts"
        description="Meet the Cerebral care team of therapists and prescribers dedicated to your mental wellness. Experience comprehensive care personalized just for you."
        url="care-team/"
        schemaMarkup={{}}
      />
      <Navigation />
      <CareTeamBody />
    </>
  )
}
