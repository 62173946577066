import { useEffect, useState } from "react"
import { BackToTopButton } from "./styled"

export const BackToTop = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 1000) {
      setVisible(true)
    } else if (scrolled <= 1000) {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible)
  }, [])

  return (
    <BackToTopButton visible={visible} onClick={scrollToTop}>
      Back to top
    </BackToTopButton>
  )
}
