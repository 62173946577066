import React, { useState } from "react"
import styled from "styled-components"
import { useFormik } from "formik"
import * as Yup from "yup"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import ItalicHeading from "../../components/ui/ItalicHeading"
import phone from "../../images/contact-phone.svg"
import email from "../../images/contact-email.svg"
import oopsImage from "../../images/oops.svg"
import DynamicNavigation from "../../components/ui/DynamicNavigation"
import { localizedStrings } from "../../lib/variations"

const Wrapper = styled.div`
  padding: 60px 0;
  text-align: center;
  color: #6786ff;
  & p {
    margin: 70px auto 0;
  }
`
const Heading = styled.div`
  font-family: "EB Garamond";
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  width: 80%;
  margin: 74px auto 0;
`
const ContactLineList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
`
const ContactsLine = styled.li`
  display: flex;
  align-items: center;
  margin: 20px auto 0;
`
const ContactsIcon = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 20px;
`
const ContactsText = styled.div`
  & a {
    font-size: 14px;
    text-decoration: none;
    color: #250044;
  }
`

const Form = styled.form`
  position: relative;
  margin: 10px auto 0;
  display: flex;
  flex-direction: column;
  @media (min-width: 834px) {
    width: 60%;
  }
`
const Input = styled.input`
  background: #ffffff;
  border: 1px solid #6786ff;
  border-radius: 2px;
  margin: 5px;
  padding: 20px;
  color: #6786ff;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: #6786ff;
  }
`
const Message = styled.textarea`
  background: #ffffff;
  border: 1px solid #6786ff;
  border-radius: 2px;
  margin: 5px;
  color: #6786ff;
  padding: 20px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: #6786ff;
  }
`
const ColumnInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 28px auto 0;
  @media (min-width: 834px) {
    flex-direction: row;
    & ${Input} {
      flex: 1;
    }
  }
`
const Submit = styled.button`
  background: linear-gradient(90deg, #6786ff 6.2%, #85abee 100%);
  box-shadow: 0px 7px 14px rgba(34, 34, 52, 0.13);
  border-radius: 30px;
  border: none;
  color: #fff;
  padding: 13px 40px;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  width: min-content;
  margin: 40px auto;

  &:disabled {
    cursor: none;
    background: #aaa;
  }
`
const Sending = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Sent = styled.div`
  padding: 120px 0;
  max-width: 320px;
  margin: 0 auto;
  h4 {
    font-style: italic;
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    letter-spacing: -0.4px;
  }

  p {
    margin: 40px auto 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
  }
`
const ErrorWrapper = styled.div`
  max-width: 300px;
  margin: 83px auto;
`
const Oops = styled.div`
  background-image: url(${oopsImage});
  width: 263px;
  height: 148px;
  margin: 0 auto;
`
const ErrorText = styled.div`
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #6786ff;
  margin: 20px auto 0;
  a {
    color: #6786ff;
    white-space: nowrap;
    text-decoration: none;
    font-weight: 500;
  }
`

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  email: Yup.string().email("Please fill correct email.").required("Required"),
  message: Yup.string().required(),
})

interface ContactPageProps {
  hideNavigation: boolean
  CustomLayout?: React.ComponentType
}

const ContactPage: React.FC<ContactPageProps> = ({
  hideNavigation,
  CustomLayout,
}) => {
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit = async (values: {
    first_name: string
    last_name: string
    email: string
    message: string
  }) => {
    setSending(true)

    try {
      const result = await fetch(process.env.GATSBY_CONTACT_URL as string, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request: {
            requester: {
              name: `${values.first_name} ${values.last_name}`,
              email: values.email,
            },
            subject: `Contact Request`,
            comment: { body: values.message },
          },
        }),
      })

      if (result.status !== 201) {
        throw new Error("Error Contact request please try again later")
      }

      setSent(true)
    } catch (err) {
      setError(true)
    }

    setSending(false)
  }

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: values => onSubmit(values),
  })

  const PageLayout = CustomLayout || Layout

  return (
    <PageLayout>
      <SEO
        title="Cerebral Contact Us"
        titleSuffix="Call or email us today"
        description="Need to get a hold of Cerebral? Issues with your prescription? Call or email us now, we'd love to hear from you!"
      />
      {!hideNavigation && (
        <DynamicNavigation bannerLookupKey="banner-default" />
      )}
      <Wrapper>
        <ItalicHeading>Contact Us</ItalicHeading>
        <p
          dangerouslySetInnerHTML={{
            __html: localizedStrings.contactUsPage.officeHours,
          }}
        ></p>

        {!sent && !error && (
          <Heading>
            You can also leave us a message below and someone from our team will
            get back to you as soon as possible.
          </Heading>
        )}

        {sent && (
          <Sent>
            <h4>Message Sent.</h4>
            <p>
              Thank you for reaching out to us. Someone from the Cerebral team
              will get back to you as soon as possible. Just check your inbox
              for updates.
            </p>
          </Sent>
        )}

        {error && (
          <ErrorWrapper>
            <Oops />
            <ErrorText>
              An error occurred while trying to send your message. Please reach
              out to our support team at{" "}
              <a href="tel:(415)617-9894">(415) 617 - 9894</a> or email us at{" "}
              <a href="mailto:support@cerebral.com">support@cerebral.com</a>{" "}
              directly with your inquiry.
            </ErrorText>
          </ErrorWrapper>
        )}

        {!sent && !error && (
          <Form onSubmit={formik.handleSubmit}>
            {sending && <Sending>Loading...</Sending>}
            <ColumnInputs>
              <Input
                name="first_name"
                placeholder="First name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                required
              />
              <Input
                name="last_name"
                placeholder="Last name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
              />
            </ColumnInputs>
            <Input
              name="email"
              placeholder="Email"
              value={formik.values.email}
              type="email"
              onChange={formik.handleChange}
              required
            />
            <Message
              name="message"
              placeholder="Type your message here..."
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              required
            />

            <Submit type="submit" disabled={sending}>
              Submit
            </Submit>
          </Form>
        )}

        {
          <ContactLineList>
            <ContactsLine>
              <ContactsIcon src={phone} />
              <ContactsText>
                <a href="tel:(415) 403 - 2156">(415) 403 - 2156</a>
              </ContactsText>
            </ContactsLine>
            <ContactsLine>
              <ContactsIcon src={email} />
              <ContactsText>
                <a href="mailto:support@cerebral.com">support@cerebral.com</a>
              </ContactsText>
            </ContactsLine>
          </ContactLineList>
        }
      </Wrapper>
    </PageLayout>
  )
}

export default ContactPage
