import styled from "styled-components"

export const Container = styled.section<{ background: string }>`
  background: ${({ background }) => background};
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 72px 0 56px;
  & > p {
    max-width: 776px;
    margin: 12px auto 24px;
    padding: 0 24px;
  }
  @media (max-width: 834px) {
    padding: 72px 0 40px;
    & > p {
      margin-top: 24px;
    }
  }
  @media (max-width: 499px) {
    padding: 72px 0 40px;
    & > h2 {
      max-width: 241px;
      margin: 0 auto;
    }
  }
`

export const TherapistsDesktopContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin: 16px 0 24px;
  padding: 0 24px;
  @media (max-width: 1022px) {
    display: none;
  }
`

export const TherapistsMobileContainer = styled.div`
  display: none;
  @media (max-width: 1022px) {
    display: block;
  }
`
