import SEO from "../../SEO"
import { Navigation } from "../../ui/Navigation"
import { CareTeamPrescribersPageBody } from "./styled"
import { LearnMore, Rediscover } from "../../shared"
import { PopularTopic } from "../shared/types"
import {
  PrescribersPopularCities,
  PrescribersPopularStates,
  PrescribersHero,
  MedicationFaqs,
} from "./sections"
import { organizationSchemaMarkup } from "../../../common/OrganizationSeoSchema"

export const CareTeamPrescribersBody = (): JSX.Element => (
  <CareTeamPrescribersPageBody>
    <PrescribersHero />
    <PrescribersPopularStates />
    <PrescribersPopularCities />
    <MedicationFaqs />
    <LearnMore />
    <Rediscover />
  </CareTeamPrescribersPageBody>
)

export const CareTeamPrescribersPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="Care Team Prescribers | Cerebral"
        description="Connect with a licensed prescriber at Cerebral for personalized mental health medication. Get a prescription to support your emotional well-being."
        url="care-team/prescribers/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <CareTeamPrescribersBody />
    </>
  )
}
