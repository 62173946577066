import { useEffect, useState } from "react"
import {
  CategoriesContainer,
  CategoriesTitle,
  CategoriesWrapper,
  CategoryButton,
  CategoryListWrapper,
  ShowMoreButton,
} from "./styled"

interface BlogCategoriesProps {
  categories: string[]
  activeCategories: string[]
  onSelected: (activeCategory: string) => void
}

export const BlogCategories = ({
  categories,
  activeCategories,
  onSelected,
}: BlogCategoriesProps) => {
  const MOBILE_MAX_WIDTH = 888
  const MOBILE_INITIAL_CATEGORIES_MAX_LENGTH = 4
  const DESKTOP_INITIAL_CATEGORIES_MAX_LENGTH = 8

  const initialMappingSelectedCategories = categories.map(category => ({
    category,
    isShowMoreHide: false,
  }))

  const [isAllShown, setIsAllShown] = useState(false)
  const [initialMaxLength, setInitialMaxLength] = useState(8)
  const [mappingSelectedCategories, setMappingSelectedCategories] = useState(
    initialMappingSelectedCategories
  )
  const shouldHideShowMore = mappingSelectedCategories.some(
    item => item.isShowMoreHide
  )

  const handleOnSelected = (category: string) => {
    {
      const isCategorySelected = activeCategories.includes(category)
      onSelected(category)

      if (isCategorySelected) {
        setMappingSelectedCategories(
          mappingSelectedCategories.filter(c => c.category !== category)
        )
        return
      }

      setMappingSelectedCategories([
        ...mappingSelectedCategories,
        {
          category,
          isShowMoreHide: categories.indexOf(category) > initialMaxLength - 1,
        },
      ])
    }
  }

  const setInitial = (width: number) => {
    if (width <= MOBILE_MAX_WIDTH) {
      setInitialMaxLength(MOBILE_INITIAL_CATEGORIES_MAX_LENGTH)
    } else {
      setInitialMaxLength(DESKTOP_INITIAL_CATEGORIES_MAX_LENGTH)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setInitial(window.innerWidth)
    }

    setInitial(window.innerWidth)
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <CategoriesWrapper>
      <CategoriesContainer>
        <CategoriesTitle>How can we help you?</CategoriesTitle>

        <CategoryListWrapper isAllShown={isAllShown}>
          {categories.map(category => (
            <CategoryButton
              isActivated={activeCategories.includes(category)}
              onClick={() => handleOnSelected(category)}
              key={category}
            >
              {category}
            </CategoryButton>
          ))}
        </CategoryListWrapper>

        {!shouldHideShowMore && (
          <ShowMoreButton onClick={() => setIsAllShown(!isAllShown)}>
            {isAllShown ? "Show less" : "Show more"}
          </ShowMoreButton>
        )}
      </CategoriesContainer>
    </CategoriesWrapper>
  )
}
