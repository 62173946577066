import styled from "styled-components"
import { BodyRegular } from "../../../ui"
import { colors } from "../../../themes/colors"

export const SeoCopy = styled.section<{ isTextExpanded: boolean }>`
  max-height: ${({ isTextExpanded }) => (isTextExpanded ? "none" : "500px")};
  overflow: hidden;
  max-width: 991px;
  h2 {
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 8px;
    margin: 16px auto;
  }

  h3 {
    /* Heading/H4 */
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 16px auto;
  }
  p {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 16px auto;
  }
`

export const ReadMoreCopy = styled(BodyRegular)`
  color: ${colors.c_secondary[600]};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 16px;
`
