import styled from "styled-components"
import { H1 } from "../../../../ui"

export const Container = styled.section`
  background: #eff2ff;
  background: linear-gradient(
    180deg,
    rgba(242, 245, 255, 1) 82%,
    rgba(255, 255, 255, 1) 100%
  );

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 176px;
  padding: 8% 12%;
  @media (max-width: 834px) {
    padding: 0px 24px 88px;
  }
`
export const Title = styled(H1)`
  text-align: center;
  margin-top: 16px;
`
