import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ConditionSection } from "../../../ui"
import { ConditionType } from "../../types"
import { Container, ContentContainer } from "./styled"
import { edgeToPostPreview } from "../../../../lib/utils"

const queryAllPosts = graphql`
  {
    items: allPrismicPost(
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: "en-us" } }
    ) {
      edges {
        node {
          uid
          id
          data {
            title {
              text
            }
            previewimage {
              gatsbyImageData(
                width: 864
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
            date(formatString: "MM/DD/YYYY")
            categories {
              category
            }
          }
        }
      }
    }
  }
`

export const ConditionSections = ({
  conditions,
}: {
  conditions: ConditionType[]
}): JSX.Element => {
  const posts = useStaticQuery(queryAllPosts)

  const postPreviews = posts.items.edges.map((edge: any) =>
    edgeToPostPreview({ edge })
  )

  return (
    <Container>
      <ContentContainer>
        {conditions.map(condition => {
          const post =
            condition.blog_tag &&
            postPreviews.find((postPreview: any) => {
              return postPreview.categories.includes(condition.blog_tag)
            })
          return (
            <ConditionSection
              key={condition.title.text}
              condition={condition}
              post={post}
            />
          )
        })}
      </ContentContainer>
    </Container>
  )
}
