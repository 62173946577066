import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f6f0;
  padding: 64px 0;

  @media screen and (max-width: 1024px) {
    padding: 40px 24px;
  }
`
export const Header = styled.h2`
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  color: ${colors.c_black[900]};
  margin-top: 16px;
  margin-bottom: 48px;

  @media screen and (max-width: 1024px) {
    font-size: 28px;
    line-height: 36px;
    margin: 24px 0;
  }
`

export const PlansContainer = styled.div`
  display: flex;
  background-color: #ffffff;
  border: 1px solid #250044;
  border-radius: 16px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    max-width: 480px;
  }
`

export const PlanContainer = styled(Link)<{ border: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 32px 24px;
  justify-content: space-between;
  width: 342px;
  text-decoration: none;
  cursor: pointer;

  ${({ border }) =>
    border &&
    "border-right: 1px solid #250044; border-left: 1px solid #250044;"}

  @media screen and (max-width: 1024px) {
    width: 100%;
    border-right: none;
    border-left: none;

    flex-direction: row;
    ${({ border }) =>
      border &&
      "border-top: 1px solid #250044; border-bottom: 1px solid #250044; "}
  }
`
export const PlansHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: row;
  }
`

export const PlansTitle = styled.h3`
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: ${colors.c_black[900]};
  word-spacing: 400px;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
    line-height: 24px;
    text-align: left;
    word-spacing: 0;
  }
`
export const LearnMoreLink = styled(Link)`
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: ${colors.c_secondary[600]};

  @media screen and (max-width: 1024px) {
    display: none;
  }
`
