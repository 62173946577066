import {
  Container,
  Category,
  CategoryText,
  CategoryTitle,
  TopicsLink,
} from "./styled"
import { convertToKebabCase } from "../../shared/utils"

import {
  getSectionsForCategory,
  useFaqCountsBySections,
} from "../../../../hooks/useZendeskApi"
import { categoryPageInfo } from "../constants"

export const FaqCategoryLinks = () => {
  const sections = getSectionsForCategory() // Use the custom hook
  const sectionIds = sections.map(section => section.id)
  const faqsCountBySection = useFaqCountsBySections(sectionIds)

  return (
    <Container>
      {sections.map(section => (
        <Category key={section.id}>
          <CategoryText>
            <CategoryTitle>{section.name}</CategoryTitle>
            <TopicsLink to={`/faqs/${convertToKebabCase(section.name)}`}>
              {`${
                faqsCountBySection[
                  section.id as keyof typeof faqsCountBySection
                ] || 0
              } Topics`}
            </TopicsLink>
          </CategoryText>
          <img
            src={
              (
                categoryPageInfo[
                  section.name as keyof typeof categoryPageInfo
                ] as { topicImage: string; pageImage: string; subTitle: string }
              )?.topicImage
            }
            alt={section.name}
          />
        </Category>
      ))}
    </Container>
  )
}
