import React from "react"
import styled from "styled-components"
import Container from "../../common/Container"
import PrismicText from "../ui/PrismicText"
import Heading from "../ui/Heading"
import Button from "../../common/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Files from "../ui/Files"
import BottomCta from "../ui/BottomCta"
import { Helmet } from "react-helmet-async"
import { LeadCapturePopup } from "../../components/shared/LeadCapturePopup"
import { leadCapturePopupVariables } from "../../components/shared/LeadCapturePopup/constants"

const Image = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  margin: 61px auto 0;
  box-shadow: 0px 7px 4px rgba(34, 34, 52, 0.13);
`
const Text = styled(PrismicText)`
  margin: 60px auto 0;
`
const Wrapper = styled.div`
  padding: 0 0 60px;
  text-align: center;
  ${Button} {
    margin: 60px auto 0;
  }

  ${Container} {
    overflow: hidden;
  }

  ${Files} {
    max-width: 320px;
  }

  @media (min-width: 600px) {
    ${Text}, ${Files} {
      max-width: 900px;
    }
  }

  @media (min-width: 834px) {
    ${Container} {
      overflow: hidden;
    }
  }
`

function Resource({ title, image, html, files, cta, url, pageContext }) {
  const images = getImage(image)

  return (
    <Wrapper>
      <Container>
        <Helmet>
          {pageContext.legacyUrl ? (
            <link
              rel="canonical"
              href={`${
                process.env.GATSBY_BASE_URL
              }/care-resources/${url.replace(/\//, "")}`}
            />
          ) : (
            ""
          )}
        </Helmet>
        <Heading>{title}</Heading>
        {image && <Image image={images} />}
        <Text dangerouslySetInnerHTML={{ __html: html }} />
        <Files files={files} />
        <BottomCta cta={cta} />
      </Container>
      <LeadCapturePopup
        url="/care-resources/anger-iceberg"
        experiment={leadCapturePopupVariables[0].experiment}
        variant={leadCapturePopupVariables[0].variant}
        storageKey={leadCapturePopupVariables[0].storageKey}
        segmentKey={leadCapturePopupVariables[0].segmentKey}
      />
    </Wrapper>
  )
}

export default Resource
