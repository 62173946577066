import { Header, YouImage } from "./styled"
import YouDesktop from "./assets/you-desktop.svg"
import YouMobile from "./assets/you-mobile.svg"
import { useWindowSize } from "../../../hooks"

export const HeroTitle = () => {
  const { isMobile } = useWindowSize()
  return (
    <Header>
      A new era of mental health care <br />
      <b>
        built with
        <YouImage src={YouDesktop} /> <br />
        in mind
      </b>
    </Header>
  )
}
