import styled from "styled-components"

export const BackDrop = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(98, 104, 116, 0.6);
`

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
`

export const Modal = styled.div`
  max-width: 946px;
  height: 438px;
  margin: 0 48px;
  background-color: #fff;
  box-shadow: 0px 5px 24px 4px rgba(28, 30, 33, 0.08),
    0px 13px 19px 2px rgba(28, 30, 33, 0.1),
    0px 7px 8px -4px rgba(28, 30, 33, 0.16);
  border-radius: 20px;
  display: flex;
  position: relative;
  overflow: auto;
  @media (max-width: 834px) {
    flex-direction: column;
    height: 604px;
  }
`

export const MainImage = styled.img`
  height: 438px;
  width: 50%;
  border-radius: 20px 0 0 20px;
  object-fit: cover;
  object-position: center 10%;
  @media (max-width: 834px) {
    height: 260px;
    width: 100%;
    object-position: center 20%;
    border-radius: 20px 20px 0 0;
  }
  @media (max-width: 600px) {
    height: 200px;
  }
`

export const ModalTextContainer = styled.div`
  padding: 72px 48px 46px 48px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  @media (max-width: 834px) {
    padding: 36px 24px;
  }
`

export const InputContainer = styled.div`
  position: relative;
`

export const Input = styled.input`
  color: var(--greyscale-cerebral-black, #250044);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 16px 12px 48px;
  border-radius: 4px;
  border: 1px solid #cbd8ff;
  background: #fff;
  width: 100%;
`

export const SubmitButton = styled.button`
  display: flex;
  width: 100%;
  height: 56px;
  padding: 12px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #51459e;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  border: none;
  cursor: pointer;
  &:disabled {
    background: #f0f1f5;
    color: #898e99;
    cursor: not-allowed;
  }
`

export const CloseButton = styled.button`
  background: transparent;
  height: 24px;
  width: 24px;
  padding: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 22px;
`

export const EnvelopeImage = styled.img`
  position: absolute;
  left: 16px;
  top: 12px;
`

export const SubmitButtonText = styled.span`
  img {
    vertical-align: text-bottom;
    margin-left: 8px;
  }
`

export const SuccessText = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  p {
    font-size: 10px;
  }
`

export const GreenCircle = styled.div`
  height: 6.6667px;
  width: 6.6667px;
  background-color: #23cdc3;
  border-radius: 50%;
`
