import Cookies from "js-cookie"
import { useEffect } from "react"

const env =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

export class QueryToCookieConverter {
  private key: string

  constructor(key: string) {
    this.key = key
  }

  private searchParams = new URLSearchParams(window.location?.search)

  public setKey(key: string) {
    this.key = key
  }

  public getKey() {
    return this.key
  }

  public setCookieParamFromQueryString(alternateKeyName?: string): void {
    if (this.searchParams.has(this.key) && alternateKeyName) {
      Cookies.set(alternateKeyName, this.searchParams.get(this.key) || "", {
        expires: 30,
        // for now we only will set the domain in production. Once we know the environment names for lower environments, we can add them as well.
        domain: env === "production" ? "cerebral.com" : undefined,
      })
    } else if (this.searchParams.has(this.key)) {
      Cookies.set(this.key, this.searchParams.get(this.key) || "", {
        expires: 30,
        // for now we only will set the domain in production. Once we know the environment names for lower environments, we can add them as well.
        domain: env === "production" ? "cerebral.com" : undefined,
      })
    }
  }

  public getCookieValue(): string | null {
    let param = null
    const cookieParam = Cookies.get(this.key)

    if (cookieParam) {
      param = cookieParam
    } else if (this.searchParams.has(this.key)) {
      param = this.searchParams.get(this.key)
    }

    return param as string
  }
}

const writeClickIdsToCookie = (clickIds: string[]) => {
  clickIds.forEach(clickId => {
    let alternateKeyName
    if (clickId === "d") alternateKeyName = "awclid"
    new QueryToCookieConverter(clickId).setCookieParamFromQueryString(
      alternateKeyName
    )
  })
}

export const ClickIdCookieWriter = ({ clickIds }: { clickIds: string[] }) => {
  useEffect(() => {
    writeClickIdsToCookie(clickIds)
  }, [clickIds])

  return null
}
