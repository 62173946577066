import { MedicationBlockCard } from "./styled"
import Pill from "./images/Pill.svg"

interface IMedicationBlockProps {
  header: string
  medicationList: string
}

export const MedicationBlock = ({
  header,
  medicationList,
}: IMedicationBlockProps) => {
  return (
    <MedicationBlockCard>
      <h3>
        {header} <img src={Pill} alt="" />
      </h3>
      <div dangerouslySetInnerHTML={{ __html: medicationList }} />
    </MedicationBlockCard>
  )
}
