import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f6f0;

  @media (max-width: 834px) {
    padding: 0 24px;
  }
`
export const Header = styled.h3`
  color: ${colors.c_black[900]};
  margin-top: 16px;
  margin-bottom: 32px;

  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;

  padding: 8px;
  border-radius: 8px;
  background-color: ${colors.c_primary[200]};

  @media (max-width: 834px) {
    font-size: 20px;
  }
`
