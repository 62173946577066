import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { Variation } from "../../lib/variations"
import Link from "../../common/Link"
import { VariationContext } from "../../lib/variations"

const Wrapper = styled.div<{
  bgBlack: boolean
}>`
  background: ${props => (props.bgBlack ? "#250044" : "#fbedc5")};
  display: flex;
  align-items: center;
  justify-content: center;
`

const GetStarted = styled(Link)<{
  bgBlack: boolean
}>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${props => (props.bgBlack ? "#fff" : "#222128")};
  cursor: pointer;
  display: block;
  text-align: center;
  text-decoration: underline;
  padding: 10px 30px;
  margin: 0 auto;
  span {
    text-decoration: underline;
  }
`

export interface PromotionalBannerProps {
  lookupKey: string
  plan: string
}

const PromotionalBanner = ({ lookupKey, plan }: PromotionalBannerProps) => {
  const variations: any = useContext(VariationContext)
  const [bgBlack, setBgBlack] = useState(false)
  const [signupUrl, setSignupUrl] = useState(process.env.GATSBY_START_URL)

  const getWindowPathname = () => {
    return typeof window !== "undefined" ? window.location.pathname : ""
  }

  useEffect(() => {
    const path = getWindowPathname()
    setSignupUrl(`${signupUrl}`)
    if (path && path.toLowerCase().includes("startyourjourney"))
      setBgBlack(true)
  }, [])

  if (!variations.has || !variations.has(lookupKey)) {
    return null
  }
  return (
    <Wrapper bgBlack={bgBlack}>
      <GetStarted to={signupUrl} bgBlack={bgBlack}>
        <Variation lookupKey={lookupKey} useDefault={false} />
      </GetStarted>
    </Wrapper>
  )
}

export default PromotionalBanner
