import styled from "styled-components"
import containerbackground from "./images/container-background.webp"

export const HeroContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 64px;
  background: rgba(242, 245, 255, 1);
  background-image: url(${containerbackground});
  background-size: cover;
  height: 411px;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    justify-content: space-around;
    padding: 16px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 1024px) {
    width: 1200px;
  }
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

export const ContentContainer = styled.div<{ carePersonType: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 64px;
  align-items: start;
  & > h1 {
    width: ${props =>
      props.carePersonType === "therapists" ? "440px" : "376px"};
    @media (max-width: 1024px) {
      display: flex;
      font-size: 28px;
      width: fit-content;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 16px;
      margin-top: 24px;
    }
  }
  & > p {
    width: ${props =>
      props.carePersonType === "therapists" ? "314px;" : "376px"};
  }
  @media (max-width: 1024px) {
    margin: 16px;
    & > p {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: 1024px) {
    & > p {
      display: none;
    }
  }
`

export const LocationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const DesktopContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`
export const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    display: none;
  }
`
