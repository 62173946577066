import Layout from "../../components/Layout"
import { FaqCategoryPage } from "../../components/FaqPages/FaqCategoryPage"
import { Section } from "../../types/ZendeskApi"
interface FaqCategoriesProps {
  pageContext: {
    section: Section
  }
}

const FaqCategories = (props: FaqCategoriesProps) => {
  const { section } = props.pageContext
  return (
    <Layout>
      <FaqCategoryPage section={section} />
    </Layout>
  )
}

export default FaqCategories
