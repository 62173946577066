import React, { forwardRef } from "react"
import {
  ContentWrapper,
  StepContentBlock,
  Step,
  TextBlock,
  BlockHeading,
} from "../styled"

const StepContent = forwardRef(
  (
    { heading, number }: { heading: string; number: string },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <ContentWrapper ref={ref}>
      <StepContentBlock>
        <Step>
          <TextBlock>
            <img src={number} alt="Step number" />
            <BlockHeading>{heading}</BlockHeading>
          </TextBlock>
        </Step>
      </StepContentBlock>
    </ContentWrapper>
  )
)

export default StepContent
