import SEO from "../../SEO"
import { Navigation } from "../../ui/Navigation"
import { CareTeamTherapistsPageBody } from "./styled"
import {
  TherapistHero,
  TherapistsPopularCities,
  TherapistsPopularStates,
  TherapistsPopularTopics,
} from "./sections"
import { LearnMore, Rediscover } from "../../shared"
import { PopularTopic } from "../shared/types"
import { organizationSchemaMarkup } from "../../../common/OrganizationSeoSchema"

export const CareTeamTherapistsBody = (): JSX.Element => (
  <CareTeamTherapistsPageBody>
    <TherapistHero />
    <TherapistsPopularStates />
    <TherapistsPopularCities />
    <TherapistsPopularTopics />
    <LearnMore />
    <Rediscover />
  </CareTeamTherapistsPageBody>
)

export const CareTeamTherapistsPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="Care Team Therapists | Cerebral"
        description="Cerebral's credentialed therapists provide expert mental health treatments and guidance—100% online. Find a professional and start online therapy."
        url="care-team/therapists/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <CareTeamTherapistsBody />
    </>
  )
}
