import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 64px;
  width: 1280px;
  margin: 0 auto;

  @media (max-width: 1295px) {
    width: 100%;
  }

  @media (max-width: 1024px) {
    padding-top: 32px;
    padding-bottom: 48px;
    width: 100%;
  }
`

export const BlogTopWrapper = styled.div`
  width: 100%;
  padding: 0 32px;

  @media (max-width: 1024px) {
    padding: 0 24px;
  }
`

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #626874;
`

export const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #626874;

  :visited {
    color: #626874;
  }
`

export const CategoryLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #626874;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 18px;

  :visited {
    color: #626874;
  }
`

export const BreadcrumbsTitle = styled.div`
  color: #250044;

  @media (max-width: 1024px) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 18px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`
export const Title = styled.h1`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  margin-top: 20px;
  margin-bottom: 32px;

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 30px;
  }
`

export const PreviewImage = styled.img`
  height: 640px !important;
  width: 100% !important;
  border-radius: 16px;
  margin-bottom: 48px;
  object-fit: cover;

  @media (max-width: 1024px) {
    height: 480px !important;
  }

  @media (max-width: 834px) {
    margin-bottom: 24px;
  }

  @media (max-width: 600px) {
    height: 320px !important;
  }

  @media (max-width: 450px) {
    height: 240px !important;
  }
`
export const SliceWrapper = styled.div`
  width: 720px;

  @media (max-width: 744px) {
    width: 100%;
  }
`
export const AuthorWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`
export const OldContentWrapper = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const OldContent = styled.div`
  max-width: 720px;
  font-size: 16px;
  font-weight: 400;
  color: #353a41;
  line-height: 24px;
  font-family: Poppins;

  @media (max-width: 884px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      max-width: calc(100vw - 48px);
    }
  }

  @media (max-width: 744px) {
    max-width: 100%;
    padding: 0 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 32px;
    font-weight: 600;
    color: #1c1e21;
    margin-bottom: 16px;
  }

  h1 {
    font-size: 32px;
    line-height: 38px;

    @media (max-width: 600px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  h2 {
    font-size: 28px;
    line-height: 34px;

    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h3 {
    font-size: 22px;
    line-height: 24px;

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;

    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
  }

  h6 {
    font-size: 14px;
    line-height: 24px;
  }

  span {
    display: inline-block !important;
  }

  span.align-left {
    text-align: left;
  }

  span.align-center {
    text-align: center;
    width: 100%;
  }

  span.align-right {
    text-align: right;
  }

  span.strikethrough {
    text-decoration: line-through;
  }

  span.underline {
    text-decoration: underline;
  }

  p {
    line-height: 24px;
    margin-top: 32px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 24px;
    }
  }

  a {
    color: #385ade;
    text-decoration-line: underline;

    :visited {
      color: #385ade;
    }
  }

  strong {
    font-weight: 600 !important;
  }

  ul {
    margin-bottom: 16px;
  }

  img {
    width: 100%;
    border-radius: 16px;
    height: 480px;
    object-fit: contain;

    @media (max-width: 600px) {
      height: 240px;
    }
  }

  ol {
    list-style-type: decimal;
    margin: 32px 0px 0px 16px;
    text-align: left;

    @media (max-width: 834px) {
      margin: 24px 0 0px 16px;
      font-size: 14px;
    }
  }

  ul {
    list-style: disc;
    margin: 32px 0px 0px 32px;
    text-align: left;

    @media (max-width: 834px) {
      margin: 24px 0 0px 32px;
      font-size: 14px;
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid #d83024;
    color: #666;
    page-break-inside: avoid;
    font-family: Poppins;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }

  em {
    font-style: italic;
  }

  li {
    line-height: 24px !important;
    margin-top: 16px;
  }
`
export const OldCtaButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #caeaec 0%, #f6e9fe 100%);
  padding: 48px 64px;
  border-radius: 16px;
  margin-bottom: 48px;
  gap: 32px;
  margin-top: 32px;

  @media (max-width: 834px) {
    padding: 48px 24px;
    margin-top: 16px;
  }

  @media (max-width: 744px) {
    border-radius: 0;
  }
`

export const OldCtaTitle = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 834px) {
    font-size: 20px;
  }
`

export const OldCtaSubTitle = styled.div`
  color: #353a41;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
`
