import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  padding: 64px 0px;
  display: flex;
  gap: 48px;
  flex-direction: column;
  text-align: center;
  font-family: Poppins;

  @media (max-width: 600px) {
    padding: 48px 24px;
    gap: 32px;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`
const Title = styled.div`
  color: #1c1e21;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 28px;
  }
`
const Description = styled.div`
  max-width: 482px;
  margin: auto;
  color: #4d515a;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`
const ContentWrapper = styled.div`
  display: flex;
  max-width: 1280px;
  padding: 0px 32px;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  margin: auto;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 32px;
  }

  @media (max-width: 600px) {
    padding: 0px 0px;
  }
`
const LeftBox = styled.div`
  display: flex;
  max-width: var(--base-S64, 512px);
  padding-top: var(--base-S3, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
`
const RightBox = styled.div`
  max-width: 456px;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: 600px) {
    gap: 16px;
  }
`
const IndexNumber = styled.div`
  color: #51459e;
  font-size: 48px;
  font-weight: 300;
  line-height: 40px;
  width: 32px;
`
const ItemContent = styled.div`
  color: #4d515a;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  max-width: 448px;
  text-align: left;
`
const ItemTitle = styled.div`
  color: #1c1e21;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
`

const Image = styled(GatsbyImage)`
  width: 100%;
  overflow: hidden;
  bottom: 0;
  left: 0;
  position: absolute !important;

  @media (max-width: 600px) {
    position: relative !important;
  }
`

const ImageWrapper = styled.div`
  width: 436px;
  height: 456px;
  position: relative;

  @media (max-width: 600px) {
    position: unset;
    width: 100%;
    height: auto;
  }
`

interface ImageRightWithListInterface {
  slice: {
    slice_type: string
    primary: {
      main_title: { text: string }
      main_description: { text: string }
      main_image: IGatsbyImageData
    }
    items: {
      item_content: { text: string }
      item_title: { text: string }
    }[]
  }
}

const ImageRightWithList = ({ slice }: ImageRightWithListInterface) => {
  const {
    primary: { main_description, main_title, main_image },
    items,
  } = slice

  const image = getImage(main_image) as IGatsbyImageData
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{main_title.text}</Title>
        <Description>{main_description.text}</Description>
      </TitleWrapper>
      <ContentWrapper>
        <LeftBox>
          {items.map((item, index) => (
            <ItemWrapper>
              <IndexNumber>{index + 1}</IndexNumber>
              <ItemContent>
                <ItemTitle>{item.item_title.text}</ItemTitle>
                <ItemContent>{item.item_content.text}</ItemContent>
              </ItemContent>
            </ItemWrapper>
          ))}
        </LeftBox>
        <RightBox>
          <ImageWrapper>
            <Image image={image} loading="lazy" alt="" />
          </ImageWrapper>
        </RightBox>
      </ContentWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageImageRightWithListSlice on PrismicPageDataBodyImageRightWithList {
    id
    items {
      item_content {
        text
      }
      item_title {
        text
      }
    }
    primary {
      main_description {
        text
      }
      main_image {
        gatsbyImageData
      }
      main_title {
        text
      }
    }
    slice_type
    slice_label
  }
`

export default ImageRightWithList
