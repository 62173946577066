import * as yup from "yup"

export const schema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  zipCode: yup.string().length(5, "Please enter a valid zip code"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter a valid email"),
  phone: yup
    .string()
    .matches(
      /^([+]?\d{1,2}[-\s]?|)\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/,
      "Please enter a valid phone number"
    )
    .optional(),
})
