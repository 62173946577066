import React, { useState, ReactNode, useRef } from "react"
import VisibilitySensor from "react-visibility-sensor"
import NextIcon from "./images/next.svg"
import PreviousIcon from "./images/previous.svg"
import {
  Container,
  Indicators,
  IndicatorDot,
  IndicatorWrapper,
  NavigateButton,
} from "./styled"

interface SliderProps {
  children: ReactNode
  hideIndicators?: boolean
  showNavigation?: boolean
}

export const Slider = ({
  children,
  hideIndicators,
  showNavigation = false,
}: SliderProps): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  const setActiveDot = (i: number) => {
    const totalChildren = React.Children.count(children)
    const newIndex = (i + totalChildren) % totalChildren // Wrap around
    setActiveIndex(newIndex)

    // Center the active child
    if (containerRef.current) {
      const activeChild = containerRef.current.children[newIndex]
      if (activeChild) {
        // Calculate the offset to center the active child
        const offset = activeChild.getBoundingClientRect().width * newIndex
        containerRef.current.scrollTo({
          left: offset,
          behavior: "smooth", // Smooth scroll
        })
      }
    }
  }

  return (
    <>
      <Container className="slider-container" ref={containerRef}>
        {React.Children.map(children, (child, i) => (
          <VisibilitySensor
            key={`slider-item-${i}`}
            scrollCheck
            onChange={(isVisible: boolean) => {
              if (isVisible) {
                setActiveIndex(i)
              }
            }}
          >
            {child}
          </VisibilitySensor>
        ))}
      </Container>
      {!hideIndicators && (
        <IndicatorWrapper showNavigation={showNavigation}>
          {showNavigation && (
            <NavigateButton
              src={PreviousIcon}
              onClick={() => setActiveDot(activeIndex - 1)}
            />
          )}
          <Indicators>
            {React.Children.map(children, (child, index) => (
              <IndicatorDot
                data-testid={
                  index === activeIndex
                    ? `indicator-active-${index}`
                    : `indicator-deactive-${index}`
                }
                isActive={index === activeIndex}
                key={`indicator-${index}`}
              />
            ))}
          </Indicators>
          {showNavigation && (
            <NavigateButton
              src={NextIcon}
              onClick={() => setActiveDot(activeIndex + 1)}
            />
          )}
        </IndicatorWrapper>
      )}
    </>
  )
}
