import { useEffect } from "react"

export const useIntersectionObserver = (
  elementRef: React.RefObject<HTMLElement>,
  callback: IntersectionObserverCallback,
  threshold = 0.5
) => {
  useEffect(() => {
    if (!elementRef.current) return
    const observer = new IntersectionObserver(callback, { threshold })
    observer.observe(elementRef.current)
    return () => observer.disconnect()
  }, [elementRef, callback, threshold])
}
