export const paragraphs = [
  {
    key: "paragraph-1",
    text: "Looking after your mental health allows you to show up as your best self in both your personal and professional life. But seeing an expert hasn’t always been easy, so we make it simple.",
  },
  {
    key: "paragraph-2",
    text: "Cerebral gives you easy access to high-quality mental health care—all from the convenience and privacy of your home.",
  },
]
