import styled from "styled-components"
import { colors } from "../../../themes/colors"
import { DropdownWrapper } from "../../CustomDropdown/styled"

export const FormSubmitButton = styled.button`
  font-family: "Poppins";
  height: 56px;
  padding: 20px 24px;
  background: ${colors.c_primary[600]};
  color: var(--bn-w-white-900, #fff);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  min-width: 191px;

  @media (max-width: 768px) {
    max-width: 480px;
    width: 100%;
    border-radius: 0 0 8px 8px;
  }
`

export const FormWrapper = styled.form<{
  direction: "horizontal" | "vertical"
}>`
  display: grid;
  margin-bottom: ${props => (props.direction === "horizontal" ? "40px" : "0")};
  grid-template-columns: ${props =>
    props.direction === "horizontal" ? "225px 1fr 191px" : "1fr"};
  justify-items: center;
  width: 100%;
  max-width: 800px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  ${DropdownWrapper} {
    background-color: ${colors.c_white[900]};
    border-radius: 0;
    border-right-width: 0;

    input {
      font-family: "Poppins";
    }

    input::placeholder {
      font-family: "Poppins";
    }

    @media (max-width: 768px) {
      border-right-width: 1px;
      border-bottom-width: 0;
    }
  }

  & ${DropdownWrapper}:first-child {
    border-radius: ${props =>
      props.direction === "horizontal" ? "8px 0 0 8px" : "8px 8px 0 0"};

    @media (max-width: 768px) {
      border-radius: 8px 8px 0 0;
    }
  }

  & > div:first-child {
    border-radius: ${props =>
      props.direction === "horizontal" ? "" : "8px 8px 0 0"};
    border-right: ${props =>
      props.direction === "horizontal" ? "" : "1px solid"};
    border-bottom: ${props => (props.direction === "horizontal" ? "" : "none")};
  }

  & ${FormSubmitButton} {
    border-top-right-radius: ${props =>
      props.direction === "horizontal" ? "" : "0"};
    border-bottom-left-radius: ${props =>
      props.direction === "horizontal" ? "" : "8px"};

    @media (min-width: 768px) {
      width: ${props => (props.direction === "horizontal" ? "" : "100%")};
    }
  }
`
