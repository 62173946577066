import { graphql } from "gatsby"

import "../common/colors.css"
import Layout from "../components/Layout"
import { AboutPage } from "../components/AboutPage"
import { AboutPageProps } from "../components/AboutPage/types"

interface PageProps {
  data: {
    prismicHealthcareExperts: {
      data: AboutPageProps
    }
  }
}

export default function DynamicPage({ data }: PageProps): JSX.Element {
  return (
    <Layout>
      <AboutPage data={data?.prismicHealthcareExperts?.data} />
    </Layout>
  )
}

export const query = graphql`
  query HealthcareExpertsQuery {
    prismicHealthcareExperts {
      id
      data {
        healthcare_experts {
          bio
          headshot {
            gatsbyImageData(layout: FIXED)
          }
          name {
            text
          }
          role {
            text
          }
        }
      }
    }
  }
`
