import { PreSignupPage } from "../components/PreSignupPage"
import {
  IPreSignupPageContext,
  IPreSignupCopy,
  IPreSignupSEO,
} from "../components/PreSignupPage/types"
import Head from "../components/Head"

const Presignup = (props: { pageContext: IPreSignupPageContext }) => {
  const { pageContext } = props
  const seo: IPreSignupSEO = {
    title: pageContext.title,
    description: pageContext.description,
    url: pageContext.url,
  }
  const copy: IPreSignupCopy = {
    header: pageContext.header,
    subheader: pageContext.subheader,
    weeklyPrice: pageContext.weekly_price,
    monthlyPrice: pageContext.monthly_price,
    priceDescription: pageContext.price_description,
    firstBlockHeader: pageContext.first_block_header,
    firstBlockBody: pageContext.first_block_body,
    firstBlockIcon: pageContext.first_block_icon?.url,
    secondBlockHeader: pageContext.second_block_header,
    secondBlockBody: pageContext.second_block_body,
    secondBlockIcon: pageContext.second_block_icon?.url,
    thirdBlockHeader: pageContext.third_block_header,
    thirdBlockBody: pageContext.third_block_body,
    thirdBlockIcon: pageContext.third_block_icon?.url,
    footer: pageContext.footer,
    pageName: pageContext.pageName,
  }
  return (
    <>
      <Head />
      <PreSignupPage seo={seo} copy={copy} />
    </>
  )
}

export default Presignup
