import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const Header = styled.h3`
  position: absolute;
  top: 80px;
  color: ${colors.c_black[900]};
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;

  padding: 8px;
  border-radius: 8px;
  background-color: ${colors.c_primary[200]};

  @media (max-width: 834px) {
    margin-top: 27px;
    position: relative;
    font-size: 20px;
    top: 0px;
    margin-bottom: 0;
  }
`
export const StepsClone = styled.div`
  position: relative;
  display: flex;
  margin-top: 0px;
  flex-direction: column;
  justify-content: center;
`

export const StepBlock = styled.div<{ index: number }>`
  position: relative;
  display: flex;
  min-height: 69vh;
  margin-top: -1px;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center;
  flex-direction: row;
  ${props => props.index % 2 === 1 && "flex-direction: row-reverse;"}

  @media screen and (max-width: 834px) {
    min-height: 78vh;
    flex-direction: column;
    justify-content: center;
  }
`

export const ImageBlock = styled.div<{ index: number }>`
  display: flex;
  width: 40%;
  justify-content: ${props => (props.index % 2 === 0 ? "end" : "start")};
  align-items: center;
  align-self: center;
  opacity: 0;
  transition: all 0.25s ease 0.25s;

  @media screen and (max-width: 834px) {
    justify-content: center;
    position: relative;
    z-index: 3;
    width: 70%;
    padding-top: 20px;
    order: 3;
  }
`

export const StepImage = styled.img`
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
`

export const ContentWrapper = styled.div`
  display: flex;
  width: 40%;
  justify-content: flex-end;
  opacity: 0;
  transition: all 0.25s ease 0.25s;

  @media screen and (max-width: 834px) {
    width: 91%;
    height: 100%;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
`

export const StepContentBlock = styled.div`
  position: relative;
  align-self: center;
  text-align: left;

  @media screen and (max-width: 834px) {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    order: -1;
  }
`
export const Step = styled.div`
  background-color: rgb(255, 255, 255);
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;

  @media screen and (max-width: 834px) {
    padding: 8%;
    justify-content: center;
  }
`
export const TextBlock = styled.div`
  @media screen and (max-width: 834px) {
    text-align: center;
  }
`

export const BlockHeading = styled.h2`
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  margin-top: 16px;

  @media screen and (max-width: 834px) {
    font-size: 28px;
    line-height: 35px;
    text-align: center;
  }
`

export const ScrollWrap = styled.div`
  position: absolute;
  bottom: 50%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  z-index: 0;
`

export const ScrollAnimation = styled.div`
  transform: scaleY(0);
  transition: transform 0.25s ease;
  transform-style: preserve-3d;
  position: absolute;
  bottom: 0px;
  width: 4px;
  height: 100%;
  background-color: ${colors.c_secondary[600]};
  transform-origin: 50% 0%;
`

export const ScrollBase = styled.div`
  width: 4px;
  height: 100%;
  background-color: #eeeff4;
  transform-origin: 50% 0%;
`

export const Dot = styled.div<{ delay: boolean }>`
  background-color: rgb(238, 239, 244);
  width: 12px;
  height: 12px;

  position: relative;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-bottom: -6px;
  border-radius: 50%;
  transition: all 0.25s ease ${({ delay }) => (delay ? "0.25s" : "")};
`

export const DotWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  @media screen and (max-width: 834px) {
    position: absolute;
    bottom: 50%;
    z-index: 99;
    margin-bottom: 0px;
    align-self: center;
    order: 2;
  }
`
