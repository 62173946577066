import { Button } from "../../../ui"
import { ButtonWrapper, CtaWrapper } from "./styled"

interface ICtaSEOCopySectionProps {
  html?: string
  leftCta: {
    link?: string
    text?: string
  }
  rightCta: {
    link?: string
    text?: string
  }
}

export const CtaSEOCopySection = ({
  html,
  leftCta,
  rightCta,
}: ICtaSEOCopySectionProps) => {
  if (!html) return null
  return (
    <CtaWrapper>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <ButtonWrapper>
        {leftCta.link && leftCta.text && (
          <Button to={leftCta.link} text={leftCta.text} theme="secondary" />
        )}
        {rightCta.link && rightCta.text && (
          <Button to={rightCta.link} text={rightCta.text} theme="primary" />
        )}
      </ButtonWrapper>
    </CtaWrapper>
  )
}
