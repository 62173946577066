/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import ReactDOM from "react-dom/client"
import { datadogRum } from "@datadog/browser-rum"
const env =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"

export const onClientEntry = () => {
  if (env === "localhost") return null
  datadogRum.init({
    applicationId: "e0a528fb-7c28-4a05-8905-536470a2d38e",
    clientToken: "pub3025b63db2de59e300720083e00ff465",
    site: "datadoghq.com",
    service: "Homepage",
    env: env,
    version: process.env.REACT_APP_GIT_SHA || "missing-git-sha",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingOrigins: [
      /https:\/\/cerebral\.com/,
      /https:\/\/.*\.cerebral\.com/,
      /https:\/\/.*\.getcerebral\.com/,
      /https:\/\/.*\.cerebral-development\.com/,
    ],
  })
  datadogRum.startSessionReplayRecording()
}

const WrapWithProvider =
  process.env.GATSBY_ACTIVE_ENV === "production"
    ? require("./src/wrap-with-provider-production").default
    : require("./src/wrap-with-provider-development").default

export const wrapRootElement = WrapWithProvider
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
