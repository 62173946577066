export const content = {
  header: "How do medication and therapy work?",
  subText:
    "Therapy and medication can both help treat mental health conditions in different ways. The benefits of both can work together to help manage symptoms and improve overall quality of life.",
  sections: [
    {
      type: "medication",
      text: "Medication works to regulate brain chemistry and can reduce symptoms when taken as directed.",
      text2:
        " Reducing symptoms can help make everyday life easier and therapy more effective in many cases.",
      link: "https://cerebral.com/plans/medication",
    },
    {
      type: "therapy",
      text: "Working with a therapist can help you better understand how your mind works, identify the root cause of your symptoms and behaviors, develop skills to navigate your feelings, and build healthier habits over time.",
      link: "https://cerebral.com/plans/therapy",
    },
  ],
}
