import React, { forwardRef } from "react"
import { ImageBlock, StepImage } from "../styled"

const Image = forwardRef(
  (
    { index, image }: { index: number; image: string },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <ImageBlock index={index} ref={ref}>
      <StepImage src={image} />
    </ImageBlock>
  )
)

export default Image
