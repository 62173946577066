import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  background: linear-gradient(135deg, #fef9ec 0%, #eef8f9 100%);
  padding: 60px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & > h2 {
    font-size: 40px;
    line-height: 125%;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 28px;
      font-weight: 600;
      line-height: 125%; /* 35px */
    }
  }
  & > p {
    max-width: 480px;
    margin-bottom: 48px;
  }

  * {
    font-family: Poppins;
  }
`

export const ApproachesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  justify-content: space-between;
  gap: 32px;
  max-width: 1440px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const ApproachContainer = styled.div`
  max-width: 320px;
  text-align: left;
  background-color: ${colors.c_white[100]};
  padding: 24px;
  border-radius: 16px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  a {
    color: #385ade;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 0px;
    max-width: none;
  }
`
export const ApproachDesc = styled.div`
  margin: 16px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`

export const APA = styled.span`
  text-align: left;
  max-width: 882px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 48px;

  a {
    color: black;
  }
`
