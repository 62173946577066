import React from "react"
import { OtherSpecialtiesType } from "../../types"
import { H2, BodyRegular } from "../../../ui"
import CircleCheck from "./assets/circle-check.svg"
import { Container, SpecialtiesContainer } from "./styled"

export const OtherSpecialties = ({
  specialties,
}: {
  specialties: OtherSpecialtiesType[]
}): JSX.Element => {
  return (
    <Container>
      <H2>Other specialties</H2>
      <BodyRegular>
        Many different experiences in life can affect your mental health — and
        you don’t have to work through them alone.
        <br />
        <br />
        If you feel like you need support, a caring professional can help you
        navigate challenges like these and many others.
      </BodyRegular>
      <SpecialtiesContainer>
        {specialties.map(specialty => (
          <span key={specialty.item.text}>
            <img src={CircleCheck} alt="" /> {specialty.item.text}
          </span>
        ))}
      </SpecialtiesContainer>
    </Container>
  )
}
