import React from "react"
import Layout from "../components/Layout"
import { CareTeamTherapistsPage } from "../components/CareTeamPages/CareTeamTherapistsPage"

export default function DynamicPage(): JSX.Element {
  return (
    <Layout>
      <CareTeamTherapistsPage />
    </Layout>
  )
}
