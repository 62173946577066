import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const BodyBlockWrapper = styled.div`
  display: flex;
  gap: 24px;
  text-align: left;
  align-items: flex-start;
  h3 {
    color: ${colors.c_black[900]};
    /* Body/Large/Semibold */
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  p {
    color: ${colors.c_grey[800]};
    /* Body/Default/Regular */
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }

  @media (max-width: 600px) {
    img {
      display: none;
    }
  }
`
