import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
`
export const CategoriesContainer = styled.div`
  max-width: 888px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 888px) {
    max-width: 658px;
  }
`

export const CategoriesTitle = styled.div`
  max-width: 477px;
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 32px;

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 888px) {
    margin-bottom: 24px;
  }
`
export const CategoryListWrapper = styled.div<{ isAllShown: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  max-height: ${props => (props.isAllShown ? "100%" : "112px")};
  overflow: hidden;
`

export const CategoryButton = styled.button<{ isActivated: boolean }>`
  padding: 16px 20px;
  border-radius: 30px;
  border: 1px solid ${props => (props.isActivated ? "#ffffff" : "#51459e")};
  background: #fff;
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  width: 212px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${props => (props.isActivated ? "#CBD8FF" : "#ffffff")};
  outline: none;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  @media (max-width: 720px) {
    width: calc(50vw - 30px);
  }
`

export const ShowMoreButton = styled.button`
  border: none;
  color: #51459e;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  background: none;
  cursor: pointer;
  margin-top: 24px;
`

export const BlogRepoHeaderWrapper = styled.div`
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
`

export const CerebralBlogBody = styled.div`
  width: 992px;
  margin: 80px auto;
  @media (max-width: 996px) {
    width: 100%;
    padding: 32px 24px 16px 24px;
    margin: 0 auto;
  }
`

export const Header = styled.div`
  width: 100%;
`

export const HeaderTitle = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 16px;
`

export const HeaderSubscript = styled.div`
  color: #1c1e21;
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const Posts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 48px auto 0 auto;
  width: 100%;
  gap: 90px;
  box-sizing: border-box;
  @media (max-width: 996px) {
    margin: 32px auto 0 auto;
    padding: 0;
    gap: 24px;
    grid-template-columns: 1fr;
  }
`

export const LeftPost = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RightPosts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const PostPreviewImage = styled(GatsbyImage)`
  object-fit: cover;
  border-radius: 16px;
  height: 255px;
`

export const PostTitle = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
`

export const DateCategoriesWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin: 12px 0 12px 0;
  flex-wrap: wrap;
  width: 100%;
`
export const Date = styled.div`
  color: #626874;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  align-items: left;
`

export const Category = styled.div`
  display: flex;
  padding: 2px 8px 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 12px;
  background: #e5ebff;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  color: #12106b;
`
export const RightPost = styled.a`
  text-decoration: none;
  width: 100%;
  padding: 16px;
  background: #fff;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 76px 1fr;
  width: 100%;
  @media (max-width: 996px) {
    margin-bottom: 16px;
  }
`

export const RightPostPreviewImage = styled(GatsbyImage)`
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
`
export const RightPostTitle = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const RightPostContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const RightDateCategoriesWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
`

export const BlogListingWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px 32px;
  width: 1216px;
  padding-bottom: 64px;

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0px 24px 32px 24px;
    gap: 32px;
  }

  @media (max-width: 996px) {
    grid-template-columns: 1fr;
  }
`
