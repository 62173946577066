import { useContext, useEffect, useRef, useState } from "react"
import { KetchContext } from "../contexts/KetchContext"
import { useLocation } from "@reach/router"

export const SegmentPageTracker = () => {
  // Avoid sending duplicate request
  const hasSentPageView = useRef(false)
  const [currentPath, setCurrentPath] = useState<string>()

  const { purposes } = useContext(KetchContext)
  const isKetchAdvertisingAnalyticsAccepted = purposes.advertising_analytics
  const { pathname } = useLocation()

  // Make sure segment is available to send page view event
  const isAnalyticsAvailable = () => {
    return typeof window !== "undefined" && window.analytics
  }

  useEffect(() => {
    if (pathname !== currentPath) {
      setCurrentPath(pathname)
      hasSentPageView.current = false
    }
  }, [pathname])

  useEffect(() => {
    if (currentPath && isAnalyticsAvailable() && !hasSentPageView.current) {
      window.analytics?.page(
        undefined,
        undefined,
        { isKetchAdvertisingAnalyticsAccepted, path: pathname },
        {
          integrations: {
            All: false,
            Heap: true,
            "Google Ads (Classic)": true,
            "Bing Ads": true,
            Datadog: true,
            Iterable: true,
            Looker: true,
          },
        } // we should explicitly set the integrations we want to use
      )
      hasSentPageView.current = true
    }
  }, [currentPath, isAnalyticsAvailable()])

  return null
}
