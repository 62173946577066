import React from "react"
import styled, { keyframes } from "styled-components"
import { colors } from "../themes/colors"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div``

const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 1s linear infinite;
  padding: 2rem 1rem;
  font-size: 1.2rem;
`

const Icon = styled.div`
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid ${colors.c_grey[200]};
  border-right-color: ${colors.c_primary[600]};
  border-radius: 50%;
`

export default styled(function Spinner({ className }) {
  return (
    <Wrapper className={className}>
      <Rotate>
        <Icon />
      </Rotate>
    </Wrapper>
  )
})``
