import React, { createContext, useContext, useState } from "react"
import { createApiInstance } from "@cerebral-inc/api"

interface FlagData {
  [key: string]: boolean
}

interface RolloutFlagProps {
  loadFlag(flag: string, populateOnError?: boolean): Promise<void>
  flag: FlagData
}

export const RolloutFlagContext = createContext<RolloutFlagProps>({
  loadFlag: () => new Promise<void>(resolve => resolve()),
  flag: {},
})

export const RolloutFlagProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const api = createApiInstance({
    baseURL: process.env.GATSBY_API_SERVER_URL,
  })
  const [flag, setFlag] = useState<FlagData>({})

  const loadFlag = (name: string, populateOnError: boolean = true) =>
    api
      .get<FlagData>("/api/v1/feature_flags/find_by", {
        params: {
          flag: name,
        },
      })
      .then(res => setFlag(prevFlags => ({ ...prevFlags, ...res.data })))
      .catch(() => {
        if (populateOnError) {
          setFlag(prevFlags => ({ ...prevFlags, [name]: false }))
        }
      })

  return (
    <RolloutFlagContext.Provider
      value={{
        loadFlag,
        flag,
      }}
    >
      {children}
    </RolloutFlagContext.Provider>
  )
}

export const useRolloutFlag = (): RolloutFlagProps =>
  useContext(RolloutFlagContext)
