import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const AppImagesWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 38px 0 0;
  background: #ecebff;
  color: ${colors.c_black[50]};
  z-index: 0;
  position: relative;
  align-items: center;

  @media (max-width: 834px) {
    padding: 40px 24px 24px;
  }
`

export const DesktopContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 120px;
  @media (max-width: 834px) {
    display: none;
    gap: 32px;
  }
`
export const MobileContainer = styled.div`
  display: none;
  width: 100%;
  @media (max-width: 834px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
export const Image = styled.img`
  width: 416px;

  @media (max-width: 1000px) {
    width: 360px;
  }

  @media (max-width: 834px) {
    width: 100%;
    margin-bottom: 40px;
  }
`

export const AppCtasWrapper = styled.div`
  display: flex;
  margin-top: 104px;
  gap: 24px;

  @media (max-width: 834px) {
    margin-top: 32px;
  }
`

export const Header = styled.h2`
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  color: ${colors.c_black[900]};
  margin-bottom: 32px;

  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 24px;
  }
`

export const HeaderContainer = styled.div``

export const RightSide = styled.div`
  max-width: 327px;

  max-width: 327px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Background = styled.img`
  position: absolute;
  bottom: -1px;
  z-index: -1;
  width: 100%;
  object-fit: cover;
  height: 100%;
`

export const OnTheWayWrapper = styled.img`
  margin-bottom: -11px;

  @media (max-width: 834px) {
    margin-bottom: -15px;
    height: 50px;
    width: 159px;
  }
`

export const ButtonWrapper = styled.div`
  @media (max-width: 834px) {
    width: 100%;
  }
`
