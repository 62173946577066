import { Form, Formik } from "formik"
import { zipCodeToState } from "../../../../common/utils"
import { useWindowSize } from "../../../hooks"
import segment from "../../../../lib/segment"
import { usePageContext } from "../PageContext"
import { TextField } from "../TextField"
import {
  ActionContainer,
  Button,
  Container,
  FormContainer,
  H3,
  Span,
} from "../styled"
import { schema } from "./schema"

interface IWaitlistValues {
  name: string
  email: string
  phone?: string
  zipCode?: string
}

const JoinWaitlistForm = () => {
  const { setCurrentPage, props } = usePageContext()
  const { isMobile } = useWindowSize()

  const onSubmit = ({ name, email, phone, zipCode }: IWaitlistValues) => {
    const segmentData = {
      email,
      name,
      stateCode:
        props?.stateCode || (zipCode && zipCodeToState(Number(zipCode))) || "",
      phone: phone || "",
    }
    segment.addUserToCouplesTherapyWaitlist(segmentData)
    setCurrentPage("joined")
  }

  return (
    <Formik
      initialValues={{
        name: "",
        zipCode: "",
        email: "",
        phone: undefined as string | undefined,
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isValid, handleSubmit }) => (
        <Container>
          <Form
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              overflow: isMobile ? "hidden" : "visible",
            }}
          >
            <FormContainer>
              <H3>
                Sorry, we don’t have therapists available in your state yet
              </H3>
              <Span>
                Thanks for your interest in Couples Therapy at Cerebral. We’ll
                text or email you as soon as we have therapists available in
                your state.
              </Span>

              <TextField label="Name" name="name" placeholder="Jane Smith" />
              <TextField
                label="Email"
                name="email"
                placeholder="janesmith@gmail.com"
              />
              <TextField
                label="Phone Number (optional)"
                name="phone"
                placeholder="888-888-8888"
              />
            </FormContainer>

            <ActionContainer>
              <div>
                <Button
                  to=""
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
                >
                  Let me know
                </Button>
              </div>
            </ActionContainer>
          </Form>
        </Container>
      )}
    </Formik>
  )
}

export default JoinWaitlistForm
