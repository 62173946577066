import { graphql } from "gatsby"
import { IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image"
import ImageWithListTextRight from "./ImageWithListTextRight"

interface ImageWithListTextRightProps {
  slice: {
    primary: {
      slice_type: string
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      copy: {
        text: string
      }
      bullets: {
        html: string
      }
      subscript: {
        html: string
      }
      subscript_icon: ImageDataLike
      image: ImageDataLike
      mobile_image: ImageDataLike
      object_fit: {
        text: string
      }
      list_icon_size?: number
      wrapper_padding?: string
      background?: string
      main_image_height?: string
      container_max_width?: string
      padding_container_list?: string
      reversed: boolean
      cta_button: string
    }
    items: {
      copy: {
        html: string
      }
      icon: IGatsbyImageData
    }[]
  }
}

const ImageWithListTextLeft = ({ slice }: ImageWithListTextRightProps) => {
  return (
    <ImageWithListTextRight reversed={slice.primary.reversed} slice={slice} />
  )
}

export const query = graphql`
  fragment ImageWithListTextLeftSlice on PrismicLandingDataBodyImageWithListTextLeft {
    id
    slice_type
    primary {
      copy {
        text
      }
      bullets {
        html
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      subscript {
        html
      }
      subscript_icon {
        gatsbyImageData(placeholder: BLURRED)
      }
      mobile_image {
        gatsbyImageData(placeholder: BLURRED)
      }
      object_fit {
        text
      }
    }
  }

  fragment PageImageWithListTextLeftSlice on PrismicPageDataBodyImageWithListTextLeft {
    id
    slice_type
    primary {
      copy {
        text
      }
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: BLURRED)
      }
      mobile_image {
        gatsbyImageData(placeholder: BLURRED)
      }
      object_fit {
        text
      }
      list_icon_size
      wrapper_padding
      main_image_height
      background
      reversed
      cta_button
      container_max_width
      padding_container_list
    }
    items {
      icon {
        gatsbyImageData
      }
      copy {
        html
      }
    }
  }
`
export default ImageWithListTextLeft
