import styled from "styled-components"
import { colors } from "../themes/colors"

export const ConditionsLPWrapper = styled.main`
  background: ${colors.c_white[500]};
`

export const ScrollToTopButton = styled.button<{ show: boolean }>`
  position: fixed;
  bottom: 16px;
  right: 32px;
  height: 56px;
  width: 56px;
  background-color: ${colors.c_white[100]};
  border: 1px solid ${colors.c_primary[600]};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? "all" : "none")};
  z-index: 999;
  transition: opacity 0.3s ease-in-out;
`
