import React from "react"
import Container from "../common/Container"
import ItalicHeading from "../components/ui/ItalicHeading"
import PolicyText from "../common/PolicyText"
import DynamicNavigation from "../components/ui/DynamicNavigation"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import { convertPrismicToAbleJumpLink, toPage } from "../lib/utils"
import { graphql } from "gatsby"

const Wrapper = styled.div`
  padding: 40px 0;
`

export default function PrivacyPolicyPage({ data }) {
  const page = toPage(data)
  const newContent = convertPrismicToAbleJumpLink(page.contents)

  return (
    <Layout>
      <SEO
        title="Cerebral Terms &amp; Conditions"
        titleSuffix="What we require from our users to provide you with the medication and treatment you need"
        description="Cerebral's Terms and Conditions. Learn what we require from our community to provide them with prescriptions and treatment"
      />
      <DynamicNavigation />
      <Wrapper>
        <Container>
          <ItalicHeading>{page.title}</ItalicHeading>
          <PolicyText dangerouslySetInnerHTML={{ __html: newContent }} />
        </Container>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query TermsAndConditionsPageQuery($lang: String) {
    page: allPrismicTermsandconditions(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            contents {
              html
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
