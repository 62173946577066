import SocialSharing from "./SocialSharing"
import {
  AuthorAndReviewerLeftWrapper,
  AuthorAndReviewerWrapper,
  AuthorHyperlink,
  AuthorItemWrapper,
  CreatedDate,
  DefaultImageCircle,
  HyperLinkWrapper,
  ImageCircle,
  TextAuthorWrapper,
} from "./styled"
import { formatDate } from "./utils"
import authorImgPlaceholder from "../../../images/Cerebral-Light.png"
import reviewerImgPlaceholder from "../../../images/Cerebral.png"
import { IGatsbyImageData, getImage } from "gatsby-plugin-image"

interface AuthorAndReviewerProps {
  authorName?: string
  authorImage?: IGatsbyImageData
  authorLink?: string
  reviewerName?: string
  reviewerImage?: IGatsbyImageData
  reviewerLink?: string
  date: Date
}

const AuthorAndReviewer = ({
  authorName,
  authorImage,
  authorLink,
  reviewerName,
  reviewerImage,
  reviewerLink,
  date,
}: AuthorAndReviewerProps) => {
  const authorImageData = getImage(authorImage as IGatsbyImageData)
  const reviewerImageData = getImage(reviewerImage as IGatsbyImageData)

  const convertName = (name?: string) => {
    return name?.split(",")[0]
  }

  return (
    <AuthorAndReviewerWrapper>
      <AuthorAndReviewerLeftWrapper>
        <AuthorItemWrapper>
          {authorImageData ? (
            <ImageCircle image={authorImageData} alt="author" />
          ) : (
            <DefaultImageCircle
              src={authorImgPlaceholder}
              alt="default-author"
            />
          )}
          <TextAuthorWrapper>
            {authorLink ? (
              <HyperLinkWrapper>
                Written by&nbsp;
                <AuthorHyperlink href={authorLink} hasLink>
                  {`${convertName(authorName)} `}
                </AuthorHyperlink>
              </HyperLinkWrapper>
            ) : (
              <AuthorHyperlink style={{ textDecoration: "none" }}>
                {authorName}
              </AuthorHyperlink>
            )}
            <CreatedDate>{` ${formatDate(date)}`}</CreatedDate>
          </TextAuthorWrapper>
        </AuthorItemWrapper>

        {reviewerImageData && (
          <AuthorItemWrapper>
            {reviewerImage ? (
              <ImageCircle image={reviewerImageData} alt="reviewer" />
            ) : (
              <DefaultImageCircle
                src={reviewerImgPlaceholder}
                alt="default-reviewer"
              />
            )}
            <HyperLinkWrapper>
              Medically Reviewed by&nbsp;
              {reviewerLink ? (
                <AuthorHyperlink href={reviewerLink} hasLink>
                  {`${reviewerName} `}
                </AuthorHyperlink>
              ) : (
                <AuthorHyperlink>{reviewerName}</AuthorHyperlink>
              )}
            </HyperLinkWrapper>
          </AuthorItemWrapper>
        )}
      </AuthorAndReviewerLeftWrapper>
      <SocialSharing />
    </AuthorAndReviewerWrapper>
  )
}

export default AuthorAndReviewer
