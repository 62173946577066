import capitalize from "lodash/capitalize"

export const createPageDescription = ({
  totalCarePersons,
  city,
  state,
  specialty,
  carePersonType,
}: {
  totalCarePersons: number
  city?: string
  state?: string
  specialty?: string
  carePersonType?: "therapist" | "prescriber"
}) => {
  if (!totalCarePersons)
    return `Sorry, we don't have any ${carePersonType}s in ${
      city ? `${city}, ${state}` : state
    }  at this time.`
  if (totalCarePersons === 1)
    return `There is 1 Cerebral ${carePersonType} ${
      specialty ? `who specializes in ${specialty}` : ""
    } serving ${city ? `${city}, ${state}` : state}`
  else
    return `There are ${totalCarePersons} Cerebral ${carePersonType}s ${
      specialty ? `who specialize in ${specialty} ` : ""
    }${state ? `serving ${city ? `${city}, ${state}` : state}` : ""}`
}

export const createPageTitle = ({
  city,
  state,
  specialty,
  carePersonType,
}: {
  city?: string
  state?: string
  specialty?: string
  carePersonType?: "therapist" | "prescriber"
}) => {
  const formattedRole =
    carePersonType === "prescriber" ? "Mental Health Prescribers" : "Therapists"
  if (!specialty && city && state) {
    return `Browse ${formattedRole} available in ${city}, ${state}`
  }
  if (specialty && !city && !state) {
    return `Browse ${formattedRole} Specializing in ${specialty}`
  }
  if (!specialty && !city && state) {
    return `Browse ${formattedRole} available in ${state}`
  }
  if (specialty && !city && state) {
    return `Browse ${formattedRole} Specializing in ${specialty} in ${state}`
  }

  return `Browse ${capitalize(
    carePersonType
  )}s Specializing in ${specialty} in ${city}, ${state}`
}
