import { Helmet } from "react-helmet-async"
import Layout from "../components/Layout"
import { FriendReferralPageV2 } from "../components/FriendReferralV2"

export default function FriendReferralV2() {
  return (
    <Layout>
      <Helmet>
        <script async type="text/javascript">{`
          (function(a,b,c,d,e,f,g){e['ire_o']=c;e[c]=e[c]||function(){(e[c].a=e[c].a||[]).push(arguments)};f=d.createElement(b);g=d.getElementsByTagName(b)[0];f.defer=1;f.src=a;g.parentNode.insertBefore(f,g);})('https://utt.impactcdn.com/A2435171-ef85-4e75-a435-a531a42fbce31.js','script','ire',document,window);
        `}</script>
      </Helmet>
      <FriendReferralPageV2 />
    </Layout>
  )
}
