import SEO from "../SEO"
import { IPreSignupCopy, IPreSignupPageProps } from "./types"
import { Wrapper } from "./components/Wrapper"
import SmallLogo from "./assets/small-logo.svg"
import {
  BodyText,
  PriceDescription,
  PriceText,
  EmphasizedTextPrice,
  ContinueButton,
  HeaderContainer,
  HeaderText,
  FooterText,
} from "./styled"
import { BodyBlock } from "./components/BodyBlock"
import { useSignupUrl } from "../../hooks/useSignupUrl"

export const PreSignupPageBody = ({ copy }: { copy: IPreSignupCopy }) => {
  const signupUrl = useSignupUrl()
  return (
    <Wrapper>
      <HeaderContainer>
        <img src={SmallLogo} alt="Cerebral Logo" />
        <HeaderText>{copy.header}</HeaderText>
        <BodyText>{copy.subheader}</BodyText>
      </HeaderContainer>
      <div>
        <PriceText>
          <EmphasizedTextPrice>{copy.weeklyPrice}</EmphasizedTextPrice> billed
          as <EmphasizedTextPrice>{copy.monthlyPrice}</EmphasizedTextPrice>
        </PriceText>
        <PriceDescription>{copy.priceDescription}</PriceDescription>
      </div>
      <BodyBlock
        header={copy.firstBlockHeader}
        description={copy.firstBlockBody}
        iconURL={copy.firstBlockIcon}
      />
      <BodyBlock
        header={copy.secondBlockHeader}
        description={copy.secondBlockBody}
        iconURL={copy.secondBlockIcon}
      />
      <BodyBlock
        header={copy.thirdBlockHeader}
        description={copy.thirdBlockBody}
        iconURL={copy.thirdBlockIcon}
      />
      <ContinueButton href={signupUrl}>Continue to sign up</ContinueButton>
      <FooterText
        dangerouslySetInnerHTML={{ __html: copy.footer?.html || "" }}
      />
    </Wrapper>
  )
}

export const PreSignupPage = ({ seo, copy }: IPreSignupPageProps) => {
  return (
    <>
      <SEO title={seo.title} description={seo.description} url={seo.url} />
      <PreSignupPageBody copy={copy} />
    </>
  )
}
