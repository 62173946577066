import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import Layout from "../components/Layout"
import { CareTeamPage } from "../components/CareTeamPages/CareTeamPage"
import { NotFoundCareTeamScreen } from "../components/NotFoundCareTeamScreen"

export default function DynamicPage() {
  const location = useLocation()
  const timeout = location.search.includes("showLoading=true") ? 2000 : 0
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, timeout)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      {location.search.includes("showLoading=true") && (
        <NotFoundCareTeamScreen isShow={isLoading} />
      )}
      <Layout isShow={!isLoading}>
        <CareTeamPage />
      </Layout>
    </>
  )
}
