import styled from "styled-components"

const PolicyText = styled.div`
  margin: 60px auto 0;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.43px;
  color: #250044;

  & h2 {
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 40px;
  }

  & h3 {
    font-weight: 600;
    margin-top: 40px;
  }

  & h4 {
    margin-top: 20px;
  }

  & p {
    margin-top: 20px;
  }

  & ul {
    list-style-type: disc;
    padding: 0 0 0 40px;
  }

  & li {
    margin-top: 30px;
  }

  strong {
    font-weight: revert;
  }
`

export default PolicyText
