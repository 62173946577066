export const COUPLE_THERAPY_QUESTIONS = [
  {
    id: 1,
    title:
      "How comfortable are you and your partner with difficult conversations?",
    description: "",
    subscript: "",
    question_type: { name: "question_type" },
    is_optional: false,
    answer_options: [
      {
        id: 1,
        title: "We have them when needed",
        score: 0,
      },
      {
        id: 2,
        title: "I ignore the topic",
        score: 0,
      },
      {
        id: 3,
        title: "My partner ignores the topic",
        score: 0,
      },
      {
        id: 4,
        title: "Varies time to time",
        score: 0,
      },
    ],
  },
  {
    id: 2,
    title: "Do your arguments tend to escalate very quickly?",
    description: "",
    subscript: "",
    question_type: { name: "question_type" },
    is_optional: false,
    answer_options: [
      {
        id: 5,
        title: "Nearly every time",
        score: 0,
      },
      {
        id: 6,
        title: "Occasionally",
        score: 0,
      },
      {
        id: 7,
        title: "More than half the times",
        score: 0,
      },
      {
        id: 8,
        title: "Not at all",
        score: 0,
      },
    ],
  },
  {
    id: 3,
    title: "How is your overall communication with each other? ",
    description: "",
    subscript: "",
    question_type: { name: "question_type" },
    is_optional: false,
    answer_options: [
      {
        id: 9,
        title: "We’re doing well",
        score: 0,
      },
      {
        id: 10,
        title: "Lacking from my end",
        score: 0,
      },
      {
        id: 11,
        title: "We both tend to get annoyed",
        score: 0,
      },
      {
        id: 12,
        title: "Lacking from my partner",
        score: 0,
      },
    ],
  },
]
