import { Splash } from "../../../shared/Splash"
import ManOnPhone from "./assets/man-on-phone-drinking-coffee.webp"

export const WhatOurMembersAreSaying = () => {
  return (
    <Splash
      titleText="What our members are&nbsp;saying"
      subtitleText="Don’t just take our word for it, read real reviews from clients who are just like you."
      image={ManOnPhone}
      isHeroImage
      background="var(--gradient-zen-inverse, linear-gradient(135deg, #eceffe 0%, #fef9ec 100%))"
    />
  )
}
