import React from "react"
import { FC, useCallback, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { Poppins } from "./styles"

const usePortal = (
  Component: FC,
  query: string
): (() => JSX.Element | null) => {
  const [canUseDOM, setCanUseDOM] = useState(false)
  const [isLoadingComplete, setIsLoadingComplete] = useState(false)
  const [containerQuery, setContainerQuery] = useState(query)

  useEffect(() => {
    setCanUseDOM(true)
  }, [])

  const getContainer = useCallback(() => {
    if (!canUseDOM || typeof document === "undefined") {
      return null
    }

    return document.querySelector(containerQuery)
  }, [containerQuery, canUseDOM])

  useEffect(() => {
    if (!canUseDOM) {
      return
    }

    const checkContainer = (attempt = 0) => {
      const container = getContainer()
      if (!container) {
        if (attempt < 10) {
          setTimeout(() => {
            checkContainer(attempt + 1)
          }, 2000)
        }

        return
      }

      if (container.id) {
        // Empty the container and then change the id
        // so no other data can be loaded into it
        // if the container doesn't have an id, the
        // createPortal will just append the elements inside it
        container.innerHTML = ""
        container.id += "-force"
        setContainerQuery(`${containerQuery}-force`)
      }
      setIsLoadingComplete(true)
    }

    checkContainer()
  }, [canUseDOM])

  return useCallback(() => {
    const container = getContainer()
    return container && isLoadingComplete
      ? createPortal(
          <Poppins>
            <Component />
          </Poppins>,
          container
        )
      : null
  }, [isLoadingComplete, typeof document])
}

export default usePortal
