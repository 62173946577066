import { ConditionType } from "./types"

export const mockConditions: ConditionType[] = [
  {
    desc: {
      html: "<p>Attention-deficit/hyperactivity disorder (ADHD) is a behavioral condition that makes focusing on everyday requests and routines challenging.</p><p>People with ADHD typically have trouble getting organized, staying focused, making realistic plans, and thinking before acting. They may be fidgety, noisy, and unable to adapt to changing situations.</p>",
    },
    group: "a",
    medication: true,
    therapy: true,
    title: { text: "Attention-deficit/hyperactivity disorder (ADHD)" },
    learn_more: {
      url: "https://cerebral.com/blog/obtaining-and-navigating-an-adhd-diagnosis",
    },
    test_link: { url: "https://cerebral.com/online/online-adhd-self-test/" },
    blog_tag: "ADHD",
    learn_more_condition_title: "ADHD",
  },
  {
    desc: {
      html: "<p>Addiction is a chronic condition characterized by psychological or physical dependence on substances (like drugs or alcohol) or certain activities or behaviors (like sex, exercise, or gambling). It involves compulsively seeking or engaging in these things despite any negative or harmful consequences.</p><p>Some substances are more likely to cause addiction than others, including prescription and non-prescription drugs, illegal drugs, alcohol, nicotine, vaping, inhalants, and hallucinogens. Use of these substances can lead to a substance abuse disorder, but not always. Addiction is the most severe form of a substance abuse disorder and can be managed with the right treatment.</p><p>Addiction is characterized by an inability to stop using a substance or engaging in an activity, increased tolerance, intense focus on the substance or activity, lack of control, health or relationship problems, and withdrawal when not using.</p>",
    },
    group: "a",
    medication: true,
    therapy: true,
    title: { text: "Addiction and substance use disorder (SUD)" },
    learn_more: {
      url: "https://cerebral.com/blog/cerebral-substance-use-disorder-launch",
    },
    blog_tag: "Substance Use Disorder",
    learn_more_condition_title: "SUD",
  },
  {
    desc: {
      html: "<p>Occasional worry about things like health, money, or family problems is very common and a normal part of life.</p><p>For people with an anxiety disorder, anxiety does not go away and can get worse over time. Symptoms of anxiety like intrusive and repetitive thoughts, sleep problems, feeling irritated or fatigued, and difficulty concentrating can interfere with school, work, and relationships.</p><p>There are several different types of anxiety disorders including generalized anxiety disorder (GAD), panic disorder, social anxiety disorder, and phobias.</p>",
    },
    group: "a",
    medication: true,
    therapy: true,
    title: { text: "Anxiety" },
    learn_more: {
      url: "https://cerebral.com/blog?categories=Anxiety",
    },
    test_link: {
      url: "https://cerebral.com/online/free-online-anxiety-test/",
    },
    blog_tag: "Anxiety",
    learn_more_condition_title: "anxiety",
  },
  {
    desc: {
      html: "<p>Autism spectrum disorder (ASD) is a neurological and developmental disorder that affects how people interact with others, communicate, learn, and behave. Autism is known as a “spectrum” disorder because there is wide variation in the type and severity of symptoms people experience.</p><p>Although autism can be diagnosed at any age, it is described as a “developmental disorder” because symptoms generally appear in the first 2 years of life. Early symptoms can include reduced eye contact, lack of response to their name, suddenly becoming withdrawn or aggressive, or losing language skills they’ve already acquired.</p><p>People of all genders, races, ethnicities, and economic backgrounds can be diagnosed with ASD. ASD can be a lifelong disorder, but treatments and services can improve a person’s symptoms and how they function.</p>",
    },
    group: "a",
    medication: false,
    therapy: true,
    title: { text: "Autism spectrum disorder (ASD)" },
  },
  {
    desc: {
      html: "<p>Body dysmorphic disorder (BDD) is a mental health condition characterized by a preoccupation with a perceived flaw or defect in your appearance that is small or can’t be seen by others.</p><p>Someone with this disorder may feel so ashamed an embarrassed about this flaw that they avoid social situations, repeatedly groom or check mirrors, and seek reassurance from others or procedures to “fix” the perceived flaw.</p>",
    },
    group: "b",
    medication: false,
    therapy: true,
    title: { text: "Body dysmorphic disorder (BDD)" },
  },
  {
    desc: {
      html: "<p>Bipolar disorder, formerly called manic depression, is a mental health condition that causes extreme mood swings with periods of extreme highs (mania or hypomania) quickly followed by extreme lows (depression) or vice versa. These changes can be unpredictable and cause significant distress and challenges in relationships and everyday life.</p><p>A manic episode may include being abnormally upbeat or energetic, agitation, an exaggerated sense of well-being or confidence (euphoria), needing sleep less, racing thoughts, increased talkativeness or distractibility, or poor decision-making. A manic episode may also trigger psychosis and require hospitalization.</p><p>A depressive episode includes symptoms that cause noticeable difficulty in every day life like feelings of sadness or hopelessness, loss of interest or feeling no pleasure at all, changes in appetite or weight, changes in sleeping habits, restlessness, loss of energy, feeling worthless or guilty, suicidal thoughts, and difficulty concentrating or making decisions.</p><p>Bipolar disorder does not improve on it’s own, but treatment from an experienced mental health professional can help manage symptoms.</p>",
    },
    group: "b",
    medication: true,
    therapy: true,
    title: { text: "Bipolar disorder" },
    learn_more: {
      url: "https://cerebral.com/blog?categories=Bipolar+Disorder",
    },
    blog_tag: "Bipolar Disorder",
    learn_more_condition_title: "bipolar disorder",
  },
  {
    desc: {
      html: "<p>Chronic pain is pain that lasts for over three months and can occur anywhere in your body. The pain can be there all the time, or it may come and go.</p><p>Chronic pain can interfere with daily activities at work, with friends or family, or when taking care of yourself or others. It can lead to (or sometimes be caused by) depression, anxiety, and trouble sleeping. These things can make your pain worse and create a cycle that’s difficult to end.</p><p>Common types of chronic pain include headaches or migraines, neck pain, arthritis, cancer pain near a tumor, scar tissue, muscle pain like fibromyalgia, neurogenic pain related to nerve damage, or ongoing pain from a past injury. Multiple forms of treatment may be beneficial, including therapy, lifestyle changes, and some medication.</p>",
    },
    group: "c",
    medication: false,
    therapy: true,
    title: { text: "Chronic pain" },
  },
  {
    desc: {
      html: "<p>Depression is a common but serious disorder that can cause symptoms that affect how a person feels, thinks, and manages daily activities.</p><p>People who experience symptoms like a persistent sad or “empty” mood, anxiety, feelings of hopelessness or worthlessness, loss of interest in activities, fatigue, difficulty concentrating or making decisions, sleep problems, changes in appetite or weight, physical pain, or thoughts of suicide consistently for at least 2 weeks may have depression.</p><p>Other changes in behavior may also include feeling angry or restless, isolating from friends and family, increasing use of drugs or alcohol, inability to meet responsibilities at work or with family or friends, loss of interest in sex or intimacy, engaging in risky behaviors, or acting impulsively.</p><p>Depression can affect people of any age, race, or gender and symptoms may be different for everyone. Treatment may include therapy, medication, or a combination of the two. There is no one-size-fits-all treatment, and finding what works best for you may require some trial and error.</p>",
    },
    group: "d",
    medication: true,
    therapy: true,
    title: { text: "Depression" },
    learn_more: {
      url: "https://cerebral.com/blog?categories=Depression",
    },
    test_link: {
      url: "https://cerebral.com/online/free-online-depression-test",
    },
    blog_tag: "Depression",
    learn_more_condition_title: "depression",
  },
  {
    desc: {
      html: "<p>Eating disorders are serious medical illnesses marked by a severe disturbance in someone’s eating behaviors and/or fixation on body weight, image, shape, or food intake.</p><p>Common eating disorders include anorexia nervosa, bulimia nervosa, binge-eating, and food restriction and avoidance. Behaviors may include severely restricting food, only eating specific foods, forced vomiting, over eating, using laxatives of diuretics, excessive exercise, or a combination of these things. These behaviors are typically associated with a fixation on body image or fear of gaining weight.</p><p>Eating disorders are not a choice and can be life-threatening, but many people can also recover completely with the right treatment.</p>",
    },
    group: "e",
    medication: false,
    therapy: true,
    title: { text: "Eating disorders" },
  },
  {
    desc: {
      html: "<p>Insomnia is a sleep disorder where someone is experiencing difficulty falling or staying asleep. It can be long-term (chronic) or short-term (acute) and may come and go throughout someone’s life.</p><p>Insomnia may exist on its own or be caused by things like stress, noise, light, temperature, a change in routine, jet lag, a family history of insomnia, depression, anxiety, medication, diet, and other health conditions.</p><p>Insomnia can contribute to mental or physical health issues and symptoms include anxiety, grumpiness, trouble focusing, and drowsiness. Changes in routine, diet, and exercise can help prevent and overcome insomnia – as well as some medications or therapy to help you manage other contributing health problems.</p>",
    },
    group: "e",
    medication: false,
    therapy: true,
    title: { text: "Insomnia" },
    learn_more: {
      url: "https://cerebral.com/blog/online-cognitive-behavioral-therapy-insomnia",
    },
    blog_tag: "Insomnia",
    learn_more_condition_title: "insomnia",
  },
  {
    desc: {
      html: '<p>A panic attack is a sudden surge of overwhelming fear that comes without warning and without any obvious reason. It is far more intense than the feeling of being "stressed out" that most people experience. Symptoms of a panic attack include racing heartbeat, difficulty breathing, dizziness, lightheadedness or nausea, trembling, sweating, shaking, choking, chest pains, hot flashes, or sudden chills, tingling in fingers or toes, fear that you\'re going to go crazy or are about to die.</p>',
    },
    group: "p",
    medication: true,
    therapy: true,
    title: { text: "Panic attacks" },
  },
  {
    desc: {
      html: "<p>A personality disorder is a mental health condition where someone has a lifelong pattern of seeing themselves and reacting to others in ways that cause problems. Many people with these disorders have a hard time tolerating distress, understanding emotions, and relating to others. These things can affect social and family life, work, school, and overall quality of life.</p><p>There are several different categories of personality disorders.</p><p>Group A personality disorders have a dysfunctional pattern of thinking or behavior that is marked by suspicion or lack of interest in other people — includes paranoid, schizoid, and schizotypal personality disorders.</p><p>Group B is marked by a pattern of dramatic, overly emotional thinking or emotional behavior and includes borderline, histronic, narccisistic, and antisocial personality disorders.</p><p>Group C is marked by anxious thinking or behavior and includes avoidant, dependent, and obsessive-compulsive personality disorder.</p><p>Many people with symptoms of one type also have symptoms of at least one other type. Treatment may involve a combination of therapy or medication and can take months or years.</p>",
    },
    group: "p",
    medication: true,
    therapy: true,
    title: { text: "Personality disorders" },
  },
  {
    desc: {
      html: "<p>A phobia is a type of anxiety characterized by a persistent, excessive, and unrealistic fear of an object, person, animal, activity, or situation and can range from mild to severe. Phobias may be general or very specific, and includes social anxiety and agoraphobia (fear of being in public places where making a sudden exit may be difficult or embarrassing).</p><p>A person with a phobia is likely to worry excessively about their fear and either avoid the thing that triggers their fear entirely, or endure it with a lot of anxiety and stress. Extreme phobias may dictate important aspects in someone’s life like employment, job location, driving routes, recreational or social activities, or home environment.</p><p>Treatment can involve therapy, medication, or a combination of the two depending on the type of phobia.</p>",
    },
    group: "p",
    medication: true,
    therapy: true,
    title: { text: "Phobias" },
  },
  {
    desc: {
      html: "<p>PTSD may develop in some people after experiencing, witnessing, or learning about a shocking, scary, or dangerous event.</p><p>Symptoms include experiencing flashbacks or dreams about the event, avoiding places or activities that remind them of the event, feeling tense or on edge, being easily startled, difficulty concentrating, easily feeling angry or irritated, aggressive outbursts, trouble sleeping, engaging in risky or destructive behaviors, memory loss, depression, social isolation, feeling guilt or shame about the event, exaggerated feelings of blame towards self or others, having ongoing negative emotions, loss of interest in enjoyable activities, and trouble feeling happy.</p>",
    },
    group: "p",
    medication: true,
    therapy: true,
    title: { text: "Post-traumatic stress disorder (PTSD)" },
    learn_more: {
      url: "https://cerebral.com/blog?categories=PTSD",
    },
    blog_tag: "PTSD",
    learn_more_condition_title: "PTSD",
  },
  {
    desc: {
      html: "<p>Seasonal affective disorder is a type of depression that lasts for a season, typically the winter months, and goes away during the rest of the year. Treatment typically includes therapy, light therapy and medication.</p>",
    },
    group: "s",
    medication: true,
    therapy: true,
    title: { text: "Seasonal affective disorder" },
    learn_more: {
      url: "https://cerebral.com/blog/understanding-seasonal-affective-disorder-sad",
    },
    learn_more_condition_title: "seasonal affective disorder",
  },
  {
    desc: {
      html: "<p>Suicidal ideation refers to thinking about or making plans for suicide. Intensity of thoughts can range from a general desire to creating a detailed plan with determined intent to act on it.</p><p>Suicidal ideation is typically linked to depression and can help indicate the level of suffering someone is experiencing. Only a small percentage of those who think about suicide actually carry through with it, and the right treatment can help manage and alleviate these thoughts.</p>",
    },
    group: "s",
    medication: true,
    therapy: true,
    title: { text: "Suicidal ideation" },
    learn_more: {
      url: "https://cerebral.com/blog/addressing-suicidal-ideation",
    },
    learn_more_condition_title: "suicidal ideation",
  },
]

export const mockSpecialties = [
  { item: { text: "Career difficulties" } },
  { item: { text: "Big life changes" } },
  { item: { text: "Family conflicts" } },
  { item: { text: "Grief and loss" } },
  { item: { text: "Relationship issues" } },
  { item: { text: "Sex trafficking" } },
  { item: { text: "Low self esteem or confidence" } },
  { item: { text: "General stress" } },
  { item: { text: "Trauma and abuse" } },
  { item: { text: "LGBTQIA+" } },
  { item: { text: "Race and identity" } },
]

export const mockApproaches = [
  {
    desc: {
      html: "<p>DBT focuses on self acceptance and understanding how two ideas that seem opposite can be true at the same time. Based on CBT and adapted for those with bipolar disorder, eating disorders, borderline personality disorder, suicidal ideation, and addiction.</p>",
    },
    title: { text: "Dialectical behavior therapy (DBT)" },
    learn_more: {
      url: "https://cerebral.com/care-resources/dbt-distress-tolerance-skills",
    },
    header_icon: {
      url: "https://cerebral.cdn.prismic.io/cerebral/ZrPFeEaF0TcGIwtD_compass.svg",
    },
  },
  {
    desc: {
      html: "<p>CBT helps identify specific challenges in your thought patterns and develop skills to respond to them more effectively. One of the most common and effective forms of talk therapy.</p>",
    },
    title: { text: "Cognitive behavioral therapy (CBT)" },
    learn_more: {
      url: "https://cerebral.com/care-resources/cbt-cognitive-triangle",
    },
    header_icon: {
      url: "https://cerebral.cdn.prismic.io/cerebral/ZrPFekaF0TcGIwtF_key.svg",
    },
  },
  {
    desc: {
      html: "<p>EMDR is trauma-focused CBT that can help reduce the power of traumatic memories. A trained therapist guides you through thinking about a memory while moving your eyes back and forth to help your brain process them so they become less painful.</p>",
    },
    title: { text: "Eye movement desensitization and reprocessing (EMDR)" },
    header_icon: {
      url: "https://cerebral.cdn.prismic.io/cerebral/ZrPFeUaF0TcGIwtE_eye.svg",
    },
  },
]

export const mockPageData = {
  conditions: mockConditions,
  therapeutic_approaches: mockApproaches,
  other_specialties: mockSpecialties,
}

export const conditions = [
  { display: "ADHD", value: "Attention-deficit/hyperactivity disorder (ADHD)" },
  {
    display: "Addiction/SUDs",
    value: "Addiction and substance use disorder (SUD)",
  },
  { display: "Anxiety", value: "Anxiety" },
  { display: "Autism Spectrum", value: "Autism spectrum disorder (ASD)" },
  {
    display: "Body Dysmorphic Disorder",
    value: "Body dysmorphic disorder (BDD)",
  },
  { display: "Bipoloar Disorder", value: "Bipolar disorder" },
  { display: "Chronic Pain", value: "Chronic pain" },
  { display: "Depression", value: "Depression" },
  { display: "Eating Disorders", value: "Eating disorders" },
  { display: "Insomnia", value: "Eating disorders" },
  { display: "Panic Attacks", value: "Panic attacks" },
  { display: "Personality Disorders", value: "Personality disorders" },
  { display: "Phobias", value: "Phobias" },
  { display: "PTSD", value: "Post-traumatic stress disorder (PTSD)" },
  {
    display: "Seasonal Affective Disorder",
    value: "Seasonal affective disorder",
  },
  { display: "Suicidal Ideation", value: "Suicidal ideation" },
]
