import React from "react"
import { createContext, ReactNode, useContext } from "react"

export enum ModalState {
  OPEN,
  CLOSED,
}

export type ModalWidthOptions = "default" | "wide"

export interface ModalOptions {
  onClose?(): void
  displayCloseIcon?: boolean
  modalWidth?: ModalWidthOptions
  fullScreenOnMobile?: boolean
}

export interface ModalContextProps {
  setModalContent(content: ReactNode, options?: ModalOptions): void
  setModalState(state: ModalState): void
  modalState: ModalState
  content: ReactNode
  options?: ModalOptions
}

export const ModalContext = createContext<ModalContextProps>({
  setModalContent() {},
  setModalState() {},
  modalState: ModalState.CLOSED,
  content: null,
  options: {
    displayCloseIcon: true,
  },
})

export const useModalContext = () => useContext(ModalContext)
