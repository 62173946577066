import React from "react"
import {
  MedsVsTherapyContainer,
  Header,
  SubText,
  Section,
  SectionHeader,
  SectionText,
  SectionLink,
  SectionWrapper,
} from "./styled"
import { content } from "./constants"
import { useWindowSize } from "../../../hooks"

const MedTherapySection = ({
  type,
  text,
  text2,
  link,
}: {
  type: string
  text: string
  text2?: string
  link: string
}) => {
  const { isMobile } = useWindowSize()

  let linkName = "Learn more"
  if (!isMobile) {
    linkName = `How to meet with a ${
      type === "medication" ? "prescriber" : "therapist"
    }`
  }

  return (
    <Section>
      <SectionHeader>
        {type === "medication" ? "Medication" : "Therapy"}
      </SectionHeader>
      <SectionText>{text}</SectionText>
      {text2 && <SectionText>{text2}</SectionText>}
      <SectionLink href={link} target="_blank">
        {linkName}
      </SectionLink>
    </Section>
  )
}

export const MedsVsTherapy = () => {
  return (
    <MedsVsTherapyContainer>
      <Header>How do medication and therapy work?</Header>
      <SubText>
        Therapy and medication can both help treat mental health conditions in
        different ways. The benefits of both can work together to help manage
        symptoms and improve overall quality of life.
      </SubText>
      <SectionWrapper>
        {content.sections.map(section => (
          <MedTherapySection key={section.type} {...section} />
        ))}
      </SectionWrapper>
    </MedsVsTherapyContainer>
  )
}
