import React from "react"
import Layout from "../components/Layout"
import { PricingPage } from "../components/PricingPage"

export default function DynamicPage() {
  return (
    <Layout>
      <PricingPage />
    </Layout>
  )
}
