import { useStaticQuery, graphql } from "gatsby"
import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { MedicationPageWrapper } from "./styled"
import HeaderImage from "./images/medication-header-image.webp"
import {
  AlternatingSplashes,
  ChooseService,
  GettingStarted,
  SuccessTestimonials,
  HeroSection,
} from "../shared"
import { alternatingSplashCopy, chooseServicePlans } from "./constants"
import { MedicationsWeOffer } from "./sections/MedicationsWeOffer"
import { EndCap, FAQSection } from "../shared"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { useSignupUrl } from "../../hooks/useSignupUrl"

const MEDICATION_PAGE_QUERY = graphql`
  query MedicationPageQuery {
    allPrismicMedicationsWeOffer {
      edges {
        node {
          data {
            body {
              ... on PrismicMedicationsWeOfferDataBodyMedicationBlock {
                id
                items {
                  heading
                  medications {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicTestimonialSection(filter: { tags: { eq: "medication_quote" } }) {
      edges {
        node {
          data {
            testimonial_section {
              name
              quote
            }
          }
        }
      }
    }
  }
`

export const MedicationPageBody = (): JSX.Element => {
  const medicationPageResponse = useStaticQuery(MEDICATION_PAGE_QUERY)
  const medicationsCopy =
    medicationPageResponse.allPrismicMedicationsWeOffer.edges[0].node.data.body.map(
      (medication: {
        items: { heading: string; medications: { html: string } }[]
      }) => ({
        header: medication.items[0].heading,
        medicationList: medication.items[0].medications.html,
      })
    )
  const quotes =
    medicationPageResponse.allPrismicTestimonialSection.edges[0].node.data
      .testimonial_section

  return (
    <MedicationPageWrapper>
      <HeroSection
        title="Medication"
        description="1-on-1 online psychiatry with a licensed prescriber. Get a professional evaluation — then medication prescription (if prescribed) and personalized treatment plan with lifestyle recommendations, if diagnosed."
        image={HeaderImage}
        buttons={[
          {
            text: "Get started",
            link: useSignupUrl(),
          },
        ]}
      />
      <AlternatingSplashes copy={alternatingSplashCopy} />
      <MedicationsWeOffer medicationsCopy={medicationsCopy} />
      <ChooseService plansInfo={chooseServicePlans} />
      <GettingStarted description="Most clients are able to meet with a clinician within 3 days of signing up" />
      <SuccessTestimonials quotes={quotes} />
      <FAQSection labels={["medication_faq"]} />
      <EndCap title="Start your care journey" isMedsOrTherapy={true} />
    </MedicationPageWrapper>
  )
}

export const MedicationPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="Conditions Cerebral Treats"
        titleSuffix="Expert Online Therapy + Mental Health Care on Your Schedule"
        description="Cerebral experts can treat anxiety, depression, insomnia, and more. Learn about the care you can get today."
        url="conditions-we-treat/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <MedicationPageBody />
    </>
  )
}
