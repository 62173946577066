import stephLogo from "./assets/steph.svg"
import danielLogo from "./assets/daniel.svg"
import meghanLogo from "./assets/meghan.png"

export const therapists = [
  {
    name: "Stephanie Novak",
    logo: stephLogo,
    type: "Therapist",
    rating: "4.8",
    location: "Illinois",
    specialties: ["Anxiety", "Depression", "LGBTQIA", "Trauma & Abuse"],
    desc: "Stephanie is a Licensed Professional Counselor who uses an eclectic and client-centered approach with interventions like Cognitive Behavioral Therapy (CBT) and Solution Focused Therapy. She has experience working with children, teens, adults, and families who need support after experiencing...",
    bio: { url: "/care-team/therapists/stephanie-novak" },
  },
  {
    name: "Daniel G. Perez",
    logo: danielLogo,
    type: "Therapist",
    rating: "4.8",
    location: "California",
    specialties: ["Anxiety", "Depression", "Trauma & Abuse"],
    desc: "Daniel Perez is a Licensed Marriage and Family Therapist and an Associate Professional Clinical Counselor. He works with adolescents, adults, families and couples who are struggling with relationship or interpersonal conflicts. He enjoys working with a variety of populations with an...",
    bio: { url: "/care-team/therapists/daniel-g-perez" },
  },
  {
    name: "Meghan K. Young",
    logo: meghanLogo,
    type: "Therapist",
    rating: "4.9",
    location: "California, Florida",
    specialties: ["Anxiety", "Depression", "Trauma & Abuse"],
    desc: "Meghan Young is a Licensed Marriage and Family Therapist. She received her Masters in Counseling Psychology with an emphasis in Marriage and Family Therapy from Pacifica Graduate Institute in Santa Barbara, CA. Meghan has experience in Cognitive Behavioral Therapy (CBT)...",
    bio: { url: "/therapists/meghan-k-young/625493" },
  },
]
