import { useState } from "react"
import {
  BackDrop,
  Modal,
  ModalContainer,
  MainImage,
  ModalTextContainer,
  Input,
  SubmitButton,
  CloseButton,
  EnvelopeImage,
  InputContainer,
  SubmitButtonText,
  SuccessText,
  GreenCircle,
} from "./styled"
import { HipaaContent, HipaaWrapper } from "../../../shared/Blog/styled"
import CheckBox from "../../../../components/ui/Checkbox"
import WomanOnPhone from "./assets/woman-on-phone.webp"
import { BodyRegular, H3 } from "../../../ui"
import Close from "../../../../images/close.svg"
import Envelope from "./assets/envelope.svg"
import CircleCheck from "./assets/circle-check.svg"
import { isValidEmail } from "./utils"
import segment from "../../../../lib/segment"

export const EmailCollectionPopup = ({
  onClose,
  initialSuccessValue = false,
}: {
  onClose: () => void
  initialSuccessValue?: boolean
}) => {
  const [email, setEmail] = useState("")
  const [showSuccessState, setShowSuccessState] = useState(initialSuccessValue)
  const [isChecked, setIsChecked] = useState(false)

  const onSubmit = () => {
    if (showSuccessState) return
    segment.addUserToIterableNewsletter(email, "", "")
    ;(window.Kameleoon as any).API.Goals.processConversion("Collected Email")
    setShowSuccessState(true)
  }

  return (
    <>
      <BackDrop onClick={onClose} />
      <ModalContainer onClick={onClose}>
        <Modal onClick={e => e.stopPropagation()}>
          <CloseButton onClick={onClose}>
            <img src={Close} alt="Close Dialog" />
          </CloseButton>
          <MainImage src={WomanOnPhone} />
          <ModalTextContainer>
            <H3>Let's stay in touch</H3>
            <BodyRegular>
              Join our mailing list and receive updates on mental health news,
              access to free resources for improving your well-being, and
              invites to exclusive webinars hosted by mental health experts.
            </BodyRegular>
            {showSuccessState ? null : (
              <InputContainer>
                <EnvelopeImage src={Envelope} alt="" />
                <Input
                  placeholder="Enter your e-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </InputContainer>
            )}
            {showSuccessState ? (
              <>
                <SubmitButton onClick={onClose}>
                  <SubmitButtonText>
                    Success! <img src={CircleCheck} />
                  </SubmitButtonText>
                </SubmitButton>
                <SuccessText>
                  {" "}
                  <GreenCircle /> <p>You were added to the mailing list!</p>
                </SuccessText>
              </>
            ) : (
              <>
                <HipaaWrapper
                  style={{ marginTop: "0px", justifyContent: "unset" }}
                >
                  <CheckBox
                    onChange={() => setIsChecked(!isChecked)}
                    checked={isChecked}
                  />
                  <HipaaContent>
                    I accept the{" "}
                    <a
                      href="https://cerebral.com/hipaa-marketing-authorization"
                      target="_blank"
                    >
                      Cerebral HIPAA Marketing Authorization.
                    </a>
                  </HipaaContent>
                </HipaaWrapper>
                <SubmitButton
                  onClick={onSubmit}
                  disabled={!isValidEmail(email) || !isChecked}
                >
                  Submit
                </SubmitButton>
              </>
            )}
          </ModalTextContainer>
        </Modal>
      </ModalContainer>
    </>
  )
}
