import styled from "styled-components"
import { colors } from "../../themes/colors"

export const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 16px;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  & > * {
    flex-shrink: 0;
    scroll-snap-align: center;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`

export const Indicators = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    margin-right: 8px;
  }
  & > div:last-child {
    margin-right: 0;
  }
`

interface IndicatorDotProps {
  isActive: boolean
}
export const IndicatorDot = styled.div<IndicatorDotProps>`
  width: ${({ isActive }) => (isActive ? "16px" : "8px")};
  height: 8px;
  background: ${({ isActive }) =>
    isActive ? colors.c_primary[600] : "#aabaf9"};
  border-radius: 8px;
  display: block;
`

export const IndicatorWrapper = styled.div<{ showNavigation: boolean }>`
  display: flex;
  justify-content: ${({ showNavigation }) =>
    showNavigation ? "space-between" : "center"};
  height: 58px;
  align-items: center;
  margin-top: 24px;
`

export const NavigateButton = styled.img`
  cursor: pointer;
  padding: 13px;
`
