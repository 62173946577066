import styled from "styled-components"
import { colors } from "../../themes/colors"

export const FaqCategoryPageBody = styled.main`
  background: ${colors.c_white[500]};
`
export const CategoryLinksContainer = styled.section`
  padding: 96px 176px;
  padding: 8% 12%;
  @media (max-width: 499px) {
    padding: 88px 16px;
  }
`
