import styled from "styled-components"
import { ScreenerWrapper } from "../styled"
import { CtaButton } from "../../../../../components/prismic/LandingPageStyles"

export const GenericWidgetWrapper = styled(ScreenerWrapper)`
  border-radius: 16px;
  background: #f2f5ff;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  flex-direction: column;
  align-items: center;

  @media (max-width: 992px) {
    padding: 40px;
  }

  @media (max-width: 884px) {
    border-radius: 0;
  }
`

export const Title = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  max-width: 402.538px;
`
export const SubTitle = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 14px;
`
export const PlanWrapper = styled.div<{ numOfColumn: number }>`
  display: ${props => (props.numOfColumn < 4 ? "grid" : "flex")};
  grid-template-columns: repeat(${props => props.numOfColumn}, 1fr);
  gap: 20px;
  margin-top: 30px;

  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: repeat(
      ${props => Math.floor(props.numOfColumn / 2)},
      1fr
    );
  }

  @media (max-width: 600px) {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
  }
`

export const PlanItemWrapper = styled.div<{ active: boolean }>`
  border-radius: 16px;
  border: ${props => (props.active ? "2px solid #a7bcff" : "none")};
  background: #fff;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 12px;
  align-items: center;
  cursor: pointer;
`

export const PlanIcon = styled.img`
  width: 38px !important;
  height: 38px !important;
`

export const PlanName = styled.div`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const Description = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  max-width: 542.237px;
  margin: 30px 0;
`
export const Button = styled(CtaButton)`
  color: #fff !important;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none !important;
  width: 320px;

  @media (max-width: 400px) {
    width: 100%;
  }
`
