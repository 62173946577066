import { TServiceLine } from "../../components/shared/Blog/MiniScreener/types"

export const SERVICE_LINE_PATTERN =
  /MiniWidget:(Anxiety|Depression|Bipolar|CouplesTherapy)/
export const GENERIC_PATTERN = /{MiniWidget:Generic}/
export const COUPLES_THERAPY_PATTERN = /{MiniWidget:GenericCouplesTherapy}/

export const getServiceLine = (data: string) => {
  const serviceLine = `${
    (data as any).match(SERVICE_LINE_PATTERN)[0].split(":")[1]
  }`.toLowerCase() as TServiceLine

  return serviceLine
}
