import { useState, useEffect } from "react"
import { Back, Nav } from "./styled"
import LeftArrow from "./assets/left-arrow.svg"

export const BackButton = () => {
  const [scrolled, setScrolling] = useState<boolean>(false)

  const toggleScrolling = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 5) {
      setScrolling(true)
    } else {
      setScrolling(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleScrolling)
  }, [])
  return (
    <Nav scrolled={scrolled}>
      <Back to={"/faqs"}>
        <img src={LeftArrow} />
        Back
      </Back>
    </Nav>
  )
}
