import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import { LocationPage } from "../../components/LocationPage"
import {
  LocationPageRawData,
  LocationPageSEOData,
} from "../../components/LocationPage/types"

interface LocationPageProps {
  pageContext: {
    locationDetails: LocationPageRawData
    seoCopySection: LocationPageSEOData
  }
}
const Locations = (props: LocationPageProps) => {
  //pass down a single location with data to page component
  const { pageContext } = props
  const { locationDetails, seoCopySection } = pageContext

  return (
    <Layout>
      <LocationPage
        locationDetails={locationDetails}
        seoCopySection={seoCopySection}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          id
          path
          pageContext
        }
      }
    }
  }
`

export default Locations
