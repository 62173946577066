import React, { useState, useEffect } from "react"
import { BodyRegular, H1 } from "../../../ui"
import { SelectLocation } from "../../../shared"
import {
  HeroContainer,
  ContentContainer,
  Wrapper,
  LocationContainer,
  MobileContainer,
  DesktopContainer,
} from "./styled"
import { SmallRatings } from "../../../shared"

interface IHeroProps {
  title: string
  blurb: string
  carePersonType: string
}

export const Hero = ({
  title,
  blurb,
  carePersonType,
}: IHeroProps): JSX.Element => {
  const [stateSelected, setStateSelected] = useState("select your state")
  return (
    <HeroContainer>
      <Wrapper>
        <ContentContainer carePersonType={carePersonType}>
          <H1>{title}</H1>
          <BodyRegular>{blurb}</BodyRegular>
          <DesktopContainer>
            <SmallRatings />
          </DesktopContainer>
        </ContentContainer>
        <LocationContainer>
          <SelectLocation
            stateSelected={stateSelected}
            onChange={setStateSelected}
            carePersonType={carePersonType}
          />
        </LocationContainer>
        <MobileContainer>
          <SmallRatings />
        </MobileContainer>
      </Wrapper>
    </HeroContainer>
  )
}
