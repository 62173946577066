import { useEffect, useState } from "react"

export const useSignupUrl = (skipPresignup = false) => {
  const [pathName, setPathName] = useState<string>("")
  const [, leadingPath, plan] = pathName.split("/")

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [])

  const mappedPlanTypes: Record<string, string> = {
    medication: "medication",
    therapy: "individual",
    "couples-therapy": "couples",
    medication2: "medication",
  }

  const planTypeParam =
    leadingPath == "plans" ? "&planType=" + mappedPlanTypes[plan] : ""
  const screenHint = "screenHint=signup"
  return `${process.env.GATSBY_LOGIN_URL}?${
    skipPresignup ? "" : screenHint
  }${planTypeParam}`
}
