import { useEffect, useState } from "react"
import axios from "axios"
import {
  GetFaqsResponse,
  SectionResponse,
  Section,
  GetSectionsForCategoryResponse,
} from "../types/ZendeskApi"

//in new web repo, use useQuery instead
export function useFaqs({
  labels,
  sectionId,
}: {
  labels?: string[]
  sectionId?: number
}) {
  const [faqs, setFaqs] = useState<GetFaqsResponse>()

  useEffect(() => {
    const fetchFaqs = async () => {
      const url = `${process.env.GATSBY_ZENDESK_API_URL}/articles/search`

      try {
        const { data } = await axios.get<GetFaqsResponse>(url, {
          params: {
            label_names: labels?.join(","),
            section: sectionId,
          },
        })
        setFaqs(data)
      } catch (error) {
        console.log(error)
      }
    }

    if (labels || sectionId) {
      fetchFaqs()
    } else {
      console.log("No labels or section ID provided")
    }
  }, [labels, sectionId])

  return {
    faqs,
  }
}

export const useSectionNames = (sectionIds: number[] | undefined) => {
  const [sectionNames, setSectionNames] = useState({})

  useEffect(() => {
    if (sectionIds) {
      const getSections = async () => {
        const newNames: { [key: number]: string } = {}
        await Promise.all(
          sectionIds.map(async id => {
            try {
              const response = await axios.get<SectionResponse>(
                `${process.env.GATSBY_ZENDESK_API_URL}/en-us/sections/${id}/`
              )
              const data = response.data
              newNames[id] = data?.section?.name || ""
            } catch (e) {
              console.log(e)
              newNames[id] = ""
            }
          })
        )
        setSectionNames(newNames)
      }
      getSections()
    }
  }, [sectionIds])

  return sectionNames
}

export const getSectionsForCategory = () => {
  const [sections, setSections] = useState<Section[]>([])

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const { data } = await axios.get<GetSectionsForCategoryResponse>(
          `${process.env.GATSBY_ZENDESK_API_URL}/en-us/categories/${process.env.GATSBY_ZENDESK_API_FAQ_CATEGORY_ID}/sections`
        )
        setSections(data.sections)
      } catch (error) {
        console.error("Error fetching sections:", error)
      }
    }

    fetchSections()
  }, [])

  return sections // Return sections directly
}

export const useFaqCountsBySections = (sectionIds: number[]) => {
  const [faqCounts, setFaqCounts] = useState({})
  const url = `${process.env.GATSBY_ZENDESK_API_URL}/articles/search`

  useEffect(() => {
    const fetchFaqCounts = async () => {
      const newCounts: { [key: number]: number } = {}
      await Promise.all(
        sectionIds.map(async id => {
          try {
            const { data } = await axios.get(url, {
              params: { section: id },
            })
            newCounts[id] = data.count || 0
          } catch (error) {
            console.error("Fetching FAQs failed for section:", id, error)
            newCounts[id] = 0
          }
        })
      )
      setFaqCounts(newCounts)
    }

    if (sectionIds.length > 0) {
      fetchFaqCounts()
    }
  }, [sectionIds])

  return faqCounts
}
