import React from "react"
import { graphql } from "gatsby"
import { FAQSection } from "../shared/FAQSection"

interface FAQProps {
  slice: {
    primary: {
      slice_id?: {
        text: string
      }
      title: {
        text: string
      }
      margin: string
      faq_label: string
    }
    items: {
      faq_question: {
        document: {
          data: {
            question: {
              text: string
            }
            answer: {
              html: string
            }
          }
        }
      }
    }[]
  }
}

const FAQ = ({ slice }: FAQProps) => {
  const { faq_label, margin } = slice.primary

  return (
    <FAQSection
      labels={[faq_label]}
      title={slice.primary.title.text}
      id="faq"
      margin={margin}
    />
  )
}

export const query = graphql`
  fragment FAQSlice on PrismicLandingDataBodyFaq {
    id
    slice_type
    primary {
      title {
        text
      }
      faq_label
    }
    items {
      faq_question {
        document {
          ... on PrismicFaqRedesign {
            data {
              answer {
                html
              }
              question {
                text
              }
            }
          }
        }
      }
    }
  }

  fragment PageFAQSlice on PrismicPageDataBodyFaq {
    id
    slice_type
    primary {
      title {
        text
      }
      margin
      tab_id
      faq_label
    }
    items {
      faq_question {
        document {
          ... on PrismicFaqRedesign {
            data {
              answer {
                html
              }
              question {
                text
              }
            }
          }
        }
      }
    }
  }
`
export default FAQ
