import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const GetStartedWrapper = styled.section`
  max-width: 824px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 16px;
  h3 {
    color: ${colors.c_primary[700]};
    margin-bottom: 32px;
  }
`
