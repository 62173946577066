import { colors } from "../../themes/colors"
import styled from "styled-components"

export const StatsWrapper = styled.section`
  width: 100%;
  position: relative;
  overflow: auto;
`

export const StatsContainer = styled.div<{ bgColor?: string }>`
  height: 120px;
  min-width: min-content;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 32px 64px;
  gap: 160px;
  overflow-x: auto;
  color: ${colors.c_grey[900]};
  background-color: ${props => props.bgColor || colors.c_secondary[100]};

  @media (max-width: 1000px) {
    gap: 64px;
  }
  @media (max-width: 600px) {
    gap: 32px;
    padding: 24px;
  }
`

export const StatContainer = styled.div`
  display: flex;
  gap: 12px;
  text-align: left;
  white-space: nowrap;

  h3 {
    font-size: 33px;
    font-weight: 600;
  }

  strong {
    font-size: 15px;
    font-weight: 600;
    line-height: 14px;
  }

  span {
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  img {
    height: 28px;
  }
`

export const ContentWrapper = styled.div`
  width: 1216px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`
