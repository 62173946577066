import { useEffect, useState } from "react"
import {
  HightLightText,
  LeftBox,
  LeftImage,
  RightBox,
  ScreenerWrapper,
  TextImageWrapper,
  TextImageWrapperV2,
} from "./styled"
import Question from "./Question"
import { TServiceLine } from "./types"
import { questionIndexKey, screenerImages } from "./utils"

interface MiniScrennerProps {
  serviceLine: TServiceLine
}

const MiniScrenner = ({ serviceLine }: MiniScrennerProps) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentQuestionIndexKey = questionIndexKey(serviceLine)

  useEffect(() => {
    setCurrentQuestionIndex(
      parseInt(localStorage.getItem(currentQuestionIndexKey) || "0")
    )
  }, [])

  return (
    <ScreenerWrapper>
      <LeftBox>
        <LeftImage
          mobile={screenerImages[serviceLine].mobile}
          desktop={screenerImages[serviceLine].desktop}
        />
        {serviceLine !== "couplestherapy" ? (
          <TextImageWrapper>
            We make it easy to start feeling
            <HightLightText>balanced</HightLightText>
          </TextImageWrapper>
        ) : (
          <TextImageWrapperV2>
            Feel
            <HightLightText>good</HightLightText>
            in your relationship
          </TextImageWrapperV2>
        )}
      </LeftBox>
      <RightBox>
        <Question
          serviceLine={serviceLine}
          currentQuestionIndex={currentQuestionIndex}
          onSetCurrentQuestionIndex={setCurrentQuestionIndex}
          staticQuestions={screenerImages[serviceLine].questions}
          showSupportText={screenerImages[serviceLine].showSupportText}
          extendWidth={screenerImages[serviceLine].extendWidth}
        />
      </RightBox>
    </ScreenerWrapper>
  )
}

export default MiniScrenner
