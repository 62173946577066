import React from "react"
import styled from "styled-components"
import Container from "../../common/Container"
import PrismicText from "../ui/PrismicText"
import Button from "../../common/Button"
import Heading from "../ui/Heading"
import ItalicHeadingDynamic from "../ui/ItalicHeadingDynamic"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Files from "../ui/Files"
import BottomCta from "../ui/BottomCta"

const Image = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  margin: 29px auto 0;
  box-shadow: 0px 7px 4px rgba(34, 34, 52, 0.13);
`
const Avatar = styled(GatsbyImage)`
  border-radius: 100%;
`
const Text = styled(PrismicText)`
  margin: 60px auto 0;
  max-width: 320px;
`
const Cta = styled.div`
  text-align: center;
  margin: 30px auto 0;
  ${Button} {
    ${({ size }) =>
      size === "big"
        ? `padding: 22px 12px; min-width: 240px;`
        : `min-width: 120px;`}
  }
`
const Wrapper = styled.div`
  padding: 0 0 60px;

  ${Container} > ${Avatar} {
    background: #d4dbf5;
    margin-top: 27px;
  }

  ${ItalicHeadingDynamic} {
    margin: 40px auto 0;
  }

  ${Files} {
    max-width: 320px;
  }

  @media (min-width: 600px) {
    ${Text}, ${Files} {
      max-width: 900px;
    }
  }

  @media (min-width: 834px) {
    ${Image} {
      margin: 61px auto 0;
    }

    ${Cta} {
      margin-top: 50px;
    }
  }
`
function Page({ title, image, html: __html, cta, style, files }) {
  const images = getImage(image)
  const isFilesShown = files.find(file => file.url)

  if (
    !title &&
    !images &&
    (!__html || __html === "<p></p>" || __html === "<h2></h2>")
  ) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        {style === "Highlight" && <ItalicHeadingDynamic text={title} />}
        {style === "No Highlight" && <Heading>{title}</Heading>}
        {image && <Image image={images} />}
        <Text dangerouslySetInnerHTML={{ __html }} />
        {isFilesShown && <Files files={files} />}
        <BottomCta cta={cta} />
      </Container>
    </Wrapper>
  )
}

export default Page
