import React from "react"
import { H3, BodyRegular, Picture } from "../../../ui"
import {
  Container,
  TextContainer,
  LearnMore,
  MobileContainer,
  ContentContainer,
} from "./styled"
import { contents } from "./contents"
import persononphonesvg from "./assets/lady-on-phone.svg"

export const FindingRightTherapist = (): JSX.Element => {
  return (
    <Container>
      <ContentContainer>
        <MobileContainer>
          <Picture
            images={contents.images}
            fallBack={persononphonesvg}
            alt=""
          />
          <H3>
            Finding the right <br /> therapist for you...
          </H3>
        </MobileContainer>
        <TextContainer>
          <H3>Finding the right therapist for you...</H3>
          <BodyRegular>{contents.body}</BodyRegular>
          <LearnMore to="/therapy">Learn more about therapy</LearnMore>
        </TextContainer>
      </ContentContainer>
    </Container>
  )
}
