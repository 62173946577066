import { H3 } from "../../../ui"
import { GetStartedWrapper } from "./styled"
import { GetStartedButton } from "../../../shared/GetStartedButton"
export const GetStarted = () => {
  return (
    <GetStartedWrapper>
      <H3>Get started with 70% off your first month</H3>
      <GetStartedButton width={400} />
    </GetStartedWrapper>
  )
}
