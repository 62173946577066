import { useEffect } from "react"
import { graphql } from "gatsby"

const RedirectorPage = ({ data, location }) => {
  const doc = data.prismicRedirector
  const serialize = function (obj) {
    var str = []
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
      }
    return "?" + str.join("&")
  }

  useEffect(() => {
    const redirectLink = doc.data.redirecturl.text
    const url = location.href
    const queryParamsIdx = url.indexOf("?")
    let queryStr = ""
    let queryCha = ""

    if (queryParamsIdx !== -1) {
      queryStr = url.substr(queryParamsIdx)

      const urlSearchParams = new URLSearchParams(queryStr)
      const params = Object.fromEntries(urlSearchParams.entries())
      const allowedParams = ["utm"]
      const filteredParams = Object.keys(params)
        .filter(key => new RegExp(allowedParams.join("|")).test(key))
        .reduce((obj, key) => {
          obj[key] = params[key]
          return obj
        }, {})

      queryStr = serialize(filteredParams)
    }
    if (redirectLink.includes("?") && queryStr) {
      queryCha = "&"
    }
    if (!redirectLink.includes("?") && queryStr) {
      queryCha = "?"
    }
    if (!redirectLink) {
      window.location.href = "/" + queryStr
    } else {
      const rediretingLink = redirectLink + queryCha + queryStr

      if (redirectLink.includes("http") || redirectLink.startsWith("/")) {
        window.location.href = rediretingLink
      } else {
        window.location.href = "/" + rediretingLink
      }
    }
  }, [doc?.data?.redirecturl?.text, location?.href])

  return null
}

export const query = graphql`
  query RedirectorPageB2CQuery($id: String) {
    prismicRedirector(id: { eq: $id }) {
      data {
        redirecturl {
          text
        }
      }
    }
  }
`

export default RedirectorPage
