import styled from "styled-components"

export const MedsVsTherapyContainer = styled.section`
  margin: 64px auto;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 834px) {
    margin: 40px 24px;
  }
`
export const Header = styled.h2`
  font-family: Poppins;
  font-size: 40px;
  line-height: 50px;
  text-align: center;

  @media (max-width: 834px) {
    font-size: 28px;
    line-height: 35px;
  }
`
export const SubText = styled.p`
  font-family: Poppins;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
  max-width: 820px;
`

export const SectionWrapper = styled.div`
  display: flex;
  margin-top: 48px;
  gap: 80px;

  @media (max-width: 834px) {
    flex-direction: column;
    margin-top: 24px;
  }
`
export const Section = styled.div`
  flex-basis: 50%;
`

export const SectionHeader = styled.h3`
  font-family: Poppins;
  font-size: 22px;
  line-height: 26px;
`
export const SectionText = styled.p`
  font-family: Poppins;
  line-height: 24px;
  margin: 16px 0;
`
export const SectionLink = styled.a`
  font-family: Poppins;
  line-height: 24px;
  color: #385ade;
`
