import { CalendarProps } from "react-calendar"
import LeftArrow from "./assets/LeftArrow"
import RightArrow from "./assets/RightArrow"
import {
  Calendar as StyledCalendar,
  CalendarContainer,
  CalendarFadeout,
} from "./styled"

type SimpleCalProps = CalendarProps & {
  calendarVersion?: "simple"
  removeShadow?: boolean
  disabled?: boolean
}

type AvailabilityCalProps = CalendarProps & {
  calendarVersion: "availability"
  availableDays: string[] | []
  handleChangeDate: (date: Date) => void
  removeShadow?: boolean
  disabled?: boolean
}

export type StyledCalProps = SimpleCalProps | AvailabilityCalProps

type AvailabilityStyle = "available-day" | "unavailable-day"

export const Calendar = (props: StyledCalProps) => {
  const {
    calendarVersion,
    removeShadow = false,
    disabled = false,
    ...rest
  } = props
  let availableDays: string[]
  let handleChangeDate: (date: Date) => void = () => {}

  if (calendarVersion === "availability") {
    ;({ availableDays, handleChangeDate } = props)
  }

  const isAvailableDay = (day: Date): boolean => {
    return availableDays?.includes(day.toLocaleDateString("en-CA"))
  }

  const disableDay: CalendarProps["tileDisabled"] = ({ date }) => {
    return !isAvailableDay(date)
  }

  const styleAvailability: CalendarProps["tileClassName"] = ({
    date,
  }): AvailabilityStyle => {
    if (isAvailableDay(date)) {
      return "available-day"
    }
    return "unavailable-day"
  }

  const tileProps: {
    styleProp: CalendarProps["tileClassName"]
    disableProp: CalendarProps["tileDisabled"]
  } =
    calendarVersion === "availability"
      ? {
          styleProp: styleAvailability,
          disableProp: disableDay,
        }
      : {
          styleProp: undefined,
          disableProp: undefined,
        }

  return (
    <CalendarContainer disabled={disabled}>
      {disabled && <CalendarFadeout />}
      <StyledCalendar
        removeShadow={removeShadow}
        nextLabel={<RightArrow />}
        prevLabel={<LeftArrow />}
        next2Label={null}
        prev2Label={null}
        formatShortWeekday={(_, date) =>
          ["SU", "MO", "TU", "WE", "TH", "FR", "SA"][date.getDay()]
        }
        calendarType="US"
        formatMonthYear={(locale, date) =>
          date.toLocaleString(locale, { month: "short", year: "numeric" })
        }
        tileClassName={tileProps.styleProp}
        tileDisabled={tileProps.disableProp}
        onClickDay={handleChangeDate}
        {...rest}
      />
    </CalendarContainer>
  )
}
