import Layout from "../components/Layout"
import { FindAClinicianPage } from "../components/FindAClinicianPage"

export default function DynamicPage() {
  return (
    <Layout>
      <FindAClinicianPage role="prescriber" />
    </Layout>
  )
}
