import { SeoCopy } from "./styled"

export const TopSEOCopySection = ({ html }: { html?: string }) => {
  if (!html) return null
  return (
    <SeoCopy
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}
