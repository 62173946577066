import SEO from "../../SEO"
import { schemaMarkup } from "./seoSchema"
import { Navigation } from "../../ui/Navigation"
import { FaqCategoryPageBody, CategoryLinksContainer } from "./styled"
import { Heading, Faqs, BackToTop, BackButton } from "./sections"
import { FaqTopics, FaqCategoryLinks } from "../shared"
import { useWindowSize } from "../../hooks"
import { useFaqs } from "../../../hooks/useZendeskApi"
import { Section, FaqResult } from "../../../types/ZendeskApi"

export const FaqCategoryBody = ({
  section,
  faqs,
}: {
  section: Section
  faqs: FaqResult[] | undefined
}): JSX.Element => {
  const { isMobile } = useWindowSize()

  return (
    <FaqCategoryPageBody>
      {isMobile && <BackButton />}
      <Heading section={section} />
      <>
        <FaqTopics titleType="number" sectionId={section.id} />
        <Faqs faqs={faqs} />
      </>
      <CategoryLinksContainer>
        <FaqCategoryLinks />
      </CategoryLinksContainer>
      <FaqTopics title={"Popular Topics"} labels={["popular_topics_faq"]} />
      <BackToTop />
    </FaqCategoryPageBody>
  )
}

export const FaqCategoryPage = ({
  section,
}: {
  section: Section
}): JSX.Element => {
  const { faqs } = useFaqs({ sectionId: section.id })

  return (
    <>
      <SEO
        title={`${section.name} | FAQs | Cerebral`}
        description="All the info on Cerebral, all in one place."
        schemaMarkup={faqs && schemaMarkup(faqs.results)}
      />
      <Navigation />
      <FaqCategoryBody section={section} faqs={faqs?.results} />
    </>
  )
}
