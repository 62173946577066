import { graphql } from "gatsby"
import styled from "styled-components"
import Star from "../shared/StatsBlueBackground/assets/star-cw.svg"
import Forbes from "../shared/StatsBlueBackground/assets/forbes-logo-cw.svg"

const StatsContainer = styled.div`
  height: 120px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  overflow-x: auto;
  color: #1c1e21;
  width: 1140px;

  @media (max-width: 820px) {
    gap: 40px;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StatsWrapper = styled.section<{ backgroundColor?: string }>`
  width: 100%;
  position: relative;
  overflow: auto;
  display: flex;
  justify-content: center;
  background-color: ${props => props.backgroundColor};
`

const Description = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #1c1e21;
  margin-left: 14px;

  & div {
    gap: 0 !important;
  }
`

const StatContainer = styled.div`
  display: flex;
  gap: 12px;
  text-align: left;
  white-space: nowrap;
  font-family: Poppins;

  strong {
    font-size: 15px;
    font-weight: 600;
    line-height: 14px;
  }

  span {
    font-family: Inter;
    font-size: 12px;
    line-height: 16px;
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    height: 28px;
  }
`

const Title = styled.div`
  font-family: Poppins;
  font-size: 33px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
`

export interface StatsProps {
  slice: {
    primary: {
      platform_title: {
        html: string
      }
      platform_description: {
        html: string
      }
      clients_helped_title: {
        html: string
      }
      clients_helped_description: {
        html: string
      }
      rating_title: {
        html: string
      }
      rating_description: {
        html: string
      }
      forbes_description: {
        html: string
      }
      background_color: string
    }
  }
}

const Stats = ({ slice }: StatsProps): JSX.Element => {
  const replaceHtml = (html: string) => {
    return html.replace("&lt;br /&gt;", "<br />")
  }

  return (
    <StatsWrapper backgroundColor={slice.primary.background_color}>
      <StatsContainer>
        <StatContainer>
          <ItemWrapper>
            <strong
              dangerouslySetInnerHTML={{
                __html: replaceHtml(slice.primary.platform_title.html),
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: replaceHtml(slice.primary.platform_description.html),
              }}
            />
          </ItemWrapper>
        </StatContainer>
        <StatContainer>
          <ItemWrapper>
            <Title
              dangerouslySetInnerHTML={{
                __html: replaceHtml(slice.primary.clients_helped_title.html),
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: replaceHtml(
                  slice.primary.clients_helped_description.html
                ),
              }}
            />
          </ItemWrapper>
        </StatContainer>
        <StatContainer>
          <ItemWrapper>
            <img src={Star} alt="" width={27} height={27} />
            <Title
              dangerouslySetInnerHTML={{
                __html: replaceHtml(slice.primary.rating_title.html),
              }}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: replaceHtml(slice.primary.rating_description.html),
              }}
            />
          </ItemWrapper>
        </StatContainer>
        <StatContainer>
          <ItemWrapper>
            <img
              src={Forbes}
              alt="forbes"
              loading="lazy"
              width={97}
              height={28}
            />
            <Description
              dangerouslySetInnerHTML={{
                __html: replaceHtml(slice.primary.forbes_description.html),
              }}
            />
          </ItemWrapper>
        </StatContainer>
      </StatsContainer>
    </StatsWrapper>
  )
}

export const query = graphql`
  fragment StatsSlice on PrismicLandingDataBodyStats {
    slice_type
    primary {
      platform_title {
        html
      }
      platform_description {
        html
      }
      clients_helped_title {
        html
      }
      clients_helped_description {
        html
      }
      rating_title {
        html
      }
      rating_description {
        html
      }
      forbes_description {
        html
      }
      background_color
    }
  }
  fragment PageStatsSlice on PrismicPageDataBodyStats {
    slice_type
    primary {
      platform_title {
        html
      }
      platform_description {
        html
      }
      clients_helped_title {
        html
      }
      clients_helped_description {
        html
      }
      rating_title {
        html
      }
      rating_description {
        html
      }
      forbes_description {
        html
      }
      background_color
    }
  }
`

export default Stats
