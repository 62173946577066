import styled from "styled-components"

export const Wrapper = styled.div<{ isShow: boolean }>`
  display: ${props => (props.isShow ? "block" : "none")};
  @media (max-width: 500px) {
    padding: 0px 24px;
  }
`

export const Header = styled.div`
  padding: 20px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #fafafc;
`

export const Logo = styled.img`
  height: 16px;
`

export const Body = styled.div`
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  height: calc(100vh - 60px);
  align-content: center;
`

export const Title = styled.div`
  color: var(--bn-w-black-900, #1c1e21);
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 48px;
`

export const Subtitle = styled.div`
  color: var(--bn-w-black-900, #1c1e21);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`

export const RedirectingText = styled.div`
  color: var(--Primary-600, #51459e);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 16px;
`
export const Spinner = styled.span`
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #7d70d2;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-top: 32px;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const MainImage = styled.img`
  width: 480px;

  @media (max-width: 500px) {
    width: 100%;
  }
`
