import { FC, useEffect, useRef, useState } from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import {
  edgeToPostPreview,
  edgeToPost,
  edgeToAuthor,
  edgeToBio,
} from "../../lib/utils"
import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { getImage, ImageDataLike } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Navigation } from "../../components/ui/Navigation"
import EndBlog from "../../components/shared/Blog/EndBlog"
import {
  AuthorWrapper,
  Breadcrumbs,
  BreadcrumbsTitle,
  Wrapper,
  Link,
  PreviewImage,
  SliceWrapper,
  Title,
  OldContentWrapper,
  OldContent,
  OldCtaButtonWrapper,
  OldCtaTitle,
  OldCtaSubTitle,
  BlogTopWrapper,
  CategoryLink,
} from "./styled"
import { PostPageProps } from "./types"
import AuthorAndReviewer from "../../components/shared/Blog/AuthorAndMedicalReview"
import SliceZone from "../../components/prismic/SliceZone"
import BioSection from "../../components/shared/Blog/BioSection"
import { CtaButton } from "../../components/prismic/LandingPageStyles"
import { EmailCollectionPopup } from "../../components/HomePage/sections/EmailCollectionPopup"
import { useEmailCollectionPopup } from "../../components/shared/BlogRepo/useEmailCollectionPopup"
import MiniScrenner from "../../components/shared/Blog/MiniScreener/MiniScreener"
import GenericWidget from "../../components/shared/Blog/MiniScreener/GenericWidget"
import {
  couplesTherapyWidgetCopy,
  genericWidgetCopy,
} from "../../components/shared/Blog/MiniScreener/GenericWidget/constants"
import {
  GENERIC_PATTERN,
  SERVICE_LINE_PATTERN,
  COUPLES_THERAPY_PATTERN,
  getServiceLine,
} from "./utils"

const getSchemaMarkup = (
  title: string,
  description: string,
  image: ImageDataLike,
  url: string,
  author: string,
  dateString: string
) => {
  const imageUrl = image ? getImage(image)?.images?.fallback?.src : undefined
  const [month, day, year] = dateString.split("/")
  const date = new Date(+year, +month - 1, +day)

  return {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: title,
    image: [imageUrl],
    author: author,
    editor: author,
    publisher: {
      "@type": "Organization",
      name: "Cerebral",
      logo: {
        "@type": "ImageObject",

        url: "https://images.prismic.io/cerebral/35fdc3b1-9224-4b94-ad47-9b549c67edac_logo.png",
      },
    },
    url: "https://cerebral.com/",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://cerebral.com/blog${url}`,
    },
    datePublished: date,
    dateCreated: date,
    dateModified: date,
    description: description.slice(0, 300) + "...",
  }
}

const PostPage = ({ data }: PostPageProps) => {
  const post = edgeToPost({ edge: data.main.edges[0] })
  const categories = post.categories.flatMap(item => item.category)
  const { seo, image } = post

  const [previewImage, setPreviewImage] = useState(
    getImage(post.previewimage)?.placeholder?.fallback
  )
  const previewImageElement = useRef<HTMLImageElement>(null)

  const schemaMarkup = getSchemaMarkup(
    post.title,
    post.text,
    post.previewimage,
    post.url,
    post.author.name,
    post.date
  )

  const recentPosts = data.more.edges.map(edge => edgeToPostPreview({ edge }))
  const bioSectionProps = edgeToBio({ edge: data.main.edges[0] })
  const { showModal, setShowModal } = useEmailCollectionPopup()

  useEffect(() => {
    if (previewImageElement.current?.complete) {
      setPreviewImage(getImage(post.previewimage)?.images?.fallback?.src)
    }
  }, [previewImageElement])

  return (
    <Layout useKameleoon={true}>
      <SEO image={image} {...seo} schemaMarkup={schemaMarkup} />
      <Navigation />
      {showModal && (
        <EmailCollectionPopup onClose={() => setShowModal(false)} />
      )}

      <Wrapper>
        <BlogTopWrapper>
          <Breadcrumbs>
            <Link href="/blog">Blog</Link>
            <p>/</p>
            <CategoryLink
              href={`/blog?categories=${categories
                .join("|")
                .replace(" ", "+")}`}
            >
              {categories.join(" - ")}
            </CategoryLink>
            <p>/</p>
            <BreadcrumbsTitle>{post.title}</BreadcrumbsTitle>
          </Breadcrumbs>
          <Title>{post.title}</Title>
          <AuthorWrapper>
            <AuthorAndReviewer
              {...edgeToAuthor({ edge: data.main.edges[0] })}
            />
          </AuthorWrapper>

          <PreviewImage
            src={previewImage}
            alt="preview_image"
            loading="lazy"
            ref={previewImageElement}
          />
        </BlogTopWrapper>

        {post.html && (
          <OldContentWrapper>
            <OldCtaButtonWrapper>
              <OldCtaTitle>
                {post.cta.title || "Take the next step to feeling better"}
              </OldCtaTitle>
              {post.cta.subtitle && (
                <OldCtaSubTitle>{post.cta.subtitle}</OldCtaSubTitle>
              )}
              <CtaButton
                to={post.cta.link || "https://cerebral.com/app/welcome"}
              >
                {post.cta.text || "Get started"}
              </CtaButton>
            </OldCtaButtonWrapper>

            <OldContent>
              {parse(post.html, {
                replace(domNode) {
                  const data = (domNode as any).data
                  if (data?.match(SERVICE_LINE_PATTERN)) {
                    const serviceLine = getServiceLine(data)

                    return <MiniScrenner serviceLine={serviceLine} />
                  }

                  if (data?.match(GENERIC_PATTERN)) {
                    return <GenericWidget plans={genericWidgetCopy} />
                  }

                  if (data?.match(COUPLES_THERAPY_PATTERN)) {
                    return <GenericWidget plans={couplesTherapyWidgetCopy} />
                  }
                },
              })}
            </OldContent>
          </OldContentWrapper>
        )}

        <SliceWrapper>
          <SliceZone sliceZones={data.prismicPost.data.body} posts={[]} />
          <BioSection {...bioSectionProps} />
        </SliceWrapper>
      </Wrapper>

      <EndBlog
        posts={recentPosts}
        title={post.contextual_cta_title}
        subtitle={post.contextual_cta_subtitle}
      />
    </Layout>
  )
}

export const query = graphql`
  query PostPageQuery($id: String, $category: [String]) {
    main: allPrismicPost(filter: { id: { eq: $id } }) {
      edges {
        node {
          _previewable
          data {
            cta_link {
              url
            }
            cta_text {
              text
            }
            cta_title {
              text
            }
            cta_subtitle {
              text
            }
            title {
              text
            }
            previewimage {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 1216)
            }
            content {
              html
              text
            }
            categories {
              category
            }
            author_name {
              text
            }
            author_picture {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 48)
            }
            author_link {
              url
            }
            reviewer_name {
              text
            }
            reviewer_picture {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 48)
            }
            reviewer_link {
              url
            }
            date(formatString: "MM/DD/YYYY")
            seotitle {
              text
            }
            seodescription {
              text
            }
            seokeywords {
              text
            }
            seocanonicaltag {
              url
            }
            contextual_cta_title {
              text
            }
            contextual_cta_subtitle {
              text
            }
          }
          prismicId
          uid
          id
        }
      }
    }

    more: allPrismicPost(
      limit: 3
      sort: { fields: data___date, order: DESC }
      filter: {
        data: { categories: { elemMatch: { category: { in: $category } } } }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            date(formatString: "MM/DD/YYYY")
            previewimage {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 1216)
            }
            categories {
              category
            }
            content {
              text
            }
            body {
              ... on PrismicPostDataBodyBlogText {
                primary {
                  text {
                    text
                  }
                }
              }
            }
          }
          uid
        }
      }
    }

    prismicPost: prismicPost(id: { eq: $id }) {
      data {
        body {
          ...PostBlogTextSlice
          ...PostBlogImageSlice
          ...PostBlogCtaButtonWithTextSlice
          ...PostVideoSlice
        }
      }
    }
  }
`

export default process.env.GATSBY_ACTIVE_ENV === "production"
  ? PostPage
  : withPrismicPreview(PostPage as FC)
