import React from "react"
import Layout from "../components/Layout"
import { TherapyPage } from "../components/TherapyPage"

export default function DynamicPage() {
  return (
    <Layout>
      <TherapyPage />
    </Layout>
  )
}
