import { Container, Title } from "./styled"
import { Subheading } from "../../../shared/styled"
import { categoryPageInfo } from "../../../shared/constants"
import { Section } from "../../../../../types/ZendeskApi"

export const Heading = ({ section }: { section: Section }) => {
  return (
    <Container>
      <img
        src={categoryPageInfo[section.name]["pageImage"]}
        alt={section.name}
      />
      <Title>{section.name}</Title>
      <Subheading>{categoryPageInfo[section.name]["subTitle"]}</Subheading>
    </Container>
  )
}
