import React from "react"
import { SuccessStories } from "../HomePage/sections"
import { graphql } from "gatsby"
import { Source } from "../../components/shared/ContentHub/types"

interface TestimonialsProps {
  slice: {
    primary: {
      header: { text: string }
      show_see_more_button: boolean
      background_color: string
      content_wrapper_max_width: string
      content_wrapper_background_image: {
        url: string
      }
      show_navigate: boolean
      card_text_color: string
    }
    items: {
      item_copy: { text: string }
      item_header: { text: string }
      source: { text: Source }
      item_title: { text: string }
    }[]
  }
}

const Testimonials = ({ slice }: TestimonialsProps) => {
  const {
    items,
    primary: {
      header,
      background_color,
      show_see_more_button,
      content_wrapper_max_width,
      content_wrapper_background_image,
      show_navigate,
      card_text_color,
    },
  } = slice

  const contentWrapperStyle = content_wrapper_background_image
    ? {
        width: content_wrapper_max_width,
        backgroundImage: `url(${content_wrapper_background_image.url})`,
        backgroundRepeat: "no-repeat",
        maxWidth: "100%",
      }
    : undefined

  const quotes = items.map(item => ({
    quote: item.item_copy.text,
    name: item.item_header.text,
    source: item.source.text,
    title: item.item_title.text,
  }))

  return (
    <SuccessStories
      items={quotes}
      variant="secondary"
      headerTitle={header.text}
      wrapperStyle={{ backgroundColor: background_color }}
      contentWrapperStyle={contentWrapperStyle}
      cardTextStyle={{ color: card_text_color }}
      hideButton={show_see_more_button === false}
      showNavigation={show_navigate}
    />
  )
}

export const query = graphql`
  fragment PageTestimonialsSlice on PrismicPageDataBodyTestimonials {
    id
    slice_type
    items {
      item_title {
        text
      }
      item_copy {
        text
      }
      item_header {
        text
      }
      item_image {
        url
      }
      source {
        text
      }
    }
    primary {
      background_color
      header {
        text
      }
      show_see_more_button
      content_wrapper_max_width
      content_wrapper_background_image {
        url
      }
      show_navigate
      card_text_color
    }
  }
`
export default Testimonials
