import styled from "styled-components"

export const ScreenerWrapper = styled.div`
  width: 992px;
  display: flex;
  margin: 0 auto;
  position: relative;
  left: -136px;

  @media (max-width: 992px) {
    width: 720px;
    left: 0;
  }

  @media (max-width: 884px) {
    width: 100vw;
    flex-direction: column-reverse;
    left: calc(-1 * (100vw - 720px) / 2);
  }

  @media (max-width: 740px) {
    width: 100vw;
    flex-direction: column-reverse;
    left: -24px;
  }
`
export const LeftBox = styled.div`
  position: relative;
`

export const RightBox = styled.div`
  width: calc(100% - 286px);
  height: 372px;
  background: #f2f5ff;
  border-radius: 0 16px 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @media (max-width: 992px) {
    padding: 0 24px;
    height: 552px;
  }

  @media (max-width: 884px) {
    width: 100%;
    padding: 40px;
    border-radius: 0;
    height: auto;
  }
`

export const LeftImage = styled.div<{ mobile: string; desktop: string }>`
  border-radius: 16px 0 0 16px !important;
  width: 286px;
  height: 372px !important;
  background: url(${props => props.desktop}) no-repeat;
  background-size: cover;

  @media (max-width: 992px) {
    height: 552px !important;
    width: 339px;
  }

  @media (max-width: 884px) {
    width: 100%;
    flex-direction: column;
    border-radius: 0 !important;
    height: 320px !important;
    background: url(${props => props.mobile}) no-repeat;
    background-size: cover;
  }

  @media (max-width: 600px) {
    height: 256px !important;
  }
`

export const ProgressWrapper = styled.div<{ isShown: boolean }>`
  width: 203px;
  height: 2px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
  opacity: ${props => (props.isShown ? 1 : 0)};
  transition: opacity 0.5s;

  @media (max-width: 600px) {
    width: 163px;
  }
`
export const Progress = styled.div<{ isActive: boolean }>`
  background-color: ${props => (props.isActive ? "#51459E" : "#51459E4D")};
  border-radius: 1px;
  transition: background-color 0.5s;
`
export const QuestionTitle = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  max-width: 482px;
  margin-top: 12px;
  margin-bottom: 40px;

  @media (max-width: 992px) {
    margin-bottom: 24px;
  }

  @media (max-width: 884px) {
    font-family: Poppins;
    font-size: 18px !important;
    line-height: 20px;
    max-width: 536px;
    margin-bottom: 24px;
  }

  @media (max-width: 740px) {
    max-width: calc(100vw - 80px);
    margin-bottom: 24px;
  }
`

export const AnswerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const AnswerOptionWrapper = styled.div<{ extendWidth: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: ${props => `${props.extendWidth ? "626" : "536"}px`};
  justify-content: center;

  @media (max-width: 992px) {
    width: 381px;
  }

  @media (max-width: 884px) {
    width: 536px;
  }

  @media (max-width: 740px) {
    width: calc(100vw - 80px);
  }
`

export const AnswerOption = styled.button<{ extendWidth: boolean }>`
  width: ${props => `${props.extendWidth ? "300" : "260"}px`};
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1.5px solid #fff;
  background: #fff;
  cursor: pointer;

  p {
    margin: 0;
  }

  @media (max-width: 992px) {
    width: 333px;
  }

  @media (max-width: 884px) {
    width: 100%;
  }
`
export const OptionTitle = styled.span`
  color: #353a41;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const NextStepTitle = styled.div`
  max-width: 402px;
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 20px;
  }
`

export const NextStepDescription = styled.div`
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 8px 0 32px 0;
  max-width: 476px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }
`
export const ContinueScreenerButton = styled.a<{
  variant: "primary" | "outline"
}>`
  color: ${props =>
    props.variant === "primary" ? "#ffffff !important" : "#51459E !important"};
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  height: 56px;
  background-color: ${props =>
    props.variant === "primary" ? "#51459E" : "none"};
  text-decoration: ${props =>
    props.variant === "primary" ? "none !important" : "underline"};
  border-radius: 100px;
  padding: 0 24px;
  display: flex;
  align-items: center;
`

export const BackButton = styled.div<{ isShown: boolean }>`
  display: flex;
  opacity: ${props => (props.isShown ? 1 : 0)};
  align-items: center;
  cursor: ${props => (props.isShown ? "pointer" : "initial")};
  position: absolute;
  top: 12px;
  left: 24px;
  transition: opacity 0.5s;
  z-index: ${props => (props.isShown ? 1 : -999999)};

  @media (max-width: 884px) {
    top: 8px;
    left: 16px;
  }
`

export const ButtonText = styled.div`
  color: #51459e;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;

  @media (max-width: 884px) {
    font-size: 14px;
  }
`

export const TextImageWrapper = styled.div`
  position: absolute;
  color: #ffffff;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  max-width: 206px;
  left: 24px;
  bottom: 24px;

  @media (max-width: 884px) {
    font-size: 18px;
    max-width: 155px;
  }
`

export const TextImageWrapperV2 = styled.div`
  position: absolute;
  color: #ffffff;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  width: 170px;
  left: 24px;
  bottom: 24px;

  @media (max-width: 884px) {
    font-size: 18px;
    width: 200px;

    div {
      margin-right: 50px !important;
    }
  }

  div {
    display: inline-block;
    margin: 0px 10px;
  }
`

export const HightLightText = styled.div`
  border-radius: 40px;
  background: #a7bcff;
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  width: fit-content;
  padding: 0px 20px;
  margin-top: 11px;

  @media (max-width: 884px) {
    font-size: 18px;
  }
`

export const SupportingText = styled.div<{
  isShown: boolean
  isBipolar: boolean
}>`
  margin-top: 40px;
  color: #1c1e21;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  opacity: ${props => (props.isShown ? 1 : 0)};
  transition: opacity 0.5s;
  max-width: ${props => (props.isBipolar ? "350px" : "100%")};

  @media (max-width: 992px) {
    width: 252px;
  }

  @media (max-width: 740px) {
    width: calc(100vw - 80px);
    padding: 0 40px;
    margin-top: 24px;
  }
`

export const QuestionsWrapper = styled.div<{
  index: number
  extendWidth: boolean
}>`
  display: flex;
  width: 706px;
  gap: ${props => `${props.extendWidth ? 40 : 85}px`};
  position: relative;
  left: ${props => `${props.extendWidth ? 40 : 85}px`};
  transition: transform 1s;
  transform: ${props => {
    const widthBlock = props.extendWidth ? 666 : 623
    return `translateX(-${props.index * widthBlock}px)`
  }};

  @media (max-width: 992px) {
    width: 381px;
    padding: 0 24px;
    left: 0;
    gap: 0;
    transform: ${props => `translateX(-${props.index * 333}px)`};
  }

  @media (max-width: 884px) {
    width: 536px;
    padding: 0;
    transform: ${props =>
      `translateX(calc(-1 * (${props.index} * (50vw + 268px))))`};
    gap: calc((100vw - 536px) / 2);
  }

  @media (max-width: 740px) {
    width: calc(100vw - 80px);
    gap: 40px;
    transform: ${props =>
      `translateX(calc(-1 * (${props.index} * (100vw - 40px))))`};
  }
`
export const QuestionItemWrapper = styled.div<{
  hidden: boolean
  index: number
  extendWidth: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 1s;
  opacity: ${props => (props.hidden ? 0 : 1)};
  margin-left: ${props => {
    const mgLeft = props.extendWidth ? "-40px" : "-80px"
    return props.index === 3 ? mgLeft : 0
  }};

  @media (max-width: 992px) {
    margin-left: 0;
  }

  @media (max-width: 884px) {
    width: 536px;
  }

  @media (max-width: 740px) {
    width: calc(100vw - 80px);
  }
`
export const NextStepWrapper = styled.div`
  width: 706px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin-top: -32px;

  @media (max-width: 992px) {
    width: 381px;
    padding: 0 24px;
  }

  @media (max-width: 884px) {
    width: 536px;
  }

  @media (max-width: 740px) {
    width: calc(100vw - 48px);
  }
`
export const LinkButton = styled.a<{
  variant: "primary" | "outline"
}>`
  color: #353a41 !important;
  text-decoration-line: none !important;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  border-radius: 16px;
  background: var(--bn-w-white-900, #fff);
  padding: 8px;
  min-width: 290px;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;

  img:first-of-type {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }

  img:nth-child(2) {
    width: 24px;
    height: 24px;
  }
`

export const MainText = styled.div`
  display: flex;
  align-items: center;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`

export const EmptySpace = styled.div`
  margin-top: 40px;
`
