import React, { useEffect, useState } from "react"
import { Navigation } from "./Navigation"
import PlansNavigation from "./PlansNavigation"
import styled from "styled-components"

const Wrapper = styled.div`
  background: white;
`

const DynamicNavigation = ({ bannerLookupKey }) => {
  return (
    <Wrapper>
      <Navigation />
    </Wrapper>
  )
}

export default DynamicNavigation
