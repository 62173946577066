import { useEffect, useState } from "react"
import { createApiInstance } from "@cerebral-inc/api"
import { UilArrowRight, UilAngleLeft } from "@iconscout/react-unicons"
import {
  AnswerOption,
  AnswerOptionWrapper,
  AnswerWrapper,
  BackButton,
  ButtonText,
  OptionTitle,
  Progress,
  ProgressWrapper,
  QuestionItemWrapper,
  QuestionTitle,
  SupportingText,
  QuestionsWrapper,
  EmptySpace,
} from "./styled"
import Spinner from "../../../../components/ui/Spinner"
import { IAnswerOption, IQuestion, TServiceLine } from "./types"
import {
  answersStorageKey,
  questionIndexKey,
  segmentIntegrations,
} from "./utils"
import NextStep from "./NextStep"
import segment, { segmentEvents } from "../../../../lib/segment"
import NextStepCoupleTherapy from "./NextStepCoupleTherapy"

interface QuestionProps {
  serviceLine: TServiceLine
  currentQuestionIndex: number
  onSetCurrentQuestionIndex: (index: number) => void
  staticQuestions?: IQuestion[]
  showSupportText?: boolean
  extendWidth?: boolean
}

const Question = ({
  serviceLine,
  currentQuestionIndex,
  onSetCurrentQuestionIndex,
  staticQuestions = [],
  showSupportText = true,
  extendWidth = false,
}: QuestionProps) => {
  const api = createApiInstance({
    baseURL: process.env.GATSBY_PLATFORM_API_URL,
  })
  const [questions, setQuestions] = useState<IQuestion[]>(staticQuestions || [])
  const [progress, setProgress] = useState([
    { isActive: true },
    { isActive: false },
    { isActive: false },
  ])

  const answersKey = answersStorageKey(serviceLine)
  const currentQuestionIndexKey = questionIndexKey(serviceLine)
  const hasUnansweredQuestions = currentQuestionIndex < 3
  const isBipolar = serviceLine === "bipolar"

  const fetchQuestions = async () => {
    const answerStorageData = localStorage.getItem(answersKey)
      ? JSON.parse(localStorage.getItem(answersKey) || "{}")
      : null

    let data = []
    if (answerStorageData) {
      data = answerStorageData
    } else {
      try {
        const fetchData = await api.get<IQuestion[]>(
          `/api/public-assessment/v1/free-assessment?serviceLine=${serviceLine}`
        )
        data = fetchData.data
      } catch (error) {}
    }

    setQuestions(data.slice(0, 3))
  }

  const handleClickNext = (option: IAnswerOption) => {
    if (hasUnansweredQuestions) {
      onSetCurrentQuestionIndex(currentQuestionIndex + 1)
      localStorage.setItem(
        currentQuestionIndexKey,
        `${currentQuestionIndex + 1}`
      )

      segment.track(
        segmentEvents.CLICK_OPTION_MINI_WIDGET,
        {
          question: questions[currentQuestionIndex].title,
          answered: option.title,
          serviceLine,
        },
        segmentIntegrations
      )
    }

    const newQuestions = questions.map(question => {
      if (question.id === questions[currentQuestionIndex].id) {
        return {
          ...question,
          selectedOption: option,
        }
      }

      return question
    })

    localStorage.setItem(answersKey, JSON.stringify(newQuestions))
    setQuestions(newQuestions)
  }

  const handleClickBackButton = () => {
    if (currentQuestionIndex > 0) {
      onSetCurrentQuestionIndex(currentQuestionIndex - 1)
      localStorage.setItem(
        currentQuestionIndexKey,
        `${currentQuestionIndex - 1}`
      )
    }
  }

  const getSupportingText = () => {
    if (isBipolar) {
      return "Has there ever been a period of time when you were not your usual self and..."
    }

    return "Over the last 2 weeks, how often have you experienced"
  }

  useEffect(() => {
    localStorage.removeItem(currentQuestionIndexKey)
    if (staticQuestions.length === 0) {
      fetchQuestions()
    }
  }, [])

  useEffect(() => {
    const newProgress = progress.map((item, index) => {
      if (index <= currentQuestionIndex) {
        return { isActive: true }
      }

      return { isActive: false }
    })
    setProgress(newProgress)
  }, [currentQuestionIndex])

  return (
    <>
      {questions.length > 0 ? (
        <>
          <BackButton
            onClick={handleClickBackButton}
            isShown={currentQuestionIndex > 0}
          >
            <UilAngleLeft size={20} color="#51459E" />
            <ButtonText>Back</ButtonText>
          </BackButton>

          <ProgressWrapper isShown={hasUnansweredQuestions}>
            {progress.map((item, index) => (
              <Progress key={index} isActive={item.isActive} />
            ))}
          </ProgressWrapper>

          {showSupportText ? (
            <SupportingText
              isShown={currentQuestionIndex < 3}
              isBipolar={isBipolar}
            >
              {getSupportingText()}
            </SupportingText>
          ) : (
            <EmptySpace />
          )}

          <QuestionsWrapper
            index={currentQuestionIndex}
            extendWidth={extendWidth}
          >
            {questions.map((question, index) => (
              <QuestionItemWrapper
                hidden={index !== currentQuestionIndex}
                index={index}
                extendWidth={extendWidth}
              >
                <QuestionTitle>{question.title}</QuestionTitle>
                <AnswerWrapper>
                  <AnswerOptionWrapper extendWidth={extendWidth}>
                    {question.answer_options.map(option => (
                      <AnswerOption
                        extendWidth={extendWidth}
                        key={option.title}
                        onClick={() => handleClickNext(option)}
                      >
                        <OptionTitle>{option.title}</OptionTitle>
                        <UilArrowRight size={24} color="#A7BCFF" />
                      </AnswerOption>
                    ))}
                  </AnswerOptionWrapper>
                </AnswerWrapper>
              </QuestionItemWrapper>
            ))}
            <QuestionItemWrapper
              hidden={hasUnansweredQuestions}
              extendWidth={extendWidth}
              index={currentQuestionIndex}
            >
              {serviceLine === "couplestherapy" ? (
                <NextStepCoupleTherapy />
              ) : (
                <NextStep serviceLine={serviceLine} />
              )}
            </QuestionItemWrapper>
          </QuestionsWrapper>
        </>
      ) : (
        <Spinner className="" />
      )}
    </>
  )
}

export default Question
