import React from "react"
import Layout from "../components/Layout"
import { CareTeamPrescribersPage } from "../components/CareTeamPages/CareTeamPrescribersPage"

export default function DynamicPage(): JSX.Element {
  return (
    <Layout>
      <CareTeamPrescribersPage />
    </Layout>
  )
}
