import { BlogListingWrapper } from "./styled"

import { Post } from "../Blog/types"
import ListingPostCard from "../Blog/ListingPostCard"

interface BlogListingProps {
  posts?: Post[]
}

const BlogListing = ({ posts }: BlogListingProps) => {
  if (!posts || posts.length === 0) {
    return <></>
  }

  return (
    <BlogListingWrapper>
      {posts.map((item, index) => (
        <ListingPostCard post={item} key={index} isListing={true} />
      ))}
    </BlogListingWrapper>
  )
}

export default BlogListing
