import React from "react"
import { TherapeuticApproachesType } from "../../types"
import { H2, H3, BodyRegular } from "../../../ui"
import {
  Container,
  ApproachesContainer,
  ApproachDesc,
  ApproachContainer,
  APA,
} from "./styled"

export const TherapeuticApproaches = ({
  approaches,
}: {
  approaches: TherapeuticApproachesType[]
}): JSX.Element => {
  return (
    <Container>
      <H2>Therapeutic Approaches</H2>
      <BodyRegular>
        Therapists use a variety of methods to help clients work through
        challenges and build long-term coping skills{" "}
      </BodyRegular>
      <ApproachesContainer>
        {approaches.map(approach => (
          <ApproachContainer key={approach.title.text}>
            <H3>
              <img src={approach.header_icon.url} /> {approach.title.text}
            </H3>
            <ApproachDesc
              dangerouslySetInnerHTML={{
                __html: approach.desc.html,
              }}
            />
            {approach.learn_more?.url && (
              <a href={approach.learn_more.url} target="_blank">
                Learn more
              </a>
            )}
          </ApproachContainer>
        ))}
      </ApproachesContainer>
      <APA>
        Source:{" "}
        <a href="https://www.apa.org/" target="_blank">
          APA
        </a>
        <br />
        <br />
        Cerebral therapists are trained in a variety approaches and specialties
        can differ depending on who is available in your state. You can see
        which approaches therapists use in their bios when you go to find a
        therapist and book a session.
      </APA>
    </Container>
  )
}
