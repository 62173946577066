import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  font-family: Poppins;
  padding: 64px 0px;
  margin: auto;
  display: flex;
  width: 1082px;
  flex-direction: column;
  align-items: center;
  gap: var(--base-S6, 48px);

  @media (max-width: 1082px) {
    width: 100%;
    padding: 48px 24px;
  }
`

const Title = styled.div`
  color: var(--bn-w-black-900, #1c1e21);
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--base-S3, 24px);

  @media (max-width: 600px) {
    gap: 32px;
  }
`
const ItemWrapper = styled.div`
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: var(--base-S3, 24px);
  display: flex;
  height: 210px;

  @media (max-width: 1082px) {
    height: auto;
    width: 100%;
    gap: 40px;
  }
`

const IconWrapper = styled.div`
  width: 56px;
  height: 56px;
`
const Image = styled(GatsbyImage)`
  width: 56px;
  height: 56px;
`
const ItemTitle = styled.div`
  color: var(--bn-w-black-900, #1c1e21);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  text-wrap: nowrap;
`

const ItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--base-S1, 8px);
  text-align: center;
`
const ItemContent = styled.div`
  color: var(--Grey-900, #353a41);
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`
const ValueWrapper = styled.div`
  display: flex;
  width: 1082px;
  justify-content: center;
  align-items: center;
  gap: 56px;

  @media (max-width: 1082px) {
    flex-direction: column;
    width: 100%;
    gap: 40px;
  }
`

const Button = styled.button`
  width: 320px;
  height: 56px;
  padding: 12px 24px;
  border-radius: 28px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  background: #51459e;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #51459e;
  cursor: pointer;
  &:hover {
    background: #422e80;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

interface ValuePropSliceInterface {
  slice: {
    slice_type: string
    primary: {
      main_title: { text: string }
    }
    items: {
      description: { text: string }
      icon: IGatsbyImageData
      item_title: { text: string }
    }[]
  }
}

const ValuePropSlice = ({ slice }: ValuePropSliceInterface) => {
  const {
    primary: { main_title },
    items,
  } = slice

  const jumpToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Wrapper>
      <Title>{main_title.text}</Title>
      <Content>
        <ValueWrapper>
          {items.map((item, index) => (
            <ItemWrapper>
              <IconWrapper>
                <Image
                  image={getImage(item.icon) as IGatsbyImageData}
                  loading="lazy"
                  alt=""
                />
              </IconWrapper>
              <ItemContentWrapper>
                <ItemTitle>{item.item_title.text}</ItemTitle>
                <ItemContent>{item.description.text}</ItemContent>
              </ItemContentWrapper>
            </ItemWrapper>
          ))}
        </ValueWrapper>
        <Button onClick={jumpToTop}>Get started</Button>
      </Content>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageValuePropSlice on PrismicPageDataBodyValuePropSlice {
    id
    items {
      description {
        text
      }
      icon {
        gatsbyImageData
      }
      item_title {
        text
      }
    }
    primary {
      main_title {
        text
      }
    }
    slice_label
    slice_type
  }
`

export default ValuePropSlice
