import { UilUser } from "@iconscout/react-unicons"
import { UilHeartMedical } from "@iconscout/react-unicons"
import { UilCalendarAlt } from "@iconscout/react-unicons"

export const IconOptions = [
  {
    icon: UilUser,
    title: "Create an account",
  },
  {
    icon: UilHeartMedical,
    title: "Choose a plan",
  },
  {
    icon: UilCalendarAlt,
    title: "Book a session",
  },
]
