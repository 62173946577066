import styled from "styled-components"
import { colors } from "../../../../../themes/colors"

export const MedicationBlockCard = styled.div`
  padding: 16px;
  border-radius: 10px;
  background: ${colors.c_white[100]};
  min-height: 208px;
  text-align: left;
  h3 {
    color: ${colors.c_black[900]};
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
    img {
      vertical-align: text-top;
    }
  }
  li {
    color: ${colors.c_grey[800]};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  b {
    font-weight: 600;
  }

  @media (max-width: 724px) {
    width: 100%;

    h3 {
      width: calc(100vw - 80px);
    }
  }
`
