import styled from "styled-components"
import {
  PostPreviewImage,
  CardWrapper,
  Category,
  Date,
  DateCategoriesWrapper,
  PostDescription,
  PostTitle,
  ListingPostPreviewInfoWrapper,
  ReadMore,
  ListingCardWrapper,
} from "./styled"
import { Post } from "./types"
import { formatDate } from "./utils"

interface PostCardProps {
  post: Post
  isListing: boolean
}

const ListingPostCard = ({ post, isListing }: PostCardProps) => {
  const { date, categories, title, subtitle, image, url } = post
  return (
    <ListingCardWrapper
      key={title}
      href={`${process.env.GATSBY_BASE_URL}${url}`}
    >
      <PostPreviewImage
        image={image}
        isListing={isListing}
        alt=""
        loading="lazy"
      />

      <ListingPostPreviewInfoWrapper>
        <DateCategoriesWrapper>
          <Date>{formatDate(date)}</Date>
          {categories.map(
            category =>
              !!category && <Category key={category}>{category}</Category>
          )}
        </DateCategoriesWrapper>
        <PostTitle>{title}</PostTitle>
        <PostDescription>{subtitle}</PostDescription>
      </ListingPostPreviewInfoWrapper>
    </ListingCardWrapper>
  )
}

export default ListingPostCard
