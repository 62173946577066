import React from "react"
import Layout from "../components/Layout"
import { MedicationPage } from "../components/MedicationPage"

export default function DynamicPage() {
  return (
    <Layout>
      <MedicationPage />
    </Layout>
  )
}
