import Gina from "./assets/gina.png"
import Nancy from "./assets/nancy.png"
import Nick from "./assets/nick.png"

export const quotes = [
  {
    quote:
      "Living in a small town in New England, it's impossible to find good mental healthcare.\n\nLocal options for mental health simply don't exist and I'd be lost without Cerebral.",
    name: "Nancy",
    image: Nancy,
  },
  {
    quote:
      "I fully believe Cerebral saved my life and marriage.\n\nI battled bipolar disorder for years. They tailored a medication regimen that has helped tremendously.",
    name: "Nick",
    image: Nick,
  },
  {
    quote:
      "My thoughts and feelings are never irrelevant with my therapist, regardless how irrational they seem.\n\nIt feels good to have such a great person in my life.",
    name: "Gina",
    image: Gina,
  },
]
