import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { GlobalsResponse } from "@cerebral-inc/api"
import type { RootState } from "./index"

interface GlobalData {
  globalsResponse: GlobalsResponse
}

const initialState: GlobalData = {
  globalsResponse: {
    "chatting-available": false,
    "regions-available-for-insurance": [],
    "regions-available-for-therapists": [],
    api_version: "",
    max_coordinator_cap: "",
    promo_notification_variable: "",
    terms_and_conditions_version: "",
  },
}

export const globalDataSlice = createSlice({
  name: "globalData",
  initialState,
  reducers: {
    setGlobalResponse: (
      state,
      { payload }: PayloadAction<{ response: GlobalsResponse }>
    ) => {
      state.globalsResponse = payload.response
    },
  },
})

export const { setGlobalResponse } = globalDataSlice.actions

export default globalDataSlice.reducer
