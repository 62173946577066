import { H1 } from "../../../../ui"
import { FaqCategoryLinks } from "../../../shared/FaqCategoryLinks"
import { Container } from "./styled"
import { Subheading } from "../../../shared/styled"

export const FrequentlyAskedQuestions = () => {
  return (
    <Container>
      <H1 style={{ textAlign: "center" }}>Frequently Asked Questions</H1>
      <Subheading>
        You can find information on common Cerebral topics below. If you are a
        Cerebral client, you may also{" "}
        <a href={process.env.GATSBY_LOGIN_URL}>log into your account</a> and
        navigate to <strong>Support</strong> or message your care team for
        assistance.
      </Subheading>
      <FaqCategoryLinks />
    </Container>
  )
}
