import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"
import { FlexWrapper } from "./LandingPageStyles"
import { InsuranceCarrierSelectV2 } from "../../components/shared/InsuranceWidget/InsuranceCarrierSelectV2"

const Wrapper = styled(FlexWrapper)`
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  padding-top: 64px;

  @media (max-width: 992px) {
    padding: 48px 24px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 992px;

  @media (max-width: 992px) {
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    align-items: start;
    margin-bottom: 48px;
  }

  @media (max-width: 500px) {
    align-items: start;
  }
`

const RightSection = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;

  @media (max-width: 992px) {
    display: none;
  }
`

const Title = styled.div`
  color: #7d70d2;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  margin-top: 8px;

  strong {
    color: #51459e;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
  }

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 30px;

    strong {
      font-size: 28px;
      line-height: 30px;
    }
  }
`

const Description = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 18px;
  line-height: 24px;
  max-width: 489px;
  margin-top: 16px;

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const InsuranceSelectionWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 24px;

  @media (max-width: 992px) {
    margin: 0 auto;
    margin-top: 24px;
    width: 480px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`

const SupportingText = styled.div`
  color: #4d515a;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  margin-top: 24px;
`

const JoinWaitlist = styled.a`
  color: #385ade;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
  text-align: center;
  margin-top: 4px;
`

const Logo = styled.img`
  width: 115px;
`

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

interface InsuranceHeroInterface {
  slice: {
    primary: {
      hero_title: {
        html: string
      }
      hero_description: {
        text: string
      }
      main_image: { gatsbyImageData: IGatsbyImageData }
      logo: { gatsbyImageData: IGatsbyImageData }
    }
  }
}

const InsuranceHero = ({ slice }: InsuranceHeroInterface) => {
  const { hero_title, hero_description, main_image, logo } = slice.primary

  const [previewImage, setPreviewImage] = useState(
    getImage(main_image)?.placeholder?.fallback
  )
  const previewImageElement = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (previewImageElement.current?.complete) {
      setPreviewImage(getImage(main_image)?.images?.fallback?.src)
    }
  }, [previewImageElement])

  return (
    <Wrapper>
      <Grid>
        <LeftSection>
          <Logo
            src={getImage(logo)?.images?.fallback?.src}
            alt="logo"
            loading="lazy"
          />
          <Title dangerouslySetInnerHTML={{ __html: hero_title.html }} />
          <Description>{hero_description.text}</Description>
          <InsuranceSelectionWrapper>
            <InsuranceCarrierSelectV2 direction="vertical" />
            <SupportingText>
              Don’t see your state or insurance carrier?
            </SupportingText>
            <JoinWaitlist href="https://forms.cerebral.com/to/KV3bSpek?typeform-source=cerebral.com">
              Join waitlist
            </JoinWaitlist>
          </InsuranceSelectionWrapper>
        </LeftSection>
        <RightSection>
          <ImageWrapper>
            <MainImage
              src={previewImage}
              alt="logo"
              loading="lazy"
              ref={previewImageElement}
            />
          </ImageWrapper>
        </RightSection>
      </Grid>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageInsuranceHeroSlice on PrismicPageDataBodyInsuranceHero {
    id
    slice_type
    primary {
      hero_description {
        text
      }
      hero_title {
        html
      }
      logo {
        gatsbyImageData(placeholder: BLURRED)
      }
      main_image {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

export default InsuranceHero
