import { useStaticQuery, graphql } from "gatsby"
import { Container, InnerContainer, QuestionWrapper } from "./styled"
import { PrismicRichText } from "@prismicio/react"
import { RichTextField } from "@prismicio/client/*"

const THERAPY_QUESTIONS = graphql`
  query MyQuery {
    allPrismicTherapyQuestions {
      edges {
        node {
          data {
            therapy_qa {
              answer {
                richText
              }
              question {
                richText
              }
            }
          }
        }
      }
    }
  }
`

export const TherapyQuestions = (): JSX.Element => {
  const response = useStaticQuery(THERAPY_QUESTIONS)
  const questions =
    response.allPrismicTherapyQuestions.edges[0].node.data.therapy_qa

  return (
    <Container>
      <InnerContainer>
        {questions.map(
          (
            q: {
              question: { richText: RichTextField }
              answer: { richText: RichTextField }
            },
            index: number
          ) => {
            return (
              <QuestionWrapper key={index}>
                <PrismicRichText field={q.question.richText} />
                <PrismicRichText field={q.answer.richText} />
              </QuestionWrapper>
            )
          }
        )}
      </InnerContainer>
    </Container>
  )
}
