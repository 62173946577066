import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  text-align: center;
  padding: 64px 64px;
  margin: 0px 0px 64px;
  background: transparent;
  & > img {
    padding: 8px;
  }
  @media (max-width: 920px) {
    border-width: 0;
    padding: 40px 0 0;
    width: auto;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    & > h2 {
      margin-top: 2em;
    }
  }
`
export const Container = styled.div`
  background: linear-gradient(0deg, ${colors.c_white[500]} 95%, #f3f5ff 99%);
  @media (max-width: 834px) {
    background: ${colors.c_white[500]};
  }
`
