import { colors } from "../../themes/colors"
import styled from "styled-components"

export const GoodDaysWrapper = styled.section`
  display: flex;
  padding: 64px 24px;
  flex-direction: column;
  align-items: center;
  background: ${colors.c_secondary[100]};
`
export const Header = styled.h2`
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-size: 40px;
  max-width: 1024px;
  line-height: 50px;

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 35px;
  }
`
export const Text = styled.p`
  text-align: center;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    margin-top: 8px;
  }
`
export const ButtonWrapper = styled.div`
  max-width: 320px;
  width: 100%;
  height: 56px;
  margin-top: 48px;
`

export const IconOptionsWrapper = styled.div`
  display: flex;
  gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const IconOptionCard = styled.div`
  width: 219px;
  font-size: 22px;
  font-family: Poppins;
  text-align: center;
`

export const IconWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  color: ${colors.c_white[900]};
  background-color: ${colors.c_secondary[500]};
  margin: 16px auto;

  & > * {
    width: 20px;
    height: 20px;
  }
`

export const IconOptionText = styled.h3`
  color: ${colors.c_black[900]}
  line-height: 24px;
  text-wrap: nowrap;
  gap: 32px;
`
