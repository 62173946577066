import { FaqTopics } from "../../../../FaqPages/shared"

export const TherapistsPopularTopics = () => {
  return (
    <FaqTopics
      modifiedHeader={true}
      title="Learn more about therapy"
      labels={["therapy_faq"]}
    />
  )
}
