import { H2 } from "../../../../ui"
import { Container, Answer, Faq } from "./styled"
import { FaqResult } from "../../../../../types/ZendeskApi"
import { convertToKebabCase } from "../../../shared/utils"

export const Faqs = ({ faqs }: { faqs: FaqResult[] | undefined }) => {
  return (
    <Container>
      {faqs?.map(faq => (
        <Faq id={convertToKebabCase(faq.name).slice(0, 50)} key={faq.id}>
          <H2>{faq.name}</H2>
          <Answer
            dangerouslySetInnerHTML={{
              __html: faq.body,
            }}
          />
        </Faq>
      ))}
    </Container>
  )
}
