import { useState } from "react"
import { SeoCopy, ReadMoreCopy } from "./styled"

export const BottomSEOCopySection = ({
  html,
  hasCtaSection,
}: {
  html?: string
  hasCtaSection: boolean
}) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false)
  if (!html) return null
  const maxCharLength = hasCtaSection ? 545 : 1200
  return (
    <div>
      <SeoCopy
        isTextExpanded={isTextExpanded}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      {html.length > maxCharLength && (
        <ReadMoreCopy onClick={() => setIsTextExpanded(!isTextExpanded)}>
          Read {isTextExpanded ? "less" : "more"}
        </ReadMoreCopy>
      )}
    </div>
  )
}
