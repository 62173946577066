import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const Header = styled.h1`
  color: ${colors.c_primary[500]};
  font-family: Poppins;
  font-size: 48.78px;
  font-weight: 600;
  line-height: 60.98px;
  text-align: left;

  b {
    font-weight: 800;
    color: ${colors.c_primary[600]};
  }

  @media (max-width: 834px) {
    font-size: 32px;
    line-height: 40px;
  }
`
export const YouImage = styled.img`
  margin-bottom: -15px;
  @media (max-width: 834px) {
    width: 75px;
    height: 42px;
    margin-bottom: -10px;
  }
`
