import React from "react"
import { ReactNode, useCallback, useMemo, useState } from "react"
import Modal from "../../components/shared/Modal"
import usePortal from "../../hooks/usePortal"
import {
  ModalContext,
  ModalContextProps,
  ModalOptions,
  ModalState,
} from "./ModalContext"

const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState(ModalState.CLOSED)
  const [content, setContent] = useState<ReactNode>(null)
  const [options, setOptions] = useState<ModalOptions>({})
  const ModalPortal = usePortal(Modal, "body")

  const changeState = useCallback(
    (newState: ModalState) => {
      setState(newState)
      if (newState === ModalState.CLOSED) {
        options.onClose?.()
        setContent(null)
      }
    },
    [options]
  )

  const value = useMemo<ModalContextProps>(
    () => ({
      setModalState: changeState,
      setModalContent(newContent, newOptions) {
        setContent(newContent)
        setOptions(newOptions || {})
      },
      modalState: state,
      content,
      options,
    }),
    [state, content, options]
  )

  return (
    <ModalContext.Provider value={value}>
      <ModalPortal />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
