import styled from "styled-components"
import UsdCircle from "./images/u_usd_circle.webp"

const Wrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 0px 12px 0px rgba(28, 30, 33, 0.16);
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 12px 16px;
  background: #f2f5ff;
  border-radius: 16px 16px 0 0;
`

const Icon = styled.img`
  width: 16px;
  height: 16px;
`

const HeaderTitle = styled.p`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`

const BodyWrapper = styled.div`
  padding: 24px;
  border-radius: 0 0 16px 16px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 336px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleAndPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 36px;

  @media (max-width: 1040px) {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    width: 100%;
  }
`

const BodyTitle = styled.div`
  color: #1c1e21;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  max-width: 236px;
`

const Price = styled.div`
  color: #51459e;
  text-align: right;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
`

const Button = styled.button`
  border-radius: 100px;
  background: #51459e;
  height: 56px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  box-shadow: none;
  justify-content: center;
  border: 2px solid #51459e;

  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
  margin-top: 40px;

  &:hover {
    cursor: pointer;
    background: #422e80;
    border: 2px solid #422e80;
  }

  @media (max-width: 1040px) {
    margin-top: 32px;
  }
`

const SupportingText = styled.p`
  color: #4d515a;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
  max-width: 408px;
`

interface InsuranceCardProps {
  icon?: string
  headerText?: string
  bodyTitle?: string
  price?: string
  supportingText?: string
}

export const InsuranceCard = ({
  bodyTitle,
  headerText,
  price,
  supportingText,
  icon,
}: InsuranceCardProps) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Icon src={icon || UsdCircle} alt="usd" />
        <HeaderTitle>
          {headerText || "Your copay could be as low as $0"}
        </HeaderTitle>
      </HeaderWrapper>
      <BodyWrapper>
        <TitleAndPriceWrapper>
          <BodyTitle>
            {bodyTitle || "In-network Blue Cross Blue Shield membership access"}
          </BodyTitle>
          <Price>
            {price || "In-network Blue Cross Blue Shield membership access"}
          </Price>
        </TitleAndPriceWrapper>
        <Button
          onClick={() => window?.scrollTo({ top: 0, behavior: "smooth" })}
        >
          Check my coverage
        </Button>
        <SupportingText>
          {supportingText ||
            "*Coverage and reimbursement can vary based on plan types. Contact your insurance provider to confirm exact coverage."}
        </SupportingText>
      </BodyWrapper>
    </Wrapper>
  )
}
