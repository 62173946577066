import { FlattenSimpleInterpolation, css } from "styled-components"

export const lineHeights = {
  default: "24px",
  sm: "16px",
  lg: "32px",
}

export const typographyStyles = {
  default: css`
    font-size: 14px;
    line-height: ${lineHeights.default};
    font-weight: 400;
  `,
  s: css`
    font-size: 12px;
    line-height: ${lineHeights.sm};
    font-weight: 400;
  `,
  xs: css`
    font-size: 10px;
    line-height: ${lineHeights.sm};
    font-weight: 400;
  `,
  wide: css``,
}

export const headerStyles: Record<
  `h${1 | 2 | 3 | 4 | 5 | 6}`,
  FlattenSimpleInterpolation
> = {
  h1: css``,
  h2: css`
    font-size: 28px;
    font-weight: 600;
    line-height: ${lineHeights.lg};
  `,
  h3: css`
    font-size: 20px;
    font-weight: 600;
    line-height: ${lineHeights.default};
  `,
  h4: css`
    font-size: 18px;
    font-weight: 600;
    line-height: ${lineHeights.default};
  `,
  h5: css`
    font-size: 16px;
    font-weight: 600;
    line-height: ${lineHeights.default};
  `,
  h6: css`
    font-size: 12px;
    font-weight: 600;
    line-height: ${lineHeights.sm};
  `,
}
