import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const WrapperGradient = styled.div`
  background: linear-gradient(135deg, #eceffe 0%, #fef9ec 100%);
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 24px 0;
  @media (max-width: 600px) {
    padding-top: 24px;
  }
`

export const Card = styled.div`
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background: ${colors.c_white[900]};
  gap: 32px;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    padding: 32px 24px;
    gap: 24px;
  }
`

export const PlantImage = styled.img`
  max-width: 554px;
  height: auto;
  width: 100%;
`
