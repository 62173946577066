import { FaqResult } from "../../../types/ZendeskApi"
export const schemaMarkup = (faqs: FaqResult[]) => {
  const seoFaqs = faqs.map(faq => ({
    "@type": "Question",
    name: faq.name,
    acceptedAnswer: {
      "@type": "Answer",
      text: faq.body,
    },
  }))
  return {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    mainEntity: seoFaqs,
  }
}
