import { UilTimes } from "@iconscout/react-unicons"
import { useState } from "react"
import { ModalState, useModalContext } from "../../../../contexts/ModalContext"
import { useWindowSize } from "../../../hooks"
import Spinner from "../../../ui/Spinner"
import { PageProvider, usePageContext } from "../PageContext"
import {
  IconContainer,
  Image,
  ImageContainer,
  ImagePlaceholder,
  ModalContainer,
} from "../styled"
import CouplesMobileImage from "./assets/couples-mobile.svg"
import CouplesImage from "./assets/couples.webp"

const InnerJoinCouplesTherayWaitlist = () => {
  const { Component } = usePageContext()
  const [loaded, setLoaded] = useState(false)
  const { isMobile } = useWindowSize()
  const { setModalState } = useModalContext()

  return (
    <ModalContainer>
      <IconContainer onClick={() => setModalState(ModalState.CLOSED)}>
        <UilTimes />
      </IconContainer>
      <ImageContainer>
        <ImagePlaceholder loaded={loaded}>
          <Spinner className="" />
        </ImagePlaceholder>
        <Image
          src={isMobile ? CouplesMobileImage : CouplesImage}
          alt=""
          onLoad={() => setLoaded(true)}
          loaded={loaded}
        />
      </ImageContainer>
      <Component />
    </ModalContainer>
  )
}

const JoinCouplesTherayWaitlist = () => {
  return (
    <PageProvider>
      <InnerJoinCouplesTherayWaitlist />
    </PageProvider>
  )
}

export default JoinCouplesTherayWaitlist
