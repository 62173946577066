import ladyonphonesvg from "./assets/lady-on-phone.svg"

export const contents = {
  body: "Cerebral therapists come from a range of backgrounds and have different approaches to treatment. You can find someone who matches your preferences, such as ethnicity, gender, specialties such as LGBTQIA+ and more.",
  images: [
    {
      srcSet: ladyonphonesvg,
      height: "300",
      width: "300",
      name: "lady-on-phone-desktop",
      media: "(min-width:835px)",
    },
    {
      srcSet: ladyonphonesvg,
      height: "130",
      width: "130",
      name: "lady-on-phone-mobile",
      media: "(max-width:600px)",
    },
  ],
}
