import DesktopAnxiety from "./images/anxiety.webp"
import MobileAnxiety from "./images/mobile-anxiety.webp"
import DesktopDepression from "./images/depression.webp"
import MobileDepression from "./images/mobile-depression.webp"
import DesktopBipolar from "./images/bipolar.webp"
import MobileBipolar from "./images/mobile-bipolar.webp"
import DesktopCouplesTherapy from "./images/couplethrapy.webp"
import MobileCouplesTherapy from "./images/mobile-couplethrapy.webp"
import { IQuestion } from "./types"
import { capitalizeFirstLetter } from "../utils"
import { COUPLE_THERAPY_QUESTIONS } from "./master-data"
interface ScreenerInterface {
  desktop: string
  mobile: string
  questions?: IQuestion[]
  showSupportText?: boolean
  extendWidth?: boolean
}

interface ScreenerImagesInterface {
  anxiety: ScreenerInterface
  depression: ScreenerInterface
  bipolar: ScreenerInterface
  couplestherapy: ScreenerInterface
}

export const screenerImages: ScreenerImagesInterface = {
  anxiety: {
    desktop: DesktopAnxiety,
    mobile: MobileAnxiety,
  },
  depression: {
    desktop: DesktopDepression,
    mobile: MobileDepression,
  },
  bipolar: {
    desktop: DesktopBipolar,
    mobile: MobileBipolar,
  },
  couplestherapy: {
    desktop: DesktopCouplesTherapy,
    mobile: MobileCouplesTherapy,
    showSupportText: false,
    extendWidth: true,
    questions: COUPLE_THERAPY_QUESTIONS,
  },
}

export const questionIndexKey = (serviceLine: string) => {
  return `widget-question-${serviceLine}-index`
}

export const answersStorageKey = (serviceLine: string) => {
  return `widget${capitalizeFirstLetter(serviceLine)}AssessmentResult`
}

export const segmentIntegrations = {
  integrations: {
    All: false,
    Heap: true,
  },
}
