import React from "react"
import { ScrollWrap, ScrollAnimation, ScrollBase } from "../styled"

const Scroll = React.forwardRef<HTMLDivElement>((_, ref) => (
  <ScrollWrap>
    <ScrollAnimation ref={ref} />
    <ScrollBase />
  </ScrollWrap>
))

export default Scroll
