import { navigate } from "gatsby"
import { Container, TopicLink, TopicsContainer, Column } from "./styled"
import { convertToKebabCase } from "../../FaqPages/shared/utils"
import { H2, H3 } from "../../ui"
import segment, { segmentEvents } from "../../../lib/segment"
import { useFaqs, useSectionNames } from "../../../hooks/useZendeskApi"
import { FaqResult, SectionNames } from "../../../types/ZendeskApi"

export const FaqTopics = ({
  title,
  titleType = "string",
  labels,
  sectionId,
  modifiedHeader,
}: {
  title?: string
  titleType?: "string" | "number"
  labels?: string[]
  sectionId?: number
  modifiedHeader?: boolean
}) => {
  const { faqs } = useFaqs({ labels, sectionId })
  const sectionIds = faqs?.results.map(faq => faq.section_id)
  const sectionNames: SectionNames = useSectionNames(sectionIds)
  if (!faqs || faqs.count === 0) return null

  const middle = Math.ceil(faqs.count / 2)
  const columns = [faqs.results.slice(0, middle), faqs.results.slice(middle)]

  const handleTopicClick = (topic: FaqResult) => {
    const slug = convertToKebabCase(topic.name).slice(0, 50)
    const sectionName = convertToKebabCase(sectionNames[topic.section_id] || "")
    segment.track(
      segmentEvents.FAQ_CLICKED,
      {
        question: `${sectionName} - ${slug}`,
      },
      {
        integrations: {
          All: false,
          Iterable: true,
          Heap: true,
        },
      }
    )

    navigate(`/faqs/${sectionName}#${slug}`)
  }

  let header = title
  if (titleType === "number") {
    header = `${faqs.count} topics`
  }

  return (
    <Container modifiedHeader={modifiedHeader}>
      {modifiedHeader ? <H3>{header}</H3> : <H2>{header}</H2>}
      <TopicsContainer>
        {columns?.map((column, index) => (
          <Column key={`column${index}`}>
            {column.map(topic => {
              return (
                <TopicLink
                  key={topic.id}
                  onClick={() => handleTopicClick(topic)}
                >
                  {topic.name}
                </TopicLink>
              )
            })}
          </Column>
        ))}
      </TopicsContainer>
    </Container>
  )
}
