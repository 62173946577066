import { graphql, useStaticQuery } from "gatsby"
import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { TherapyPageWrapper } from "./styled"
import { HeroSection } from "../shared/HeroSection"
import HeaderImage from "./images/therapy-header-image.webp"
import {
  ChooseService,
  GettingStarted,
  SuccessTestimonials,
  FAQSection,
  AlternatingSplashes,
  EndCap,
} from "../shared"
import { alternatingSplashCopy, chooseServicePlans } from "./constants"
import { organizationSchemaMarkup } from "../../common/OrganizationSeoSchema"
import { useSignupUrl } from "../../hooks/useSignupUrl"

const THERAPY_PAGE_QUERY = graphql`
  query TherapyPageQuery {
    allPrismicTestimonialSection(filter: { tags: { eq: "therapy_quote" } }) {
      edges {
        node {
          data {
            testimonial_section {
              name
              quote
            }
          }
        }
      }
    }
  }
`

export const TherapyPageBody = (): JSX.Element => {
  const response = useStaticQuery(THERAPY_PAGE_QUERY)

  const quotes =
    response.allPrismicTestimonialSection.edges[0].node.data.testimonial_section
  return (
    <TherapyPageWrapper>
      <HeroSection
        title="Therapy"
        description="Meet 1-on-1 with a qualified, compassionate therapist to learn lifelong skills for managing your mental health"
        image={HeaderImage}
        buttons={[
          {
            text: "Start therapy",
            link: useSignupUrl(),
          },
        ]}
      />
      <AlternatingSplashes copy={alternatingSplashCopy} />
      <ChooseService plansInfo={chooseServicePlans} />
      <GettingStarted description="Most clients are able to meet with a therapist within 3 days of signing up" />
      <SuccessTestimonials quotes={quotes} />
      <FAQSection labels={["therapy_faq"]} />
      <EndCap title="Start your care journey" isMedsOrTherapy={true} />
    </TherapyPageWrapper>
  )
}

export const TherapyPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="Explore Cerebral Individual Therapy Plans"
        titleSuffix="Cerebral"
        description="Cerebral experts can treat anxiety, depression, insomnia, and more. Learn about the care you can get today."
        url="/therapy"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <TherapyPageBody />
    </>
  )
}
