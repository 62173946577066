const PAGE_URL_PATTERN = [
  {
    pattern: /^\/therapists\/[^\/]+\/depression$/,
    category: "Depression",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/anxiety$/,
    category: "Anxiety",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/relationship-challenges$/,
    category: "Couples Therapy",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/bipolar$/,
    category: "Bipolar Disorder",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/hyperactivity-disorder-(adhd)$/,
    category: "ADHD",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/self-esteem-and-confidence$/,
    category: "Self-care",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/coping-with-life-changes$/,
    category: "Therapy",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/grief-&-loss$/,
    category: "Therapy",
  },
  {
    pattern: /^\/therapists\/[^\/]+\/women's-health$/,
    category: "Women's Health",
  },
]

export const pathToBlogCategory = (path: string) => {
  return PAGE_URL_PATTERN.find(({ pattern }) => pattern?.test(path))?.category
}
