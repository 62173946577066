import SEO from "../SEO"
import { Navigation } from "../ui/Navigation"
import { TestimonialsBody, TestimonialsHeader } from "./styled"
import { FindYourPath } from "../shared/FindYourPath"
import { WhatOurMembersAreSaying, TestimonialsPageQuotes } from "./sections"
import { colors } from "../themes/colors"

interface TestimonialsPageBodyProps {}

export const TestimonialsPageBody =
  ({}: TestimonialsPageBodyProps): JSX.Element => (
    <TestimonialsBody>
      <WhatOurMembersAreSaying />
      <TestimonialsHeader>Testimonials</TestimonialsHeader>
      <TestimonialsPageQuotes />
      <FindYourPath backgroundColor={colors.c_secondary[50]} />
    </TestimonialsBody>
  )

export const TestimonialsPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="Testimonials | Cerebral"
        description="Cerebral offers online therapy, mental health assessments and expert care on your schedule. Includes clinician visits for anxiety, depression, insomnia and more."
        schemaMarkup={{}}
      />
      <Navigation />
      <TestimonialsPageBody />
    </>
  )
}
