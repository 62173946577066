import React from "react"
import Layout from "../components/Layout"
import { WelcomeBackPage } from "../components/WelcomeBackPage"

export default function DynamicPage() {
  return (
    <Layout showFooter={false}>
      <WelcomeBackPage />
    </Layout>
  )
}
