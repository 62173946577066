import { FaqTopics } from "../../../../shared"

export const MedicationFaqs = (): JSX.Element => {
  return (
    <FaqTopics
      modifiedHeader={true}
      title={"Learn more about our medication services"}
      labels={["medication_faq"]}
    />
  )
}
