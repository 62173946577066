import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { IGatsbyImageData, getImage, getSrcSet } from "gatsby-plugin-image"
import { useWindowSize } from "../../components/hooks"

const Wrapper = styled.div`
  background: var(
    --Gradient-Zen,
    linear-gradient(135deg, #fef9ec 0%, #eceffe 100%)
  );
`

const Content = styled.div`
  max-width: 992px;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding-top: 64px;
  font-family: Poppins;

  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

const LeftBox = styled.div`
  flex: 1;
`

const RightBox = styled.div`
  flex: 1;
  overflow: hidden;

  @media (max-width: 510px) {
    height: 423px;
    position: relative;
  }
`

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const Title = styled.div`
  margin-top: 83px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;

  @media (max-width: 992px) {
    margin: 32px auto;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    width: calc(100% - 48px);
  }
`

const Description = styled.div`
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 992px) {
    margin: 10px auto 32px;
    width: calc(100% - 48px);
    text-align: center;
  }
`

const Button = styled.button`
  margin-top: 32px;
  width: 240px;
  height: 56px;
  padding: 12px 24px;
  border-radius: 28px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  background: #51459e;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #51459e;
  cursor: pointer;
  &:hover {
    background: #422e80;
  }

  @media (max-width: 992px) {
    width: calc(100% - 48px);
    margin: 0px 24px 48px;
    padding: 0;
  }
`

const LeftBoxContent = styled.div`
  width: 418px;

  @media (max-width: 992px) {
    width: 100%;
  }
`

interface BannerWithTextInterface {
  slice: {
    slice_type: string
    primary: {
      banner_title: {
        text: string
      }
      button_text: string
      banner_image: IGatsbyImageData
      banner_mobile_image: IGatsbyImageData
      description: {
        text: string
      }
    }
  }
}

const BannerWithText = ({ slice }: BannerWithTextInterface) => {
  const { isMobile } = useWindowSize()
  const {
    banner_title,
    description,
    button_text,
    banner_image,
    banner_mobile_image,
  } = slice.primary
  const images = getImage(banner_image)
  const mobileImages = getImage(banner_mobile_image)
  const [mainImage, setMainImage] = useState(images)
  const srcSet = getSrcSet(mainImage as IGatsbyImageData)

  const jumpToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    if (isMobile && mobileImages) {
      setMainImage(mobileImages)
    } else {
      setMainImage(images)
    }
  }, [isMobile])

  return (
    <Wrapper>
      <Content>
        <LeftBox>
          <LeftBoxContent>
            <Title>{banner_title.text}</Title>
            <Description>{description.text}</Description>
            <Button onClick={jumpToTop}>{button_text}</Button>
          </LeftBoxContent>
        </LeftBox>
        <RightBox>
          <Image srcSet={srcSet} loading="lazy" alt="" />
        </RightBox>
      </Content>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageBannerWithTextSlice on PrismicPageDataBodyBannerWithText {
    id
    primary {
      banner_title {
        text
      }
      button_text
      description {
        text
      }
      banner_image {
        gatsbyImageData
      }
      banner_mobile_image {
        gatsbyImageData
      }
    }
    slice_label
    slice_type
  }
`

export default BannerWithText
