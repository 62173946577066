import { graphql, useStaticQuery } from "gatsby"
import { Navigation } from "../ui/Navigation"
import {
  BottomSEOCopySectionOuterWrapper,
  BottomSeoSectionWrapper,
  LocationBody,
  PlantContainer,
} from "./styled"
import { LocationPageProvider } from "./LocationPageProvider"
import { LocationPageRawData, LocationPageSEOData } from "./types"
import plantSvg from "../../images/plant.svg"
import {
  Header,
  CarePersonsList,
  CommonlySearchFor,
  FindingRightTherapist,
} from "./sections"
import SEO from "../../components/SEO"
import { TopSEOCopySection } from "./sections/TopSEOCopySection"
import { BottomSEOCopySection } from "./sections/BottomSEOCopySection"
import { CtaSEOCopySection } from "./sections/CtaSEOCopySection/CtaSEOCopySection"
import { TherapyQuestions } from "./sections/TherapyQuestions"
import { InsuranceBanner } from "./sections/InsuranceBanner"
import LatestPost from "../shared/Blog/LatestPost"
import { edgeToPostPreview } from "../../lib/utils"
import { pathToBlogCategory } from "./utils"

interface LocationPageBodyProps {
  locationsData: LocationPageRawData
  seoCopySection: LocationPageSEOData
}

const POST_LOCATION_PAGE_QUERY = graphql`
  query PostLocationPageQuery {
    allPrismicPost(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          data {
            title {
              text
            }
            date(formatString: "MM/DD/YYYY")
            previewimage {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, width: 1216)
            }
            categories {
              category
            }
            content {
              text
            }
            body {
              ... on PrismicPostDataBodyBlogText {
                primary {
                  text {
                    text
                  }
                }
              }
            }
          }
          uid
        }
      }
    }
  }
`

export const LocationPageBody = ({
  locationsData,
  seoCopySection,
}: LocationPageBodyProps): JSX.Element => {
  const {
    allPrismicPost: { edges },
  } = useStaticQuery(POST_LOCATION_PAGE_QUERY)

  const posts = edges.map((edge: any) => edgeToPostPreview({ edge }))
  const hasCTASection = !!seoCopySection?.include_cta_section
  const category = pathToBlogCategory(locationsData.url)

  const getPostsByCategory = () => {
    if (category) {
      return posts
        .filter((post: { categories: string[] }) =>
          post.categories.includes(category)
        )
        .slice(0, 3)
    }

    return posts.slice(0, 3)
  }

  return (
    <LocationBody>
      <LocationPageProvider locationPageData={locationsData}>
        {locationsData.carePersons && <Header />}
        <TopSEOCopySection html={seoCopySection?.top_section_text?.html} />
        <InsuranceBanner />
        {locationsData.carePersons && <CarePersonsList />}
        <LatestPost posts={getPostsByCategory()} filteredCategory={category} />
        <FindingRightTherapist />
        {locationsData.carePersonType === "therapist" && <TherapyQuestions />}
        <BottomSEOCopySectionOuterWrapper>
          {seoCopySection?.bottom_section_text?.html && (
            <BottomSeoSectionWrapper>
              <BottomSEOCopySection
                html={seoCopySection.bottom_section_text.html}
                hasCtaSection={hasCTASection}
              />
              {hasCTASection && (
                <CtaSEOCopySection
                  html={seoCopySection.cta_section_copy?.html}
                  leftCta={{
                    link: seoCopySection.left_cta_link?.url,
                    text: seoCopySection.left_cta_copy,
                  }}
                  rightCta={{
                    link: seoCopySection.right_cta_link?.url,
                    text: seoCopySection.right_cta_copy,
                  }}
                />
              )}
            </BottomSeoSectionWrapper>
          )}
        </BottomSEOCopySectionOuterWrapper>
        {locationsData.associated_locations && <CommonlySearchFor />}
        <PlantContainer>
          <img alt="" src={plantSvg} height={187} width={107} loading="lazy" />
        </PlantContainer>
      </LocationPageProvider>
    </LocationBody>
  )
}

interface LocationPageProps {
  locationDetails: LocationPageRawData
  seoCopySection: LocationPageSEOData
}
export const LocationPage = ({
  locationDetails,
  seoCopySection,
}: LocationPageProps): JSX.Element => {
  const { city_name, state_name, specialty, carePersonType } = locationDetails
  const totalCarePersons = locationDetails.carePersons.length

  const location = city_name ? `${city_name}, ${state_name}` : state_name
  const specialtyCopy = specialty ? `specializing in ${specialty}` : ""
  const title =
    carePersonType === "therapist"
      ? `View ${totalCarePersons} licensed ${carePersonType}s available in ${location} ${specialtyCopy}`
      : `View ${totalCarePersons} ${carePersonType}s licensed in ${location} ${specialtyCopy}`

  return (
    <>
      <SEO
        title={title}
        description={`Discover the best ${carePersonType}s in ${location} ${specialtyCopy} and book your appointment with Cerebral. Cerebral offers a wide variety of ${carePersonType}s that can accommodate anyone's needs. Your ideal ${carePersonType} is just a click away. Start your journey to personal growth and mental wellness today.`}
      />
      <Navigation />
      <LocationPageBody
        locationsData={locationDetails}
        seoCopySection={seoCopySection}
      />
    </>
  )
}

export default LocationPage
