import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  background: var(
    --Gradient-Zen,
    linear-gradient(135deg, #fef9ec 0%, #eceffe 100%)
  );
  font-family: Poppins;
`

const Content = styled.div`
  display: flex;
  padding: 64px 0px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: var(--base-S5, 40px);

  @media (max-width: 800px) {
    padding: 48px 24px;
    gap: 32px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`
const Title = styled.div`
  color: var(--bn-w-black-900, #1c1e21);
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 28px;
  }
`
const Description = styled.div`
  color: var(--Grey-900, #353a41);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`
const BenefitsWrapper = styled.div`
  display: flex;
  max-width: 992px;
  margin: auto;
  justify-content: center;
  gap: var(--base-s-25, 20px);

  @media (max-width: 1120px) {
    flex-direction: column;
    gap: var(--base-s-15, 12px);
  }
`

const ItemWrapper = styled.div`
  display: flex;
  padding: var(--base-S3, 24px);
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: var(--base-S2, 16px);
  border: 1.5px solid var(--bn-w-white-900, #fff);
  background: var(--bn-w-white-900, #fff);

  @media (max-width: 800px) {
    gap: 8px;
  }
`
const Image = styled(GatsbyImage)``
const ItemTitle = styled.div`
  color: var(--Grey-900, #353a41);
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 800px) {
    font-size: 20px;
  }
`
const ItemContent = styled.div`
  color: var(--Grey-800, #4d515a);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const Button = styled.button`
  width: 320px;
  height: 56px;
  padding: 12px 24px;
  border-radius: 28px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  background: #51459e;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #51459e;
  cursor: pointer;
  &:hover {
    background: #422e80;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`

interface OffersListInterface {
  slice: {
    slice_type: string
    primary: {
      main_title: { text: string }
      main_description: { text: string }
    }
    items: {
      offer_description: { text: string }
      offer_title: { text: string }
      offer_image: IGatsbyImageData
    }[]
  }
}

const OffersList = ({ slice }: OffersListInterface) => {
  const {
    primary: { main_title, main_description },
    items,
  } = slice

  const jumpToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Wrapper>
      <Content>
        <TitleWrapper>
          <Title>{main_title.text}</Title>
          <Description>{main_description.text}</Description>
        </TitleWrapper>
        <BenefitsWrapper>
          {items.map((item, index) => (
            <ItemWrapper>
              <Image
                image={getImage(item.offer_image) as IGatsbyImageData}
                loading="lazy"
                alt=""
              />
              <ItemTitle>{item.offer_title.text}</ItemTitle>
              <ItemContent>{item.offer_description.text}</ItemContent>
            </ItemWrapper>
          ))}
        </BenefitsWrapper>
        <Button onClick={jumpToTop}>Check my coverage</Button>
      </Content>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageOffersListSlice on PrismicPageDataBodyOffersList {
    id
    items {
      offer_description {
        text
      }
      offer_image {
        gatsbyImageData
      }
      offer_title {
        text
      }
    }
    primary {
      main_description {
        text
      }
      main_title {
        text
      }
    }
    slice_label
    slice_type
  }
`

export default OffersList
