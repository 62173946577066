import styled from "styled-components"

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 64px;

  @media (max-width: 834px) {
    padding-top: 32px;
  }
`

export const BackgroundSquiggle = styled.img`
  position: absolute;
  bottom: -1px;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const Header = styled.h2`
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  max-width: 800px;

  @media (max-width: 834px) {
    font-size: 22px;
    line-height: 27.5px;
    text-align: left;
    padding: 0 24px;
  }
`
export const SafeWrapper = styled.img`
  margin-bottom: -12px;

  @media (max-width: 834px) {
    margin-bottom: -6px;
    height: 28px;
    width: 53px;
  }
`

export const JudgementWrapper = styled.img`
  margin-bottom: -12px;

  @media (max-width: 834px) {
    margin-bottom: -6px;
    height: 28px;
    width: 179px;
  }
`
export const ClinicianImage = styled.img<{ isHovered: boolean }>`
  max-width: 500px;
  width: 100%;
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    box-shadow: 8px 8px 0px 0px #545ca6;
  }
  box-shadow: ${({ isHovered }) =>
    isHovered ? "8px 8px 0px 0px #545ca6" : "none"};
`

export const ClinicianContainer = styled.div`
  position: relative;

  @media (max-width: 834px) {
    padding: 0 24px;
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  width: 320px;
  transform: translateX(-50%);
  padding: 24px 0;
  cursor: pointer;

  @media (max-width: 834px) {
    width: 100%;
    padding: 24px 48px;
  }
`

export const CliniciansWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 64px 0;
  position: relative;
  z-index: 0;
  width: 100%;
  overflow: hidden;
  align-items: center;

  @media (max-width: 834px) {
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 32px;
  }
`
