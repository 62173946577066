import { useEffect, useState } from "react"
import {
  AppCtasWrapper,
  AppImagesWrapper,
  DesktopContainer,
  MobileContainer,
  Image,
  Header,
  HeaderContainer,
  RightSide,
  Background,
  OnTheWayWrapper,
  ButtonWrapper,
} from "./styled"
import { GetStartedButton } from "../../../shared"
import App01 from "./assets/app-1.webp"
import App02 from "./assets/app-2.webp"
import App03 from "./assets/app-3.webp"
import App04 from "./assets/app-4.webp"
import App05 from "./assets/app-5.webp"
import appleAppCTA from "./assets/apple-app.svg"
import googleAppCTA from "./assets/google-play.svg"
import OnTheWay from "./assets/on-the-way.svg"
import BackgroundShapes from "./assets/background-shapes.svg"

const images = [App01, App02, App03, App04, App05]

const AppCtas = () => (
  <AppCtasWrapper>
    <a
      href="https://apps.apple.com/us/app/cerebral-mental-health/id1537193671"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={appleAppCTA} alt="Download on the App Store" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.cerebral.cerebral"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={googleAppCTA} alt="Available on the Google Play" />
    </a>
  </AppCtasWrapper>
)

export const AppImages = (): JSX.Element => {
  const [activeImage, setActiveImage] = useState(0)

  const nextImage = () => {
    setActiveImage(activeImage === images.length - 1 ? 0 : activeImage + 1)
  }

  useEffect(() => {
    const activeImageInterval = setInterval(() => {
      nextImage()
    }, 2000)

    return () => clearInterval(activeImageInterval)
  }, [activeImage])

  return (
    <AppImagesWrapper>
      <DesktopContainer>
        <Image src={images[activeImage]} alt="" />
        <RightSide>
          <HeaderContainer>
            <Header>
              More good days are{" "}
              <OnTheWayWrapper src={OnTheWay} alt="on the way" />
            </Header>
            <GetStartedButton />
          </HeaderContainer>
          <AppCtas />
        </RightSide>
      </DesktopContainer>
      <MobileContainer>
        <Header>
          More good days are <OnTheWayWrapper src={OnTheWay} alt="on the way" />
        </Header>
        <Image src={images[activeImage]} alt="" />
        <ButtonWrapper>
          <GetStartedButton />
        </ButtonWrapper>
        <AppCtas />
      </MobileContainer>
      <Background src={BackgroundShapes} alt="" />
    </AppImagesWrapper>
  )
}
