import styled, { css, FlattenSimpleInterpolation } from "styled-components"
import { ModalState } from "../../../contexts/ModalContext"

const overlayStyles: Record<ModalState, FlattenSimpleInterpolation> = {
  [ModalState.CLOSED]: css`
    opacity: 0;
    &,
    * {
      pointer-events: none !important;
    }
  `,
  [ModalState.OPEN]: css`
    opacity: 1;
  `,
}

const dialogStyles: Record<ModalState, FlattenSimpleInterpolation> = {
  [ModalState.CLOSED]: css`
    transform: translate(-50%, -50%) scale(1.1);
  `,
  [ModalState.OPEN]: css`
    transform: translate(-50%, -50%);
  `,
}

export const Overlay = styled.div<{
  state: ModalState
}>`
  position: fixed;
  overflow-y: hidden;
  inset: 0;
  background: rgba(98, 104, 116, 0.6);
  transition: opacity 0.3s;
  z-index: 1999;
  ${({ state }) => overlayStyles[state]};
`

export const ModalDialog = styled.div<{
  state: ModalState
  width?: "default" | "wide"
  isMaxHeight: boolean
  fullScreenOnMobile?: boolean
}>`
  overflow-y: hidden;
  border-radius: 20px;
  filter: drop-shadow(0px 5px 24px rgba(81, 69, 158, 0.2));
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100vw - 48px);
  max-width: ${({ width = "default" }) =>
    width === "wide" ? "824px" : "600px"};
  z-index: 2000;
  transition: transform 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  ${({ state }) => overlayStyles[state]};
  ${({ state }) => dialogStyles[state]};
  > div {
    width: 100%;
    height: 100%;
  }

  ${({ fullScreenOnMobile = true }) =>
    fullScreenOnMobile
      ? `@media (max-width: 834px) {
    height: calc(100% - 108px);
    width: 100%;
    border-radius: 0px;
    transform: none;
    top: auto;
    bottom: 0;
    left: 0;
  }`
      : ""}
`
