import { colors } from "../../../themes/colors"

const LeftArrow = () => {
  return (
    <svg
      aria-label="left-arrow icon"
      width="10px"
      height="10px"
      viewBox="0 0 7 8"
      fill={colors.c_primary[900]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.6875 3.91609L6.36242 7.1925L6.36242 0.639676L0.6875 3.91609Z" />
    </svg>
  )
}

export default LeftArrow
