import { ReactNode, createContext, useContext, useMemo, useState } from "react"
import AvailableLocation from "./AvailableLocation"
import CheckCouplesTherapyAvailabilityForm from "./CheckCouplesTherapyAvailabilityForm"
import JoinWaitlistForm from "./JoinWaitlistForm/JoinWaitlistForm"
import SuccessfulJoin from "./SuccessfulJoin/SuccessfulJoin"

export type Page =
  | "check-availability"
  | "location-available"
  | "join-waitlist"
  | "joined"

const pageComponentMap: Record<Page, () => JSX.Element | null> = {
  "check-availability": CheckCouplesTherapyAvailabilityForm,
  "location-available": AvailableLocation,
  "join-waitlist": JoinWaitlistForm,
  joined: SuccessfulJoin,
}

interface IPageContext {
  currentPage?: Page
  setCurrentPage: (page: Page, props?: Record<string, any>) => void
  props?: Record<string, any>
  Component: () => JSX.Element | null
}

const PageContext = createContext<IPageContext>({
  setCurrentPage: () => {},
  Component: () => null,
})

export const usePageContext = () => useContext(PageContext)

export const PageProvider = ({ children }: { children: ReactNode }) => {
  const [page, setPage] = useState<Page>("check-availability")
  const [props, setProps] = useState<Record<string, any>>()

  const Component = useMemo(() => pageComponentMap[page], [page])

  const setCurrentPage = (page: Page, props?: Record<string, any>) => {
    setPage(page)
    setProps(props)
  }

  const value = useMemo(
    () => ({
      currentPage: page,
      setCurrentPage,
      props,
      Component,
    }),
    [page, setPage, Component, props]
  )

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
