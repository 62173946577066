import React, { useEffect, useRef, useState } from "react"
import logoImage from "../../images/logo.svg"
import {
  Wrapper,
  Header,
  Logo,
  Body,
  Title,
  Subtitle,
  RedirectingText,
  Spinner,
  MainImage,
} from "./styled"
import ClinicianImage from "./images/clinician.webp"

interface NotFoundCareTeamScreenProps {
  isShow: boolean
}
export const NotFoundCareTeamScreen = ({
  isShow,
}: NotFoundCareTeamScreenProps) => {
  return (
    <Wrapper isShow={isShow}>
      <Header>
        <Logo src={logoImage} alt="Cerebral logo" />
      </Header>
      <Body>
        <MainImage src={ClinicianImage} alt="" loading="lazy" />
        <Title>Sorry, we couldn't find anything</Title>
        <Subtitle>Let's find an available clinician in your area.</Subtitle>
        <Spinner />
        <RedirectingText>Redirecting...</RedirectingText>
      </Body>
    </Wrapper>
  )
}
