import { H1 } from "../../../ui"
import styled from "styled-components"

export const Container = styled.div`
  text-align: center;
  max-width: 1200px;
  height: 904px;
  margin: 0 auto 80px;
  h1 {
    text-align: center;
  }
`

export const TestimonialsHeader = styled(H1)`
  margin-bottom: 68px;
`
