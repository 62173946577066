import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const CtaWrapper = styled.div`
  border-radius: 8px;
  background: ${colors.c_white[500]};
  box-shadow: 0px 0px 12px 0px rgba(28, 30, 33, 0.16);
  min-width: 404px;
  max-width: 404px;
  min-height: 208px;
  padding: 32px 24px;

  h4 {
    /* Heading/H4 */
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    margin-bottom: 8px;
  }

  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  a {
    max-width: none;
    box-shadow: none;
    border-width: 2px;
    flex: 1;
    span {
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }
`
