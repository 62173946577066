import { useField } from "formik"
import { ErrorMessage, Input, InputContainer, Label } from "./styled"

export const TextField = (props: {
  label: string
  name: string
  placeholder: string
}) => {
  const [field, meta] = useField(props.name)

  return (
    <InputContainer>
      <Label>{props.label}</Label>
      <Input data-testid={props.name} {...field} {...props} />
      {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </InputContainer>
  )
}
