import styled from "styled-components"
import { colors } from "../themes/colors"

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const HeaderText = styled.h1`
  color: ${colors.c_black[900]};
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`

export const BodyText = styled.p`
  color: ${colors.c_grey[900]};
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`

export const PriceText = styled.p`
  color: ${colors.c_grey[800]};
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`

export const EmphasizedTextPrice = styled.span`
  color: ${colors.c_primary[600]};
  font-weight: 600;
`

export const PriceDescription = styled.p`
  color: ${colors.c_grey[700]};
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

export const ContinueButton = styled.a`
  display: flex;
  max-width: 320px;
  width: 100%;
  height: 56px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: ${colors.c_primary[600]};
  color: ${colors.c_white[100]};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  cursor: pointer;
  text-decoration: none;
  :hover {
    background: ${colors.c_primary[700]};
  }
  :focus,
  :target {
    box-shadow: 0px 0px 0px 2px rgba(66, 46, 128, 0.24);
    outline: none;
  }
`

export const FooterText = styled.div`
  & > * {
    color: #353a41;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    line-height: 24px;

    strong {
      font-weight: 600;
    }
  }
`
