import { useState } from "react"
import {
  FAQAnswer,
  FAQBlock,
  FAQInnerWrapper,
  FAQQuestion,
  FAQSectionWrapper,
} from "./styled"
import Plus from "./assets/u_plus.svg"
import Minus from "./assets/u_minus.svg"
import InsuranceModals from "../../InsuranceModals"
import { useFaqs } from "../../../hooks/useZendeskApi"

interface IFAQSectionProps {
  title?: string
  id?: string
  noTitle?: boolean
  noPadding?: boolean
  margin?: string
  withInsuranceModal?: boolean
  labels: string[]
}

export const FAQSection = ({
  labels,
  title,
  id,
  noTitle = false,
  noPadding = false,
  margin = "",
  withInsuranceModal,
}: IFAQSectionProps) => {
  const [openedFAQ, setOpenedFAQ] = useState<number | null>(null)
  const { faqs } = useFaqs({ labels: labels || [] })
  const handleQuestionClick = (i: number) => {
    setOpenedFAQ(current => (current === i ? null : i))
  }

  return (
    <FAQSectionWrapper id={id} noPadding={noPadding} margin={margin || ""}>
      <FAQInnerWrapper>
        {!noTitle && <h2>{title || "Frequently asked questions"}</h2>}
        {faqs?.results.map((result, i) => {
          const question = result.title
          const answer = result.body
          return (
            <FAQBlock key={question}>
              <FAQQuestion
                onClick={() => handleQuestionClick(i)}
                id={`faq-${i}`}
              >
                <h3>{question}</h3>
                {i === openedFAQ ? (
                  <img src={Minus} alt="Close answer" />
                ) : (
                  <img src={Plus} alt="Open answer" />
                )}
              </FAQQuestion>
              <FAQAnswer
                isOpen={openedFAQ === i}
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            </FAQBlock>
          )
        })}
      </FAQInnerWrapper>
      {withInsuranceModal ? (
        <div style={{ margin: "64px auto 0", textAlign: "center" }}>
          <InsuranceModals cta_text="Check my coverage" disableCTALink />
        </div>
      ) : null}
    </FAQSectionWrapper>
  )
}
