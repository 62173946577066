import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  background: ${colors.c_secondary[200]};

  padding: 80px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  & > h2 {
    margin-bottom: 16px;
    font-size: 40px;
    font-weight: 600;
    line-height: 125%;

    @media (max-width: 768px) {
      font-size: 28px;
      font-weight: 600;
      line-height: 125%; /* 35px */
    }
  }
  & > p {
    max-width: 506px;
    margin-bottom: 32px;
    line-height: 24px; /* 150% */
    font-weight: 400;
  }
  @media (max-width: 768px) {
    padding: 72px 40px;
  }

  * {
    font-family: Poppins;
  }
`

export const SpecialtiesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  gap: 16px;
  justify-content: center;

  span {
    background: ${colors.c_white[100]};
    padding: 16px;
    border-radius: 24px;
    color: ${colors.c_primary[800]};
    font-weight: 600;
    line-height: 16px;

    img {
      vertical-align: bottom;
    }
  }
`
