import {
  Wrapper,
  TextContainer,
  Text,
  ArrowDown,
  LeftSide,
  RightSide,
  HeroImage,
  LearnMoreText,
  LearnMoreTextMobile,
  LearnMoreAnimation,
  LearnMoreWrapper,
  LearnMoreWrapperMobile,
  ButtonWrapper,
} from "./styled"
import { GetStartedButton } from "../GetStartedButton/GetStartedButton"
import { useWindowSize } from "../../hooks"
import ArrowDesktop from "./assets/u_arrow_down.svg"
import ArrowMobile from "./assets/arrow_down_mobile.svg"

export const BigHero = ({
  Title,
  image,
  learnMoreClicked,
  description,
}: {
  Title: React.ComponentType
  image: string
  learnMoreClicked: () => void
  description?: string
}) => {
  const { isMobile } = useWindowSize()
  return (
    <Wrapper>
      {isMobile && (
        <LearnMoreWrapperMobile onClick={learnMoreClicked}>
          <LearnMoreTextMobile>Learn more</LearnMoreTextMobile>
          <ArrowDown src={ArrowMobile} />
        </LearnMoreWrapperMobile>
      )}
      <LeftSide>
        <TextContainer>
          <Title />
          {description && <Text>{description}</Text>}
          <ButtonWrapper>
            <GetStartedButton />
          </ButtonWrapper>
        </TextContainer>
      </LeftSide>

      <RightSide>
        <HeroImage src={image} alt="pricing hero image" />
        {!isMobile && (
          <LearnMoreWrapper onClick={learnMoreClicked}>
            <LearnMoreAnimation>
              <LearnMoreText>Learn More</LearnMoreText>
              <ArrowDown src={ArrowDesktop} />
            </LearnMoreAnimation>
          </LearnMoreWrapper>
        )}
      </RightSide>
    </Wrapper>
  )
}
