import { Carousel, CarouselItem } from "../../ui"
import { useWindowSize } from "../../hooks"
import {
  QuotesContainer,
  QuotesContentContainer,
  Label,
  Name,
  Quote,
  TextContainer,
  InfoContainer,
  ProfilePicture,
  InnerContentContainer,
} from "./styled"
import PurpleStar from "./assets/purple-star.svg"

export interface Quote {
  quote: string
  name: string
  image: string
}

export interface IQuotesCarouselProps {
  quotes: Quote[]
}

export const FiveStars = () => {
  return (
    <div style={{ display: "flex" }}>
      {Array.from({ length: 5 }, (_, index) => (
        <img
          key={index}
          src={PurpleStar}
          alt="Star"
          height="16"
          style={{ margin: "0 2px" }}
        />
      ))}
    </div>
  )
}

export const QuotesCarousel = ({ quotes }: IQuotesCarouselProps) => {
  const { isMobile } = useWindowSize()

  return (
    <QuotesContainer>
      <Carousel
        indicatorStyles={{
          desktop: { x: "0", y: "32%" },
          mobile: { x: "32px", y: "70%" },
        }}
        startingIndex={1}
      >
        {quotes.map(({ quote, name, image }) => (
          <CarouselItem key={quote}>
            <QuotesContentContainer>
              {isMobile ? (
                <>
                  <Quote>{quote}</Quote>
                  <InfoContainer>
                    <Label>
                      <Name>{name}</Name>
                      <FiveStars />
                    </Label>
                    <ProfilePicture src={image} alt="" />
                  </InfoContainer>
                </>
              ) : (
                <InnerContentContainer>
                  <TextContainer>
                    <Quote>{quote}</Quote>
                    <Label>
                      <Name>{name}</Name>
                      <FiveStars />
                    </Label>
                  </TextContainer>
                  <ProfilePicture src={image} alt="" />
                </InnerContentContainer>
              )}
            </QuotesContentContainer>
          </CarouselItem>
        ))}
      </Carousel>
    </QuotesContainer>
  )
}
