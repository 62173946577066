import React, { useEffect } from "react"
import Container from "../common/Container"
import ItalicHeading from "../components/ui/ItalicHeading"
import PolicyText from "../common/PolicyText"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import { convertPrismicToAbleJumpLink, toPage } from "../lib/utils"
import { graphql } from "gatsby"
import DynamicNavigation from "../components/ui/DynamicNavigation"

const Wrapper = styled.div`
  padding: 40px 0;
`

export default function Page({ data }) {
  const page = toPage(data)

  useEffect(() => {
    const hashtag = window.location.hash
    if (hashtag) {
      setTimeout(() => {
        document.getElementById(`0`)?.scrollIntoView({
          behavior: "smooth",
        })
      }, [500])
    }
  })

  const newContent = convertPrismicToAbleJumpLink(page.contents)

  return (
    <Layout>
      <SEO
        title="Cerebral Privacy Policy"
        titleSuffix="How we protect your information"
        description="At Cerebral, your privacy is our top priority. Your medical information will never be shared outside of our secure platform"
      />
      <DynamicNavigation />
      <Wrapper>
        <Container>
          <ItalicHeading>{page.title}</ItalicHeading>
          <PolicyText dangerouslySetInnerHTML={{ __html: newContent }} />
        </Container>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query PolicyPageQuery($lang: String) {
    page: allPrismicPrivacypolicy(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            contents {
              html
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
