import styled from "styled-components"

export const Container = styled.section`
  padding: 0 12%;
  @media (max-width: 499px) {
    padding: 0 16px;
  }
`
export const Answer = styled.div`
  color: #222128;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  padding: 16px 0 64px;

  @media (max-width: 499px) {
    padding: 16px 0 40px;
  }

  b,
  strong {
    font-weight: 600;
    line-height: 32px;
  }
  p:not(:first-child) {
    margin: 8px 0 0;
  }

  ul {
    list-style: disc;
    padding: 8px 0px 0px 24px;
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  .table-wrapper {
    margin-left: 32px;

    @media (max-width: 700px) {
      margin: 0px;
    }

    table.pricing {
      @media (max-width: 700px) {
        width: 100%;
      }
      tr {
        @media (max-width: 700px) {
          border-bottom: 1pt solid #4d515a;
        }

        td {
          padding: 4px 0px;
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          &:nth-child(1) {
            width: 486px;

            @media (max-width: 700px) {
              width: 50%;
            }
          }

          &:nth-child(2) {
            width: 150px;
            font-size: 14px;
            padding: 0px 20px;

            @media (max-width: 700px) {
              text-align: end;
              padding: 0px;
            }
          }

          .benefit-items {
            color: var(--Grey-800, #4d515a);
            font-family: Poppins;
            font-size: 14px;
            padding: 8px 0px 0px 32px;

            @media (max-width: 700px) {
              padding: 0px;
            }
            li {
              padding: 2px 0px;

              @media (max-width: 700px) {
                display: flex;
              }

              .title {
                width: 475px;
                float: left;

                @media (max-width: 700px) {
                  width: 70%;
                }
              }

              .value {
                display: block;

                @media (max-width: 700px) {
                  width: 30%;
                  text-align: end;
                }
              }
            }
          }
        }
      }
    }
  }
`

export const Faq = styled.div`
  padding-top: 200px;
  margin-top: -200px;
`
