import { colors } from "../../../themes/colors"

const RightArrow = () => {
  return (
    <svg
      aria-label="right-arrow icon"
      width="10px"
      height="10px"
      viewBox="0 0 7 8"
      fill={colors.c_primary[900]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.61328 3.91609L0.938366 7.1925L0.938365 0.639676L6.61328 3.91609Z" />
    </svg>
  )
}

export default RightArrow
