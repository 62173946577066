import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Button from "../../../common/Button"
import PromotionalBanner from "../PromotionalBanner"
import logoImage from "../../../images/logo.svg"
import closeImage from "../../../images/close.svg"
import chevronImage from "../../../images/dark-chevron.svg"
import {
  SubMenuItem,
  Wrapper,
  Menu,
  MenuItem,
  Logo,
  CloseButton,
  MenuLink,
  MenuLinkExternal,
  Nav,
  SubMenu,
  MobileMenuIcon,
  SubMenuBackDrop,
} from "./styled"
import { localizedStrings } from "../../../lib/variations"
import { setLinks } from "./helpers"

const Chevron = styled.div`
  background-image: url(${chevronImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%) ${({ open }) => (open ? "" : "rotate(-90deg)")};
`

const ItemWarpper = styled.li`
  position: relative;
  ${SubMenu} {
    min-width: 129px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const CollapsableMenuItem = ({ className, name, children }) => {
  const [open, setOpen] = useState(false)

  return (
    <ItemWarpper className={className}>
      <ul>
        <MenuItem onClick={() => setOpen(open => !open)}>
          <span
            role="button"
            tabIndex="0"
            onKeyDown={e => (e.key === "Enter" ? setOpen(open => !open) : null)}
          >
            {name}
          </span>
          <Chevron open={open} />
        </MenuItem>
        {open && (
          <li>
            <SubMenu>{children}</SubMenu>
            <SubMenuBackDrop onClick={() => setOpen(false)} />
          </li>
        )}
      </ul>
    </ItemWarpper>
  )
}

const MobileMenu = styled.div`
  position: fixed;
  z-index: 2;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 90px;

  & > ${Logo} {
    position: absolute;
    top: 10px;
    left: 15px;
  }

  & > ${CloseButton} {
    position: absolute;
    top: 2px;
    right: 3px;
  }

  & > ${Menu} {
    text-align: center;
  }

  ${MenuItem} {
    margin: 40px auto;
    width: max-content;
  }

  ${SubMenuBackDrop} {
    display: none;
  }

  ${SubMenu} {
    position: relative;
    box-shadow: none;
    max-width: unset;

    ${SubMenuItem} {
      margin: 40px;
    }
  }

  @media (min-width: 600px) {
    ${Logo} {
      top: 10px;
      left: 30px;
    }

    ${CloseButton} {
      position: absolute;
      top: 3px;
      right: 16px;
    }
  }
`

const DesktopMenu = styled.div`
  display: none;
  & > ${Menu} {
    display: flex;
    align-items: center;
    & > li:last-child {
      margin-right: 0;
    }
  }

  & ${MenuItem} {
    margin: 20px;
  }

  @media (min-width: 834px) {
    display: block;
  }
`
const menu = localizedStrings.what_we_offer_links

const PlansNavigation = ({ bannerLookupKey, plan = "medication" }) => {
  const [open, setOpen] = useState(false)
  let top = 0
  if (typeof window !== "undefined") {
    top = document.getElementById("membership")?.offsetTop
  }

  const toggleMenu = () => {
    setOpen(!open)
  }

  const isActive = m => {
    return typeof window !== "undefined"
      ? m.path === window.location.pathname
      : false
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    if (query.get("scrollToMembership") === "true" && top) {
      window.scrollTo(0, top)
      setOpen(false)
    }
  }, [top])

  const { linkToHomepage, linkToFaq, linkToLogin } = setLinks()

  return (
    <>
      <Wrapper>
        <PromotionalBanner lookupKey={bannerLookupKey} plan={plan} />
        <Nav>
          <Logo to={linkToHomepage}>
            <img src={logoImage} alt="Cerebral" />
          </Logo>
          <MobileMenuIcon onClick={toggleMenu} />
          <DesktopMenu>
            <Menu>
              <CollapsableMenuItem name="What We Offer">
                {menu.map(m => (
                  <SubMenuItem key={m.path} active={isActive(m)}>
                    <MenuLink to={m.path}>{m.title}</MenuLink>
                  </SubMenuItem>
                ))}
              </CollapsableMenuItem>
              <MenuItem>
                <MenuLink to={linkToFaq}>FAQ</MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLinkExternal to={linkToLogin}>Login</MenuLinkExternal>
              </MenuItem>
              <MenuItem>
                {plan !== "medication" ? (
                  <Button
                    to={`${process.env.GATSBY_LOGIN_URL}?plan=${plan}&screenHint=signup`}
                  >
                    Get Started
                  </Button>
                ) : (
                  <Button
                    to={`${process.env.GATSBY_LOGIN_URL}?screenHint=signup`}
                  >
                    Get Started
                  </Button>
                )}
              </MenuItem>
            </Menu>
          </DesktopMenu>
        </Nav>
      </Wrapper>
      {open && (
        <MobileMenu>
          <Logo to="/">
            <img src={logoImage} alt="Cerebral" />
          </Logo>
          <CloseButton onClick={toggleMenu}>
            <img src={closeImage} alt="close icon" />
          </CloseButton>
          <Menu>
            <MenuItem>
              {plan !== "medication" ? (
                <MenuLinkExternal
                  to={`${process.env.GATSBY_LOGIN_URL}?plan=${plan}&screenHint=signup`}
                >
                  Get Started
                </MenuLinkExternal>
              ) : (
                <MenuLinkExternal
                  to={`${process.env.GATSBY_LOGIN_URL}?screenHint=signup`}
                >
                  Get Started
                </MenuLinkExternal>
              )}
            </MenuItem>
            <MenuItem>
              <MenuLinkExternal to={linkToLogin}>Login</MenuLinkExternal>
            </MenuItem>
            <MenuItem>
              <MenuLink to={linkToFaq}>FAQ</MenuLink>
            </MenuItem>
            <CollapsableMenuItem name="What We Offer">
              {menu.map(m => (
                <SubMenuItem>
                  <MenuLink to={m.path}>{m.title}</MenuLink>
                </SubMenuItem>
              ))}
            </CollapsableMenuItem>
          </Menu>
        </MobileMenu>
      )}
    </>
  )
}

export default PlansNavigation
