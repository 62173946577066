import styled from "styled-components"

export const Container = styled.section`
  width: 1175px;
  margin: 32px auto;
  background: linear-gradient(135deg, #eeecf5 0%, #c3cffc 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  padding: 24px;
  line-height: 24px;
  border-radius: 8px;
  h2 {
    font-size: 18px;
  }
  p {
    margin-top: 12px;
    max-width: 700px;
  }
  @media (max-width: 1300px) {
    width: auto;
    max-width: 1050px;
  }
  @media (max-width: 800px) {
    width: auto;
    max-width: 100%;
    flex-direction: column;
    padding: 16px;
    margin: 24px;
  }
`
export const TextWrapper = styled.div`
  display: flex;
  flex-direction-row;
  gap: 24px;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
export const ButtonWrapper = styled.div`
  @media (max-width: 800px) {
    max-width: 300px;
    margin-top: 24px;
`
export const IconWrapper = styled.div`
  min-width: 40px;
`
