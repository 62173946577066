import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f3f5ff;
  height: 828px;
  & > h1 {
    position: relative;
    max-width: 720px;
    padding: 25px 0px;
  }
  @media (max-width: 834px) {
    height: fit-content;
    padding: 24px 24px;
    background: linear-gradient(180deg, #f3f5ff 97%, ${colors.c_white[500]});
  }
`

export const SubText = styled.p`
  position: relative;
  max-width: 720px;
  font-size: 16px;
  font-weight: 300;
  margin: 8px;
  line-height: 24px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
  }
`
