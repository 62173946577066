import { forwardRef, useState } from "react"
import {
  Header,
  SafeWrapper,
  JudgementWrapper,
  ClinicianImage,
  ClinicianContainer,
  ButtonWrapper,
  CliniciansWrapper,
  Container,
  BackgroundSquiggle,
} from "./styled"
import JudgementDesktop from "./assets/judgement-desktop.svg"
import SafeDesktop from "./assets/safe-desktop.svg"
import Prescriber from "./assets/prescriber.webp"
import Therapist from "./assets/therapist.webp"
import BackgroundShape from "./assets/background-squiggle.svg"
import { GetStartedButton } from "../../../shared"

const Clinician = ({ type }: { type: string }) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <ClinicianContainer>
      <ClinicianImage
        src={type === "prescriber" ? Prescriber : Therapist}
        isHovered={isHovered}
      />
      <ButtonWrapper
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <GetStartedButton
          text={
            type === "prescriber" ? "Find a prescriber" : "Find a therapist"
          }
          href={`/find-a-${type}`}
        />
      </ButtonWrapper>
    </ClinicianContainer>
  )
}

export const WorkWithAClinician = forwardRef(
  ({}, ref: React.ForwardedRef<HTMLDivElement>) => {
    return (
      <Container ref={ref}>
        <Header>
          Work with a prescriber or therapist in a{" "}
          <SafeWrapper src={SafeDesktop} /> and{" "}
          <JudgementWrapper src={JudgementDesktop} /> online space{" "}
        </Header>

        <CliniciansWrapper>
          <Clinician type="prescriber" />
          <Clinician type="therapist" />
          <BackgroundSquiggle src={BackgroundShape} />
        </CliniciansWrapper>
      </Container>
    )
  }
)
