import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const SeoCopy = styled.section`
  background: linear-gradient(#eff2ff, ${colors.c_white[500]});
  padding: 8px 16px 80px;
  h2 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
    max-width: 991px;
    margin: 16px auto;
    @media (max-width: 834px) {
      max-width: 500px;
    }
  }
  p {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 991px;
    margin: 0 auto;
    @media (max-width: 834px) {
      max-width: 500px;
    }
  }
`
