import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const HowItWorksWrapper = styled.section`
  background-color: ${colors.c_white[500]};
  max-width: 900px;
  padding: 64px 16px 32px;
  margin: 0 auto;
  text-align: center;
  h2 {
    text-align: center;
  }
`

export const Row = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 258px));
  gap: 40px;

  @media (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`

export const Item = styled.div`
  text-align: center;
  h5 {
    margin-bottom: 8px;
  }
`

export const NumberBadge = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${colors.c_secondary[100]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: ${colors.c_primary[900]};
  padding: 8px;
  font-size: 14px;
  margin-bottom: 16px;
`
