import styled from "styled-components"

export const ExpertContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px;
  @media (max-width: 834px) {
    margin: 88px 24px;
  }
`

export const Heading = styled.div`
  text-align: center;

  @media (max-width: 600px) {
    max-width: 216px;
  }
`

export const Subheading = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 12px;

  @media (max-width: 834px) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    max-width: 328px;
  }
`

export const ExpertSection = styled.div`
  margin-top: 40px;
  display: flex;
  @media (max-width: 834px) {
    flex-direction: column;
    align-items: center;
     h3 {
        align-items: center;
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        margin-top: 24px;
      }
    }
  }
`

export const ExpertInfo = styled.span`
  max-width: 650px;
  margin-left: 50px;
  @media (max-width: 834px) {
    margin-left: 0;
    align-items: center;
  }
`
export const ExpertRole = styled.p`
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin: 5px 0 16px;

  @media (max-width: 834px) {
    margin-left: 0;
    align-items: center;
    text-align: center;
    margin: 5px;
  }
`

export const ExpertBio = styled.p`
  font-weight: 300;
  line-height: 24px;

  @media (max-width: 834px) {
    font-size: 14px;
    line-height: 22px;
  }
`
export const ExpertName = styled.h3`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.13);

  @media (max-width: 834px) {
    font-size: 18px;
    line-height: 24px;
    margin-top: 24px;
    text-align: center;
  }
`
