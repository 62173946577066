import "../common/colors.css"
import { HomePage } from "../components/HomePage"
import Layout from "../components/Layout"

export default function DynamicPage() {
  return (
    <Layout useKameleoon={true}>
      <HomePage />
    </Layout>
  )
}
