import { Helmet } from "react-helmet-async"
import Head from "../components/Head"
import MicroFrontEndLoader from "../components/MicroFrontEndLoader"

export default function ScreenersPage() {
  return (
    <>
      <Helmet title="Cerebral - Online assessment self-test" />
      <Head />
      <MicroFrontEndLoader
        serviceName="Screeners"
        serviceHost="https://main.dzt0mx3ib6hjo.amplifyapp.com"
        prefix="/screeners"
      />
    </>
  )
}
