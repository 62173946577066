import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const Container = styled.section`
  background: ${colors.c_white[500]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  @media (max-width: 834px) {
    gap: 0;
    & > div:last-child {
      margin-top: 16px;
    }
  }
`
