import { BodyBlockWrapper } from "./styled"

export interface IBodyBlockProps {
  header?: string
  description?: string
  iconURL?: string
}

export const BodyBlock = ({
  header,
  description,
  iconURL,
}: IBodyBlockProps) => {
  return (
    <BodyBlockWrapper>
      <img src={iconURL} />
      <div>
        <h3>{header}</h3>
        <p>{description}</p>
      </div>
    </BodyBlockWrapper>
  )
}
