import React from "react"
import Layout from "../components/Layout"
import { CouplesTherapyPage } from "../components/CouplesTherapyPage/CouplesTherapyPage"

export default function DynamicPage() {
  return (
    <Layout>
      <CouplesTherapyPage />
    </Layout>
  )
}
