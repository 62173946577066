import { current } from "@reduxjs/toolkit"
import { AssociatedLocations, SpecializationData } from "../../types"
import capitalize from "lodash/capitalize"

const createStringValueForLink = (
  key: string,
  currentCityOrState?: string,
  currentSpecialty?: string,
  name?: string,
  carePersonType: string = "therapist"
) => {
  switch (key) {
    case "cities":
    case "states":
      return `${capitalize(carePersonType)}s in ${name}`
    case "specializations":
      return `${capitalize(carePersonType)}s specializing in ${name}`
    case "states_with_specialization":
      return `${capitalize(
        carePersonType
      )}s in ${name} specializing in ${currentSpecialty}`
    case "specialization_in_this_state":
    case "specialization_in_this_city":
      return `${capitalize(
        carePersonType
      )}s in ${currentCityOrState} specializing in ${name}`
    default:
      return ""
  }
}

export const createAssociatedLinksArray = (
  associatedLocations: AssociatedLocations,
  currentCityOrState?: string,
  currentSpecialty?: string,
  carePersonType?: string
): Array<SpecializationData> => {
  const currentKeys = Object.keys(associatedLocations) as Array<
    keyof typeof associatedLocations
  >
  return currentKeys
    .map(currentKey => {
      return (associatedLocations[currentKey] || []).map(currentLink => {
        const currentName = currentLink.name
        currentLink.linkTitle = createStringValueForLink(
          currentKey,
          currentCityOrState,
          currentSpecialty,
          currentName,
          carePersonType
        )
        return currentLink
      })
    }, [])
    .flat()
}
