import { useState } from "react"
import {
  Button,
  Description,
  GenericWidgetWrapper,
  PlanIcon,
  PlanItemWrapper,
  PlanName,
  PlanWrapper,
  SubTitle,
  Title,
} from "./styled"

interface PlanOption {
  icon: string
  planName: string
  title: string
  subTitle: string
  description: string
  planType: string
}

interface GenericWidgetProps {
  plans: PlanOption[]
}

const GenericWidget = ({ plans }: GenericWidgetProps) => {
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0)

  return (
    <GenericWidgetWrapper>
      <Title>{plans[selectedPlanIndex].title}</Title>
      <SubTitle>{plans[selectedPlanIndex].subTitle}</SubTitle>
      <PlanWrapper numOfColumn={plans.length}>
        {plans.map((plan, index) => (
          <PlanItemWrapper
            active={index === selectedPlanIndex}
            onClick={() => setSelectedPlanIndex(index)}
          >
            <PlanIcon src={plan.icon} alt={plan.planName} />
            <PlanName>{plan.planName}</PlanName>
          </PlanItemWrapper>
        ))}
      </PlanWrapper>
      <Description>{plans[selectedPlanIndex].description}</Description>
      <Button
        to={`${process.env.GATSBY_BASE_URL}/app?screenHint=signup&nextStep=signup&planType=${plans[selectedPlanIndex].planType}`}
      >
        Get Started
      </Button>
    </GenericWidgetWrapper>
  )
}

export default GenericWidget
