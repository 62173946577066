import { useEffect, useState } from "react"
import { createApiInstance } from "@cerebral-inc/api"
import { ICarePerson } from "../../types"
import { handleBook } from "../../../utils/utils"
import {
  AvailabilityHeading,
  CalendarTitle,
  InnerContentContainer,
  BookButton,
  SelectedDateText,
  CalendarWrapper,
} from "./styled"
import { CalendarV2 } from "../../../shared/Calendar"
import { TimeSlots } from "../../../shared/TimeSlots"
import { useCarePersonAvailability } from "../../../shared/hooks/useCarePersonAvailability"
import Spinner from "../../../ui/Spinner"
import { StateSelectGroup } from "./StateSelectGroup"
import { FIND_PRESCRIBER, FIND_THERAPIST, isCashPayPayer } from "./utils"
import {
  UnderCalendarTypeFormPayer,
  UnderCalendarInsuranceNotCover,
  FindSomeOneFooter,
  SelfPlanLinkFooter,
  SupportLinkFooter,
  UnavailabilityText,
} from "./helpers"
import {
  IInsurancePayer,
  IRteConfigs,
} from "components/shared/InsuranceWidget/InsuranceCarrierSelectV2/types"

interface AvailabilityProps {
  carePersonData: ICarePerson
}

export const Availability = ({
  carePersonData,
}: AvailabilityProps): JSX.Element => {
  const [payers, setPayers] = useState<Record<string, IInsurancePayer[]>>({})
  const [states, setStates] = useState<string[]>([])
  const [currentPayer, setCurrentPayer] = useState<string>("")
  const [currentState, setCurrentState] = useState<string>("")
  const [resetKey, setResetKey] = useState(0)
  const [currentDate, setCurrentDate] = useState<Date | undefined>(undefined)

  const { accepting_new_client, given_name, cash_pay } = carePersonData
  const acceptedPayers =
    (carePersonData.insurance_accepted || {})[currentState] || []
  const clinicianCoverInsurance = acceptedPayers.find(
    payer => payer.payer === currentPayer
  )
  const currentPayerInfo = (payers[currentState] || []).find(
    ({ display_name }) => display_name === currentPayer
  )
  const payerSupportRTE =
    clinicianCoverInsurance && currentPayerInfo?.rte_enabled

  const api = createApiInstance({
    baseURL: process.env.GATSBY_API_SERVER_URL,
  })

  const {
    availableDates,
    timeSlots,
    timeSelected,
    setTimeSelected,
    dateString,
    handleChangeDate,
    hasBeenLoaded,
    isSlotLoading,
  } = useCarePersonAvailability(carePersonData)

  const acceptedInsuranceStates = Object.keys(
    carePersonData.insurance_accepted || {}
  )
  const isInsuranceAcceptedEmpty = acceptedInsuranceStates.length === 0
  const clinicianPath =
    carePersonData.role.toLowerCase() === "therapist"
      ? FIND_THERAPIST
      : FIND_PRESCRIBER

  const fetchRteStateConfigs = async () => {
    try {
      const response = await api.get<IRteConfigs>(`api/insurance/rte_configs`)
      const stateNames = response.data.states.map(state => state.name)
      setStates(stateNames)
      const payersMap = response.data.states.reduce((acc, state) => {
        acc[state.name] = state.insurance_payers
        return acc
      }, {} as Record<string, IInsurancePayer[]>)
      setPayers(payersMap)
    } catch (error) {
      console.error("Failed to fetch RTE configurations:", error)
    }
  }

  useEffect(() => {
    fetchRteStateConfigs()
  }, [])

  useEffect(() => {
    setCurrentDate(availableDates[0] ? new Date(availableDates[0]) : undefined)
  }, [availableDates])

  const handeSelectPayer = ({
    state,
    payer,
  }: {
    state: string
    payer: string
  }) => {
    setCurrentPayer(payer)
    setCurrentState(state)
    setResetKey(prevKey => prevKey + 1)
    if (availableDates[0]) {
      handleChangeDate(new Date(availableDates[0]))
    }
  }

  const handleBookButtonAction = () => {
    if (isCashPayPayer(currentPayer)) {
      handleBook(carePersonData, timeSelected)
    } else {
      window.location.href = `${process.env.GATSBY_BASE_URL}/app/pre-signup?external_source=insurance_widget&ob_flow=insurance_eligibility_check&preferred_clinician=${given_name}&widget_state=${currentState}&widget_payer=${currentPayer}`
    }
  }

  const handleClickPrevOrNext = (activeStartDate: Date) => {
    if (!activeStartDate) return

    const firstDayOfCurrentMonth = availableDates.find(
      availableDate =>
        new Date(activeStartDate) < new Date(availableDate) &&
        new Date(activeStartDate).getMonth() >=
          new Date(availableDate).getMonth() &&
        new Date(activeStartDate).getFullYear() >=
          new Date(availableDate).getFullYear()
    )

    if (firstDayOfCurrentMonth) {
      setCurrentDate(new Date(firstDayOfCurrentMonth))
      handleChangeDate(new Date(firstDayOfCurrentMonth))
    } else {
      setCurrentDate(undefined)
    }
  }

  const renderNoAcceptNewClient = () => {
    return (
      <>
        <StateSelectGroup
          states={states}
          onChange={handeSelectPayer}
          payers={payers}
          showPayerInput
          disabled
        />
        <CalendarWrapper>
          <CalendarV2
            calendarVersion="availability"
            availableDays={availableDates}
            handleChangeDate={handleChangeDate}
            disabled
          />
        </CalendarWrapper>
        <UnavailabilityText name={given_name} mt="27px" />
        <FindSomeOneFooter path={clinicianPath} />
      </>
    )
  }

  const renderNoInsuranceAccepted = () => {
    const hasCashPay = cash_pay.find(item => item.state === currentState)
    const isCashPayAvailable = hasCashPay?.available

    const renderUnderCalendar = () => {
      if (!currentDate) {
        return (
          <>
            <BookButton disabled>Get Started</BookButton>
            <SupportLinkFooter />
          </>
        )
      }

      if (!hasCashPay) {
        return (
          <FindSomeOneFooter
            path={clinicianPath}
            text="Let's find another clinician"
          />
        )
      }

      if (isCashPayAvailable) {
        if (isSlotLoading) {
          return <Spinner className="" />
        }

        return (
          <>
            <SelectedDateText>{dateString}</SelectedDateText>
            <TimeSlots
              timeSlots={timeSlots}
              timeSelected={timeSelected}
              setTimeSelected={setTimeSelected}
            />
            {timeSlots.length === 0 && <UnavailabilityText name={given_name} />}
            <BookButton
              disabled={timeSelected === ""}
              onClick={() => handleBook(carePersonData, timeSelected)}
            >
              Get Started
            </BookButton>
            <SupportLinkFooter />
          </>
        )
      }

      return (
        <>
          <UnavailabilityText name={given_name} mt="27px" />
          <FindSomeOneFooter path={clinicianPath} />
        </>
      )
    }

    return (
      <>
        <StateSelectGroup
          states={states}
          onChange={handeSelectPayer}
          payers={payers}
          showPayerInput={!isInsuranceAcceptedEmpty}
        />
        <CalendarWrapper>
          <CalendarV2
            key={resetKey}
            defaultValue={currentDate}
            calendarVersion="availability"
            availableDays={availableDates}
            handleChangeDate={date => {
              handleChangeDate(date)
              setCurrentDate(date)
            }}
            disabled={!isCashPayAvailable}
            onActiveStartDateChange={e =>
              handleClickPrevOrNext(e.activeStartDate as Date)
            }
            value={currentDate}
          />
        </CalendarWrapper>
        {renderUnderCalendar()}
      </>
    )
  }

  const initialViewWithInsurancceAccepted = () => {
    return (
      <>
        <StateSelectGroup
          states={states}
          onChange={handeSelectPayer}
          payers={payers}
          showPayerInput={accepting_new_client && !isInsuranceAcceptedEmpty}
        />
        <CalendarWrapper>
          <CalendarV2
            key={resetKey}
            defaultValue={currentDate}
            calendarVersion="availability"
            availableDays={availableDates}
            handleChangeDate={handleChangeDate}
            disabled
          />
        </CalendarWrapper>
        <BookButton disabled>Get started</BookButton>
        <SupportLinkFooter />
      </>
    )
  }

  const renderHasInsuranceAccepted = () => {
    // When user not select payer and state
    if (currentPayer === "") return initialViewWithInsurancceAccepted()

    const hasCashPay = cash_pay.find(item => item.state === currentState)
    const hasInsurance = acceptedPayers.find(
      item => item.payer === currentPayer
    )
    const isCashPaySupported = isCashPayPayer(currentPayer) && hasCashPay
    const isAvailableDate = availableDates?.length > 0
    const insurancAvailable = hasInsurance?.available
    const cashPayAvailable = hasCashPay?.available
    const noAvailability =
      (hasInsurance && !insurancAvailable && !isCashPayPayer(currentPayer)) ||
      (hasCashPay && !cashPayAvailable && isCashPayPayer(currentPayer))

    const renderUnderCalendar = () => {
      if (clinicianCoverInsurance) {
        if (!clinicianCoverInsurance?.available) {
          return (
            <>
              <UnavailabilityText name={given_name} mt="27px" />
              <FindSomeOneFooter path={clinicianPath} />
            </>
          )
        }

        return (
          <UnderCalendarTypeFormPayer
            external_source={window.location.href}
            preferred_clinician={carePersonData.name}
            widget_state={currentState}
            widget_payer={currentPayer}
          />
        )
      }

      if (isCashPayPayer(currentPayer) && !hasCashPay) {
        return (
          <FindSomeOneFooter
            path={clinicianPath}
            text="Let's find another clinician"
          />
        )
      }

      return <UnderCalendarInsuranceNotCover path={clinicianPath} />
    }

    const renderTimeSlot = () => {
      if (isSlotLoading) {
        return <Spinner className="" />
      }

      if (!currentDate) {
        return (
          <>
            <BookButton disabled>Get Started</BookButton>
            <SupportLinkFooter />
          </>
        )
      }

      if (noAvailability) {
        return (
          <>
            <UnavailabilityText name={given_name} mt="27px" />
            <FindSomeOneFooter path={clinicianPath} />
          </>
        )
      }

      return (
        <>
          <SelectedDateText>{dateString}</SelectedDateText>
          <TimeSlots
            timeSlots={timeSlots}
            timeSelected={timeSelected}
            setTimeSelected={setTimeSelected}
          />
          {timeSlots.length === 0 && <UnavailabilityText name={given_name} />}
          <BookButton
            onClick={handleBookButtonAction}
            disabled={timeSelected === ""}
          >
            {isCashPayPayer(currentPayer)
              ? "Book session"
              : "Get started with insurance"}
          </BookButton>
          {payerSupportRTE ? <SelfPlanLinkFooter /> : <SupportLinkFooter />}
        </>
      )
    }

    if (payerSupportRTE || isCashPaySupported) {
      return (
        <>
          <StateSelectGroup
            states={states}
            onChange={handeSelectPayer}
            payers={payers}
          />
          <CalendarWrapper>
            <CalendarV2
              key={resetKey}
              defaultValue={currentDate}
              calendarVersion="availability"
              availableDays={availableDates}
              handleChangeDate={date => {
                handleChangeDate(date)
                setCurrentDate(date)
              }}
              disabled={!isAvailableDate || noAvailability}
              onActiveStartDateChange={e =>
                handleClickPrevOrNext(e.activeStartDate as Date)
              }
              value={currentDate}
            />
          </CalendarWrapper>
          {renderTimeSlot()}
        </>
      )
    }

    return (
      <>
        <StateSelectGroup
          states={states}
          onChange={handeSelectPayer}
          payers={payers}
        />
        <CalendarWrapper>
          <CalendarV2
            key={resetKey}
            defaultValue={currentDate}
            calendarVersion="availability"
            availableDays={availableDates}
            handleChangeDate={handleChangeDate}
            disabled
          />
        </CalendarWrapper>
        {renderUnderCalendar()}
      </>
    )
  }

  const renderCalendar = () => {
    if (accepting_new_client) {
      if (isInsuranceAcceptedEmpty) {
        return renderNoInsuranceAccepted()
      }

      return renderHasInsuranceAccepted()
    }

    return renderNoAcceptNewClient()
  }

  if (!hasBeenLoaded) return <Spinner className="" />

  return (
    <InnerContentContainer>
      <AvailabilityHeading>
        Think {given_name} sounds like a great fit?
      </AvailabilityHeading>
      <CalendarTitle>Check {given_name}'s availability</CalendarTitle>
      {renderCalendar()}
    </InnerContentContainer>
  )
}
