import Signup from "./assets/step-signup.svg"
import FindClinician from "./assets/step-find-clinician.svg"
import Goals from "./assets/step-goals.svg"
import Schedule from "./assets/step-schedule.svg"
import Number1 from "./assets/number-1.svg"
import Number2 from "./assets/number-2.svg"
import Number3 from "./assets/number-3.svg"
import Number4 from "./assets/number-4.svg"

export const stepData = [
  {
    heading: "Sign up for therapy, medication, or both",
    image: Signup,
    number: Number1,
  },
  {
    heading: "Choose your clinician",
    image: FindClinician,
    number: Number2,
  },
  {
    heading: "Book + manage sessions online",
    image: Schedule,
    number: Number3,
  },
  {
    heading: "Set goals + track your progress ",
    image: Goals,
    number: Number4,
  },
]
