import { IGatsbyImageData } from "gatsby-plugin-image"
import {
  BioCard,
  BioDescription,
  BioSectionWrapper,
  BioTitle,
  BioImage,
} from "./styled"
import { useEffect, useState } from "react"
import axios from "axios"

interface BioSectionProps {
  authorImage: IGatsbyImageData
  authorName: string
  authorLink: string
  reviewerImage: IGatsbyImageData
  reviewerName: string
  reviewerLink: string
}

const BioSection = ({
  authorImage,
  authorLink,
  authorName,
  reviewerImage,
  reviewerLink,
  reviewerName,
}: BioSectionProps) => {
  const [authorDescription, setAuthorDescription] = useState("")
  const [reviewerDescription, setReviewerDescription] = useState("")

  const convertBioUrl = (url: string) => {
    const path = url.split("/").slice(3).join("/")

    return `${process.env.GATSBY_BASE_URL}/page-data/${path}/page-data.json`
  }

  const getDescription = async () => {
    if (process.env.GATSBY_BASE_URL?.includes("localhost")) return

    if (authorLink) {
      const authorData = await axios.get(convertBioUrl(authorLink))
      setAuthorDescription(authorData.data.result.pageContext.bio_experience)
    }

    if (reviewerLink) {
      const reviewerData = await axios.get(convertBioUrl(reviewerLink))
      setReviewerDescription(
        reviewerData.data.result.pageContext.bio_experience
      )
    }
  }

  useEffect(() => {
    getDescription()
  }, [])

  if (!authorDescription && !reviewerDescription) {
    return <></>
  }

  return (
    <BioSectionWrapper>
      {authorDescription && (
        <BioCard>
          <BioImage image={authorImage} alt="author_image" />
          <BioTitle>{`Written by ${authorName}`}</BioTitle>
          <BioDescription>{authorDescription}</BioDescription>
        </BioCard>
      )}

      {reviewerDescription && (
        <BioCard>
          <BioImage image={reviewerImage} alt="reviewer_image" />
          <BioTitle>{`Medical reviewed by Bisma Anwar ${reviewerName}`}</BioTitle>
          <BioDescription>{reviewerDescription}</BioDescription>
        </BioCard>
      )}
    </BioSectionWrapper>
  )
}

export default BioSection
