import {
  Container,
  LogosContainer,
  LogoImage,
  InsuranceText,
  CoverageLink,
  CoverageTextContainer,
  InsuranceContainer,
  Wrapper,
} from "./styled"
import CignaIcon from "./assets/cigna-black.svg"
import BCBSIcon from "./assets/bcbs-black.svg"
import UnitedHealthcareIcon from "./assets/united-black.svg"
import OptumIcon from "./assets/optum-black.svg"
import HumanaIcon from "./assets/humana-black.svg"
import Arrow from "./assets/arrow-right.svg"
import HeartShield from "./assets/heart-shield.svg"

export const CheckYourInsurance = () => {
  return (
    <Wrapper>
      <Container>
        <InsuranceContainer>
          <img src={HeartShield} alt="Heart Shield" />
          <CoverageTextContainer>
            <InsuranceText>Paying with insurance?</InsuranceText>
            <CoverageLink to="/insurance-partners">
              Check your coverage <img src={Arrow} alt="Arrow" />
            </CoverageLink>
          </CoverageTextContainer>
        </InsuranceContainer>
        <LogosContainer>
          <LogoImage src={CignaIcon} alt="Cigna Insurance Logo" />
          <LogoImage src={HumanaIcon} alt="Humana Insurance Logo" />
          <LogoImage src={OptumIcon} alt="Optum Insurance Logo" />
          <LogoImage
            src={UnitedHealthcareIcon}
            alt="United Healthcare Insurance Logo"
          />
          <LogoImage src={BCBSIcon} alt="BCBS Insurance Logo" />
        </LogosContainer>
      </Container>
    </Wrapper>
  )
}
