import { IAlternatingSplashesCopy } from "../shared/AlternatingSplashes/AlternatingSplashes"
import Star from "./images/Star.svg"
import People from "./images/People.svg"
import Writing from "./images/Writing.svg"
import Heart from "./images/fi_heart.svg"
import LGBTHeart from "./images/fi_heart-1.svg"
import Users from "./images/fi_users.svg"
import Intersection from "./images/u_master-card.svg"
import ApproachesWeUse from "./images/approaches_we_use.webp"
import PhoneTherapy from "./images/phone-therapy.webp"
import { UilThumbsUp } from "@iconscout/react-unicons"
import { UilHeart } from "@iconscout/react-unicons"
import { PlanInfo } from "../shared/ChooseService/ChooseService"
import { IIsCouplesRightToMeCopy } from "./sections/IsCouplesRightToMe/IsCouplesRightToMe"
import { IApproachesWeUseCopy } from "./sections/ApproachesWeUse/ApproachesWeUse"

export const IsCouplesRightToMeCopy: IIsCouplesRightToMeCopy = {
  section: {
    heading: "Is couples therapy right for me?",
    image: PhoneTherapy,
    body: [
      {
        heading: "Support at any stage",
        description: "Get support for any relationship status or situation",
        icon: Heart,
      },
      {
        heading: "LGBTQia+ relationships",
        description: "Safe and judgement-free guidance",
        icon: LGBTHeart,
      },
      {
        heading: "Pre-marriage",
        description:
          "Learn how to prepare for the next step in your relationship",
        icon: Users,
      },
      {
        heading: "Married couples",
        description: "Strengthen your bond, navigate conflict, deepen intimacy",
        icon: Intersection,
      },
    ],
  },
}

export const approachesWeUseCopy: IApproachesWeUseCopy = {
  section: {
    heading: "Approaches our therapists use",
    image: ApproachesWeUse,
    body: [
      {
        heading: "Emotionally Focused Therapy (EFT)",
        description:
          "Short-term therapy that aims to rekindle physical and emotional bonds when couples are feeling distant or disappointed",
      },
      {
        heading: "Integrated Behavioral Couples Therapy (IBCT)",
        description:
          "A newer form of therapy that focuses on how acceptance and change can create long-term success in relationships",
      },
      {
        heading: "The Gottman Method",
        description:
          "Couples take an assessment to understand the health of the relationship and then work through a plan based on the results",
      },
    ],
  },
}

export const chooseServicePlans: Array<PlanInfo> = [
  {
    icon: UilHeart,
    title: "Navigate your relationship with confidence",
    planName: "Couples Therapy",
    planDescription:
      "Weekly 45-minute video sessions with a therapist. A dedicated treatment plan + goal setting",
    weeklyPrice: 82,
    monthlyPrice: 325,
  },
]
