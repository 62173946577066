// This is ignored from linting because it is default Ketch code. It would normally run in the head of the index.html file,
// but we have to run it in the javascript code because we need to load it conditionally based upon a feature flag.

export const loadKetchScript = () => {
  !(function () {
    ;(window.semaphore = window.semaphore || []),
      (window.ketch = function () {
        window.semaphore.push(arguments)
      })
    const n = document.createElement("script")
    ;(n.type = "text/javascript"),
      (n.src = "https://global.ketchcdn.com/web/v2/config/cerebral/".concat(
        "website_smart_tag",
        "/boot.js"
      )),
      (n.defer = n.async = !0),
      document.getElementsByTagName("head")[0].appendChild(n)
  })()
  console.log("Ketch is enabled")
}
