import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../../../themes/colors"

export const Container = styled.div`
  display: flex;
  gap: 64px;
  align-items: baseline;

  @media (max-width: 1023px) {
    max-width: 480px;
    flex-direction: column;
    gap: 0px;
    align-items: left;
  }
`

export const Wrapper = styled.section`
  display: flex;
  width: 100%;
  background: #ecebff;
  padding-top: 24px;
  padding-bottom: 88px;
  justify-content: center;

  @media (max-width: 1023px) {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 40px;
  }
`
export const LogosContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
  gap: 0 24px;

  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const InsuranceText = styled.h3`
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  max-width: 150px;

  @media (max-width: 1023px) {
    font-size: 20px;
    max-width: 300px;
  }
`

export const CoverageLink = styled(Link)`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-decoration: none;
  color: ${colors.c_secondary[600]};
`

export const CoverageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const InsuranceContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 834px) {
    margin-bottom: 32px;
    gap: 8px;
  }
`

export const LogoImage = styled.img`
  @media (max-width: 1023px) {
    height: 56px;
  }
`
