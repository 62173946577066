import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import FiArrowRightIcon from "./images/fi_arrow_right.svg"

const Wrapper = styled.div`
  border-radius: var(--base-S2, 16px);
  background: var(
    --Gradient-Relax-Inverse,
    linear-gradient(135deg, #f6e9fe 0%, #caeaec 100%)
  );
  max-width: 992px;
  margin: auto;
  min-height: 425px;
  padding: 64px;
  font-family: Poppins;
  text-align: center;
  margin-bottom: 64px;

  @media (max-width: 1040px) {
    margin: 48px 24px;
    padding: 32px 24px;
  }
`

const SubTitle = styled.div`
  color: #353a41;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
`

const Title = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-top: 12px;

  @media (max-width: 992px) {
    margin-top: 8px;
    font-size: 24px;
    line-height: 28px;
  }
`

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  width: 654px;
  margin: 24px auto;
  color: #353a41;

  @media (max-width: 992px) {
    font-size: 14px;
    width: 100%;
  }
`

const OptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 654px;
  margin: 32px auto 0px;
  text-align: left;
  padding: 0px 71px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`

const Option = styled.a`
  border-radius: var(--base-S2, 16px);
  border: 1.5px solid var(--bn-w-white-900, #fff);
  background: var(--bn-w-white-900, #fff);
  padding: 12px 20px 12px 16px;
  text-decoration: none;
  color: #353a41;
  font-size: 16px;
  font-weight: 600;
  flex: 1 1 calc(50% - 10px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`

const OptionIcon = styled.img`
  float: inline-end;
`

interface AssessmentListInterface {
  slice: {
    slice_type: string
    primary: {
      sub_title: {
        text: string
      }
      main_title: {
        text: string
      }
      description: {
        html: string
      }
    }
    items: {
      assessesment_title: {
        text: string
      }
      assessment_link: {
        url: string
      }
    }[]
  }
}

const AssessmentList = ({ slice }: AssessmentListInterface) => {
  const { items, primary } = slice

  return (
    <Wrapper>
      <SubTitle>{primary.sub_title.text}</SubTitle>
      <Title>{primary.main_title.text}</Title>
      <Description
        dangerouslySetInnerHTML={{
          __html: primary.description.html,
        }}
      ></Description>
      <OptionWrapper>
        {items.map(item => {
          return (
            <Option href={item.assessment_link.url}>
              {item.assessesment_title.text}
              <OptionIcon src={FiArrowRightIcon} />
            </Option>
          )
        })}
      </OptionWrapper>
    </Wrapper>
  )
}

export const query = graphql`
  fragment PageAssessmentListSlice on PrismicPageDataBodyAssessmentList {
    id
    items {
      assessesment_title {
        text
      }
      assessment_link {
        url
      }
    }
    primary {
      description {
        html
      }
      main_title {
        text
      }
      sub_title {
        text
      }
    }
    slice_label
    slice_type
  }
`

export default AssessmentList
