import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { edgeToResource } from "../lib/utils"
import { graphql } from "gatsby"
import DynamicNavigation from "../components/ui/DynamicNavigation"
import Resource from "../components/sections/Resource"

const ResourcePage = ({ data, pageContext }) => {
  const resource = edgeToResource({ edge: data.main.edges[0] })
  const { seo, image } = resource

  return (
    <Layout useKameleoon={true}>
      <SEO image={image} {...seo} />
      <DynamicNavigation bannerLookupKey="banner-default" />
      <Resource {...resource} pageContext={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query ResourcePageQuery($id: String) {
    main: allPrismicResource(filter: { id: { eq: $id } }) {
      edges {
        node {
          data {
            title {
              text
            }
            image {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
            content {
              html
            }
            files {
              file {
                url
                uid
                link_type
                raw
              }
            }
            ctatext {
              text
            }
            ctalink {
              url
              target
            }
            ctasize
            seotitle {
              text
            }
            seodescription {
              text
            }
            seokeywords {
              text
            }
          }
          prismicId
          uid
          id
        }
      }
    }
  }
`

export default ResourcePage
