import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 64px 64px 112px;
  text-align: center;
  align-items: center;
  & > img {
    margin: 0 auto;
  }
  @media (max-width: 834px) {
    flex-direction: column;
    align-items: center;
    padding: 0px 40px 96px;
  }
  & > p {
    display: flex;
    color: ${colors.c_secondary[600]};
    @media (max-width: 834px) {
      font-size: 16px;
      max-width: unset;
    }
  }
`

export const QuoteWrapper = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-size: 20px;
  padding: 24px;
  line-height: 32px;
  font-weight: 600;
  max-width: 784px;
`

export const TextWrapper = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
`
