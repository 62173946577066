import HeroHeader from "./HeroHeader"
import FeatureTextRight from "./FeatureTextRight"
import FeatureTextLeft from "./FeatureTextLeft"
import TextLeft from "./TextLeft"
import TextRight from "./TextRight"
import CallOutBox from "./CallOutBox"
import CtaButton from "./CtaButton"
import ThreeColumns from "./ThreeColumns"
import FourColumns from "./FourColumns"
import LargeQuote from "./LargeQuote"
import QuoteWithImage from "./QuoteWithImage"
import Video from "./Video"
import Image from "./Image"
import ImageWithText from "./ImageWithText"
import FullImageWithTextOverlay from "./FullImageWithTextOverlay"
import Text from "./Text"
import NewsLinks from "./NewsLinks"
import SubMenuNavBar from "./SubMenuNavBar"
import Space from "./Space"
import ThreeColumnsList from "./ThreeColumnsList"
import TextAndTwoColumnsList from "./TextAndTwoColumnsList"
import TitleAndCopyWithImage from "./TitleAndCopyWithImage"
import ButtonsColumns from "./ButtonsColumns"
import ListOfItemsWithIcon from "./ListOfItemsWithIcon"
import ImageWithHeader from "./ImageWithHeader"
import AvatarWithBio from "./AvatarWithBio"
import HeroWithBackgroundImage from "./HeroWithBackgroundImage"
import HeroWithCroppedBackgroundImage from "./HeroWithCroppedBackgroundImage"
import ListOfCards from "./ListOfCards"
import ListOfPercentages from "./ListOfPercentages"
import HeaderWithTrustpilot from "./HeaderWithTrustpilot"
import Stats from "./Stats"
import ImageTextRight from "./ImageTextRight"
import ImageWithListTextRight from "./ImageWithListTextRight"
import ImageWithListTextLeft from "./ImageWithListTextLeft"
import FAQ from "./FAQ"
import TestimonialsCards from "./TestimonialsCards"
import TextButton from "./TextButton"
import BlogCtaButtonWithText from "./BlogCtaButtonWithText"
import BlogImage from "./BlogImage"
import BlogText from "./BlogText"
import HeaderWithInsuranceLogos from "./HeaderWithInsuranceLogos"
import HeaderWithRightCta from "./HeaderWithRightCta"
import HeaderWithSteps from "./HeaderWithSteps"
import HeaderWithTwoContentBlocks from "./HeaderWithTwoContentBlocks"
import ContentHubTabOverview from "./ContentHubTabOverview"
import ContentHubHeader from "./ContentHubHeader"
import ContentHubTabCost from "./ContentHubTabCost"
import ContentHubTabBenefits from "./ContentHubTabBenefits"
import ContentHubMarrigeCounseling from "./ContentHubMarrigeCounseling"
import ContentHubEndCap from "./ContentHubEndCap"
import ContentHubOnlineTreatment from "./ContentHubOnlineTreatment"
import ContentHubPlan from "./ContentHubPlan"
import { Post } from "../../components/shared/Blog/types"
import ContentHubTabDini from "./ContentHubTabDini"
import StickyTab from "./StickyTab"
import MedicationIntro from "./MedicationIntro"
import ThreeColumnLp from "./ThreeColumnLp"
import OfferingComparisonSlice from "./OfferingComparison"
import Testimonials from "./Testimonials"
import AssessmentList from "./AssessmentList"
import BannerWithText from "./BannerWithText"
import ImageRightWithList from "./ImageRightWithList"
import OffersList from "./OffersList"
import ValuePropSlice from "./ValuePropSlice"
import InsuranceHero from "./InsuranceHero"
import InsuranceCardWithTextLeft from "./InsuranceCardWithTextLeft"

interface SliceZoneProps {
  sliceZones: { id: string; slice_type: string; primary: any }[]
  posts?: Post[]
}

export default function SliceZone({ sliceZones, posts }: SliceZoneProps) {
  const sliceComponents: { [key: string]: React.ElementType } = {
    hero_header: HeroHeader,
    text_right: TextRight,
    text_left: TextLeft,
    call_out_box: CallOutBox,
    feature_text_right: FeatureTextRight,
    feature_text_left: FeatureTextLeft,
    cta_button: CtaButton,
    "3_columns": ThreeColumns,
    "4_columns": FourColumns,
    large_quote: LargeQuote,
    quote_with_image: QuoteWithImage,
    video: Video,
    image: Image,
    image_with_text: ImageWithText,
    full_image_with_text_overlay: FullImageWithTextOverlay,
    text: Text,
    news_links: NewsLinks,
    "sub-menu_nav_bar": SubMenuNavBar,
    space: Space,
    three_columns_list: ThreeColumnsList,
    text_and_two_columns_list: TextAndTwoColumnsList,
    title_and_copy_with_image: TitleAndCopyWithImage,
    buttons_columns: ButtonsColumns,
    list_of_items_with_icon: ListOfItemsWithIcon,
    image_with_header: ImageWithHeader,
    avatar_with_bio: AvatarWithBio,
    hero_with_background_image: HeroWithBackgroundImage,
    hero_with_cropped_background_image_: HeroWithCroppedBackgroundImage,
    list_of_cards: ListOfCards,
    list_of_percentages: ListOfPercentages,
    header_with_trustpilot: HeaderWithTrustpilot,
    stats: Stats,
    image_text_right: ImageTextRight,
    image_with_list_text_right: ImageWithListTextRight,
    image_with_list_text_left: ImageWithListTextLeft,
    faq: FAQ,
    testimonials_cards: TestimonialsCards,
    text_button: TextButton,
    blog_cta_button_with_text: BlogCtaButtonWithText,
    blog_image: BlogImage,
    blog_text: BlogText,
    header_with_insurance_logos: HeaderWithInsuranceLogos,
    header_with_right_cta: HeaderWithRightCta,
    header_with_steps: HeaderWithSteps,
    header_with_two_content_blocks: HeaderWithTwoContentBlocks,
    content_hub_tab_overview: ContentHubTabOverview,
    content_hub_tab_cost: ContentHubTabCost,
    content_hub_marriage_counseling: ContentHubMarrigeCounseling,
    content_hub_header: ContentHubHeader,
    content_hub_tab_benefits: ContentHubTabBenefits,
    couples_therapy_end_cap: ContentHubEndCap,
    content_hub_tab_dini: ContentHubTabDini,
    sticky_tab: StickyTab,
    content_hub_online_treatment: ContentHubOnlineTreatment,
    content_hub_plan: ContentHubPlan,
    medication_intro: MedicationIntro,
    three_column_lp: ThreeColumnLp,
    offering_comparison: OfferingComparisonSlice,
    testimonials: Testimonials,
    assessment_list: AssessmentList,
    banner_with_text: BannerWithText,
    image_right_with_list: ImageRightWithList,
    offers_list: OffersList,
    value_prop_slice: ValuePropSlice,
    insurance_hero: InsuranceHero,
    insurance_card_with_text_left: InsuranceCardWithTextLeft,
  }

  const slices = sliceZones.map(slice => {
    return {
      id: slice.id,
      slice_type: slice.slice_type,
      primary: slice.primary,
    }
  })

  const sliceZoneContent = sliceZones.map(slice => {
    const SliceComponent = sliceComponents[slice.slice_type]

    if (SliceComponent) {
      const otherSlices = slices.filter(sliceInfo => sliceInfo.id != slice.id)

      return (
        <SliceComponent
          slice={{ ...slice, posts }}
          otherSlices={otherSlices}
          key={`slice-${slice.id}`}
        />
      )
    }

    return null
  })

  return <main className="container">{sliceZoneContent}</main>
}
