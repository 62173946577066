import React from "react"
import { ModalState, useModalContext } from "../../../contexts/ModalContext"
import { useEffect, useRef, useState } from "react"
import { ModalDialog, Overlay } from "./styled"

const Modal = () => {
  const { setModalState, modalState, content, options } = useModalContext()
  const dialogRef = useRef<HTMLDivElement>(null)
  const [isMaxHeight, setIsMaxHeight] = useState(false)

  const onResize = () => {
    if (!dialogRef.current) return
    setIsMaxHeight(
      Math.floor(window.innerHeight * 0.9) <=
        Math.floor(dialogRef.current.clientHeight)
    )
  }
  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize)
    if (dialogRef.current) {
      resizeObserver.observe(dialogRef.current)
      onResize()

      window.addEventListener("resize", onResize)
    }

    return () => {
      resizeObserver.disconnect()
      window.removeEventListener("resize", onResize)
    }
  }, [dialogRef.current === null])

  return (
    <div>
      <Overlay
        onClick={() => setModalState(ModalState.CLOSED)}
        state={modalState}
      />
      <ModalDialog
        state={modalState}
        width={options?.modalWidth}
        ref={dialogRef}
        isMaxHeight={isMaxHeight}
        fullScreenOnMobile={options?.fullScreenOnMobile}
      >
        <div>{content}</div>
      </ModalDialog>
    </div>
  )
}

export default Modal
