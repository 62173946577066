import SEO from "../../SEO"
import { Navigation } from "../../ui/Navigation"
import { FaqPageBody } from "./styled"
import { FrequentlyAskedQuestions } from "./sections"
import { FaqTopics } from "../../shared/FaqTopics"
import { organizationSchemaMarkup } from "../../../common/OrganizationSeoSchema"

export const FaqBody = (): JSX.Element => (
  <FaqPageBody>
    <FrequentlyAskedQuestions />
    <FaqTopics title={"Popular Topics"} labels={["popular_topics_faq"]} />
  </FaqPageBody>
)

export const FaqPage = (): JSX.Element => {
  return (
    <>
      <SEO
        title="FAQs | Cerebral"
        description="All the info on Cerebral, all in one place."
        url="faqs/"
        schemaMarkup={organizationSchemaMarkup}
      />
      <Navigation />
      <FaqBody />
    </>
  )
}
