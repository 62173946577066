import React, { useRef } from "react"
import {
  Container,
  Header,
  StepBlock,
  StepsClone,
  Dot,
  DotWrapper,
} from "./styled"
import Image from "./components/Image"
import StepContent from "./components/StepContent"
import Scroll from "./components/Scroll"
import { useIntersectionObserver } from "./hooks"
import { stepData } from "./constants"

export const HowItWorks = () => {
  return (
    <Container>
      <Header>How it works</Header>
      <StepsClone>
        {stepData.map((step, index) => {
          const stepBlockRef = useRef<HTMLDivElement>(null)
          const scrollRef = useRef<HTMLDivElement>(null)
          const dotRef = useRef<HTMLDivElement>(null)
          const imageRef = useRef<HTMLDivElement>(null)
          const stepContentRef = useRef<HTMLDivElement>(null)

          useIntersectionObserver(stepBlockRef, ([entry]) => {
            if (entry.isIntersecting) {
              if (scrollRef.current) {
                scrollRef.current.style.transform = "scaleY(1)"
              }
              if (dotRef.current) {
                dotRef.current.style.backgroundColor = "rgb(0, 108, 208)"
                dotRef.current.style.width = "24px"
                dotRef.current.style.height = "24px"
              }
              if (imageRef.current) {
                imageRef.current.style.opacity = "1"
              }
              if (stepContentRef.current) {
                stepContentRef.current.style.opacity = "1"
              }
            }
          })

          return (
            <StepBlock key={index} index={index} ref={stepBlockRef}>
              <Image index={index} image={step.image} ref={imageRef} />
              <DotWrapper>
                <Dot ref={dotRef} delay={index !== 0} />
              </DotWrapper>
              <StepContent
                heading={step.heading}
                number={step.number}
                ref={stepContentRef}
              />
              {index !== 0 && <Scroll ref={scrollRef} />}
            </StepBlock>
          )
        })}
      </StepsClone>
    </Container>
  )
}
