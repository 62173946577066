import React from "react"
import { graphql } from "gatsby"

import "../common/colors.css"
import Layout from "../components/Layout"
import { ConditionsLanding } from "../components/ConditionsLP"
import { ConditionsPageProps } from "../components/ConditionsPage/types"

interface PageProps {
  data: {
    prismicConditionpage: {
      data: ConditionsPageProps
    }
  }
}
export default function DynamicPage({ data }: PageProps): JSX.Element {
  return (
    <Layout>
      <ConditionsLanding data={data?.prismicConditionpage?.data} />
    </Layout>
  )
}
export const query = graphql`
  query ConditionsPageQuery {
    prismicConditionpage {
      data {
        conditions {
          desc {
            html
          }
          title {
            text
          }
          group
          medication
          therapy
          learn_more {
            url
          }
          test_link {
            url
          }
          blog_tag
          learn_more_condition_title
        }
        other_specialties {
          item {
            text
          }
        }
        therapeutic_approaches {
          desc {
            html
          }
          title {
            text
          }
          header_icon {
            url
          }
        }
      }
    }
  }
`
