import React from "react"
import Layout from "../components/Layout"
import { TestimonialsPage } from "../components/TestimonialsPage"

export default function DynamicPage() {
  return (
    <Layout>
      <TestimonialsPage />
    </Layout>
  )
}
