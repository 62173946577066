import React, { useEffect, FC, useState } from "react"
import styled from "styled-components"

const Button = styled.div<{ selected?: boolean; disabled?: boolean }>`
  font-family: Poppins;
  min-width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  gap: 6px;
  align-items: center;
  padding: 8px 12px;
  background-color: ${props => (props.selected ? "#E1E7FF" : "#FFF")};
  border: 2px solid #e1e7ff;
  border-left-width: 0px;
  font-weight: 600;
  font-size: 12px;
  color: ${props => (props.disabled ? "#E1E7FF" : "#250044")};
  cursor: pointer;
  outline: none;
  text-align: center;

  &:hover {
    background-color: "#E1E7FF";
  }

  &:first-child {
    border-left-width: 2px;
    border-radius: 8px 0px 0px 8px;
  }

  &:last-child {
    border-radius: 0px 8px 8px 0px;
  }

  p {
    display: none;
  }

  @media (min-width: 896px) {
    padding: 10px 14px;
    p {
      display: block;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  max-width: 456px;
  margin: auto;
  margin-bottom: 80px;

  @media (max-width: 1024px) {
    margin-bottom: 48px;
  }
`

interface PaginationProps {
  itemsCount: number
  itemsPerPage: number
  onPageChange: (page: number) => void
}

const Pagination: FC<PaginationProps> = ({
  itemsCount,
  itemsPerPage,
  onPageChange,
}) => {
  const [pages, setPages] = useState(Math.ceil(itemsCount / itemsPerPage))
  const [currentPage, setCurrentPage] = useState(0)
  const [pagesShown, setPagesShown] = useState<number[]>(
    currentPage < 3 ? [0, 1, 2, 3, 4] : [...Array(pages).keys()]
  )

  const getPagesToShow = () => {
    if (pages < 5) {
      setPagesShown([...Array(pages).keys()])
      return
    }

    if (currentPage < 3) {
      setPagesShown([0, 1, 2, 3, 4])
      return
    }

    if (currentPage > pages - 4) {
      setPagesShown([pages - 5, pages - 4, pages - 3, pages - 2, pages - 1])
      return
    }
    setPagesShown([
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ])
  }

  useEffect(() => {
    setPages(Math.ceil(itemsCount / itemsPerPage))
  }, [itemsCount])

  useEffect(() => {
    onPageChange(currentPage)
    getPagesToShow()
  }, [currentPage])

  useEffect(() => {
    getPagesToShow()
    setCurrentPage(0)
  }, [pages])

  if (pages <= 1 || itemsCount < itemsPerPage) return <></>

  return (
    <Wrapper>
      <Button
        disabled={currentPage == 0}
        onClick={() => currentPage > 0 && setCurrentPage(0)}
      >
        {"<<"}
        <p>First</p>
      </Button>

      <Button
        disabled={currentPage == 0}
        onClick={() => currentPage > 0 && setCurrentPage(currentPage - 1)}
      >
        {"<"}
        <p>Prev</p>
      </Button>

      {pagesShown.map(page => (
        <Button
          key={page}
          selected={currentPage == page}
          onClick={() => setCurrentPage(page)}
        >
          {page + 1}
        </Button>
      ))}

      <Button
        disabled={currentPage == pages - 1}
        onClick={() => currentPage < pages && setCurrentPage(currentPage + 1)}
      >
        <p>Next</p>
        {">"}
      </Button>

      <Button
        disabled={currentPage == pages - 1}
        onClick={() => currentPage < pages && setCurrentPage(pages - 1)}
      >
        <p>Last</p>
        {">>"}
      </Button>
    </Wrapper>
  )
}

export default Pagination
