import { BodyRegular, H2, H5 } from "../../../ui"
import { HowItWorksWrapper, Item, NumberBadge, Row } from "./styled"

export const HowItWorks = () => {
  return (
    <HowItWorksWrapper>
      <H2>How it works</H2>
      <Row>
        <Item>
          <NumberBadge>1</NumberBadge>
          <H5>Share your link</H5>
          <BodyRegular>
            Copy your referral link and share it with family or friends.{" "}
          </BodyRegular>
        </Item>
        <Item>
          <NumberBadge>2</NumberBadge>
          <H5>Give 70% off</H5>
          <BodyRegular>
            Your friend creates an account and gets 70% off their first month’s
            subscription.
          </BodyRegular>
        </Item>
        <Item>
          <NumberBadge>3</NumberBadge>
          <H5>Get a $15 credit</H5>
          <BodyRegular>
            You get a $15 credit when your friend creates an account.
          </BodyRegular>
        </Item>
      </Row>
    </HowItWorksWrapper>
  )
}
