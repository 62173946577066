import React, { createContext, useEffect } from "react"
import { loadKetchScript } from "../../lib/ketch"

export interface Props {
  purposes: Record<string, string>
  hasUserTakenActionOnKetchConsent: boolean
}
export interface KetchContextProviderProps {
  children: React.ReactNode
}

export const KetchContext = createContext<Props>({
  purposes: {},
  hasUserTakenActionOnKetchConsent: false,
})

const KetchContextProvider: React.FC<KetchContextProviderProps> = props => {
  const { children } = props

  const [isKetchLoaded, setIsKetchLoaded] = React.useState(false)
  const [
    hasUserTakenActionOnKetchConsent,
    setHasUserTakenActionOnKetchConsent,
  ] = React.useState(false)
  const [purposes, setPurposes] = React.useState<Record<string, string>>({})

  useEffect(() => {
    loadKetchScript()
    setIsKetchLoaded(true)
  }, [])

  useEffect(() => {
    if (isKetchLoaded) {
      window.ketch("on", "consent", function (c) {
        setPurposes(c.purposes)
        if (c.purposes.advertising_analytics !== undefined) {
          setHasUserTakenActionOnKetchConsent(true)
        }
      })
    }
  }, [isKetchLoaded])

  return (
    <KetchContext.Provider
      value={{ purposes, hasUserTakenActionOnKetchConsent }}
    >
      {children}
    </KetchContext.Provider>
  )
}
export default KetchContextProvider
