import React from "react"
import Container from "../common/Container"
import ItalicHeading from "../components/ui/ItalicHeading"
import PolicyText from "../common/PolicyText"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"
import { toPage } from "../lib/utils"
import { graphql } from "gatsby"
import DynamicNavigation from "../components/ui/DynamicNavigation"

const Wrapper = styled.div`
  padding: 40px 0;
  flex-grow: 1;
`
export default function CancellationPolicyPage({ data }) {
  const page = toPage(data)

  //TODO: prismic does not let you have relative links in their Rich Text Fields
  const contents = page.contents.replace(/https:\/\/\//g, "/")

  return (
    <Layout>
      <SEO title="Cancellation Policy" />
      <DynamicNavigation />
      <Wrapper>
        <Container>
          <ItalicHeading>{page.title}</ItalicHeading>
          <PolicyText dangerouslySetInnerHTML={{ __html: contents }} />
        </Container>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query CancellationPolicyQuery($lang: String) {
    page: allPrismicCancellationpolicy(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            contents {
              html
            }
            title {
              text
            }
          }
        }
      }
    }
  }
`
