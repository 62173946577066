import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const Header = styled.div`
  color: ${colors.c_primary[500]};
  font-family: Poppins;
  font-size: 64px;
  font-weight: 600;
  line-height: 80px;

  b {
    font-weight: 800;
    color: ${colors.c_primary[600]};
  }

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`
