import styled from "styled-components"
import { colors } from "../../../../themes/colors"

export const TheCerebralWayOuterContainer = styled.div`
  background: #f2f5ff;
  padding: 72px 10%;
  @media (max-width: 834px) {
    padding: 72px 8% 0;
    margin-bottom: -20px;
  }
`

export const TheCerebralWayContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(300px, 9fr);
  gap: 58px;
  margin: 0 auto;
  max-width: 1200px;
  h2 {
    margin-bottom: 36px;
  }
  @media (max-width: 834px) {
    grid-template-columns: 100%;
  }
`

export const ImageContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 0px 0px 9.54px 3.18px #f6f6f9;
  height: 868px;
  width: 337px;
  align-self: center;
  justify-self: center;
  @media (max-width: 834px) {
    height: 408px;
    img {
      height: 360px;
      width: 289px;
      object-fit: none;
      object-position: 0 0;
    }
  }
`

export const Circle = styled.div`
  height: 40px;
  width: 40px;
  background: ${colors.c_secondary[200]};
  color: ${colors.c_primary[600]};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

export const CerebralWaySection = styled.div`
  display: grid;
  grid-template-columns: 40px minmax(0px, 650px);
  margin-bottom: 56px;
  gap: 32px;
  position: relative;
  h4 {
    margin-top: 8px;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
  }

  @media (max-width: 834px) {
    margin-bottom: 32px;
    grid-template-columns: 40px minmax(0px, 650px);
  }
`

export const DesktopGetToKnow = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 175px) minmax(113px, 350px);
  gap: 40px;
  margin-left: 80px;
  margin-bottom: 56px;
  img {
    width: 100%;
    height: auto;
  }
  h6 {
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
  }
  @media (max-width: 834px) {
    display: none;
  }
`

export const MobileGetToKnow = styled.div`
  display: none;
  @media (max-width: 834px) {
    display: grid;
    grid-template-columns: minmax(75px, 175px) minmax(0px, 350px);
    gap: 40px;
    margin-left: 80px;
    margin-bottom: 32px;
    gap: 24px;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
    h6 {
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
      grid-column: span 2;
    }
  }
`

export const CopySection = styled.div`
  position: relative;
  a {
    width: 245px;
    @media (max-width: 834px) {
      margin: 0 auto;
    }
  }
  @media (max-width: 834px) {
    order: -1;
  }
`

export const VerticalLine = styled.div`
  position: absolute;
  width: 1px;
  height: 70%;
  top: 100px;
  left: 20px;
  background: linear-gradient(
    180deg,
    ${colors.c_secondary[200]} 40.57%,
    rgba(203, 216, 255, 0) 100%
  );
`
