import { useState } from "react"
import { UilCheckCircle } from "@iconscout/react-unicons"
import {
  Button,
  CtaWrapper,
  EmailCollectionSubtitle,
  EmailCollectionTitle,
  EmailCollectionWrapper,
  EmailIcon,
  EmailInput,
  EmailInputContainer,
  ErrorMessage,
  HipaaContent,
  HipaaWrapper,
  SuccessWrapper,
} from "./styled"
import segment from "../../../lib/segment"
import EmailICon from "./images/email.svg"
import { isValidEmail } from "../../HomePage/sections/EmailCollectionPopup/utils"
import CheckBox from "../../../components/ui/Checkbox"

const EmailCollectionSection = () => {
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const DEFAULT_TITLE = "Get top insights and news from our experts"
  const DEFAULT_SUBTITLE =
    "Helpful mental heath resources delivered straight to your inbox!"
  const SUCCESS_TITLE = "Thank you for subscribing"
  const SUCCESS_SUBTITLE =
    "You've been added to our mailing list. Please check your email for the latest updates."

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)

    if (!isValidEmail(email) && !!e.target.value) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  }

  const handleOnSuccess = () => {
    setIsSuccess(false)
    setIsError(false)
    setIsChecked(false)
  }

  const handleOnSubmit = () => {
    if (!isValidEmail(email)) {
      setIsSuccess(false)
      return
    }

    if (!isSuccess) {
      segment.addUserToIterableNewsletter(email, "", "")
      ;(window.Kameleoon as any).API.Goals.processConversion("Collected Email")
      setIsSuccess(true)
      setIsChecked(false)
      setEmail("")

      window.analytics?.track(
        "Content Hub - HIPAA Marketing Authorization Checkbox Clicked",
        { isChecked },
        {
          integrations: {
            All: false,
            Heap: true,
            Iterable: true,
          },
        }
      )
    } else {
      setIsSuccess(false)
      setIsChecked(false)
    }
  }

  return (
    <EmailCollectionWrapper>
      <EmailCollectionTitle>
        {isSuccess ? SUCCESS_TITLE : DEFAULT_TITLE}
      </EmailCollectionTitle>

      <EmailCollectionSubtitle>
        {isSuccess ? SUCCESS_SUBTITLE : DEFAULT_SUBTITLE}
      </EmailCollectionSubtitle>

      <CtaWrapper isSuccess={isSuccess}>
        {!isSuccess && (
          <EmailInputContainer>
            <EmailIcon src={EmailICon} alt="" />
            <EmailInput
              isError={isError}
              placeholder="Email address"
              value={email}
              onChange={handleOnChange}
            />
            {isError && <ErrorMessage>Your email is invalid.</ErrorMessage>}
          </EmailInputContainer>
        )}
        {isSuccess ? (
          <Button onClick={handleOnSuccess}>
            <SuccessWrapper>
              Success
              <UilCheckCircle color="#FFFFFF" size={20} />
            </SuccessWrapper>
          </Button>
        ) : (
          <Button onClick={handleOnSubmit} disabled={!isChecked}>
            Subscribe
          </Button>
        )}
      </CtaWrapper>

      {!isSuccess ? (
        <HipaaWrapper>
          <CheckBox
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
          />
          <HipaaContent>
            I accept the{" "}
            <a
              href="https://cerebral.com/hipaa-marketing-authorization"
              target="_blank"
            >
              Cerebral HIPAA Marketing Authorization.
            </a>
          </HipaaContent>
        </HipaaWrapper>
      ) : (
        <></>
      )}
    </EmailCollectionWrapper>
  )
}

export default EmailCollectionSection
