import "../common/colors.css"
import Layout from "../components/Layout"
import { FaqPage } from "../components/FaqPages/FaqPage"

export default function DynamicPage(): JSX.Element {
  return (
    <Layout>
      <FaqPage />
    </Layout>
  )
}
